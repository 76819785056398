import { StyledCard, StyledText } from '@/components/styles'

export default function ({ name, text, img }) {
    return (
        <StyledCard center>
            <StyledCard.BankIcon>
                <img src={img} alt="" />
            </StyledCard.BankIcon>
            <StyledCard.Separator />
            <StyledCard.Title small mb="small">
                {name}
            </StyledCard.Title>
            <StyledText grey>{text}</StyledText>
        </StyledCard>
    )
}
