import { useCity } from '@/contexts/CityContext'
import Link from 'next/link'

export default function ({ slug, city: cityFromPage, children }) {
    const { city: initialCity } = useCity()
    const city = cityFromPage ?? initialCity

    return (
        <Link
            as={city.is_default ? `/${slug}` : `/${city.slug}/${slug}`}
            href={city.is_default ? '/[slug]' : '/[slug]/[productSlug]'}
        >
            {children}
        </Link>
    )
}
