import React, { useState, useEffect, useContext } from 'react'
import useForm from '@/lib/hooks/form'

const CalculatorContext = React.createContext()

export function CalculatorProvider({ children }) {
    const [calculator, setCalculator] = useState(null)

    const initialFormValues = {
        sum: 5000,
        term: 1,
        initialFee: '',
        monthSum: 100,
    }

    const calcFormValues = {
        monthPay: '',
        dealSum: '',
        saving: '',
    }

    const form = useForm(initialFormValues)
    const calcForm = useForm(calcFormValues)
    const [rate, setRate] = useState(
        calculator && (calculator.points[0]?.rate_month ?? calculator.points[0].rate_year),
    )
    const [rateType, setRateType] = useState('')
    const [termType, setTermType] = useState('')

    useEffect(() => {
        calculator &&
            form.setValues({
                ...form.values,
                sum: calculator.sum || calculator.points[0].amount_from,
                term:
                    calculator.term?.month ??
                    calculator.term?.year ??
                    calculator.deadline_from?.month ??
                    calculator.deadline_from.year,
                initialFee: calculator.points[0].prepaid
                    ? ((calculator.sum || calculator.points[0].amount_from) / 100) *
                      calculator.points[0].prepaid
                    : '',
                monthSum: calculator.pay_month_default ?? calculator.pay_month_from,
            })
        calculator && setRateType(calculator.points[0]?.rate_month ? 'month' : 'year')
        calculator && setTermType(calculator.deadline_from?.month ? 'month' : 'year')
    }, [calculator])

    useEffect(() => {
        calculator && calculator.points?.length > 0 && form.values.sum
            ? form.values.sum <= calculator.points[0].amount_from
                ? setRate(calculator.points[0]?.rate_month ?? calculator.points[0].rate_year)
                : form.values.sum > calculator?.points[calculator.points.length - 1].amount_from
                ? setRate(
                      calculator.points[calculator.points.length - 1]?.rate_month ??
                          calculator.points[calculator.points.length - 1].rate_year,
                  )
                : setRate(
                      calculator?.points.find(
                          (point) =>
                              form.values.sum > point.amount_from &&
                              form.values.sum <= point.amount_to,
                      )?.rate_month ??
                          calculator?.points.find(
                              (point) =>
                                  form.values.sum > point.amount_from &&
                                  form.values.sum <= point.amount_to,
                          ).rate_year,
                  )
            : setRate(null)
        calculator &&
            form.values.initialFee > form.values.sum * 0.9 &&
            form.setValues({ ...form.values, initialFee: form.values.sum * 0.9 })
    }, [calculator, form.values.sum])

    useEffect(() => {
        calculator &&
            calcForm.setValues(
                calculator.type === 'credit'
                    ? {
                          monthPay: (
                              (form.values.sum *
                                  (((rateType === 'month' ? rate : rate / 12) / 100) *
                                      (termType === 'month'
                                          ? form.values.term
                                          : form.values.term * 12) +
                                      1)) /
                              (termType === 'month' ? form.values.term : form.values.term * 12)
                          ).toFixed(2),
                      }
                    : calculator.type === 'credit_v2'
                    ? {
                          monthPay: (
                              ((form.values.sum - form.values.initialFee) *
                                  (((rateType === 'month' ? rate : rate / 12) / 100) *
                                      (termType === 'month'
                                          ? form.values.term
                                          : form.values.term * 12) +
                                      1)) /
                              (termType === 'month' ? form.values.term : form.values.term * 12)
                          ).toFixed(2),
                      }
                    : calculator.type === 'leasing'
                    ? {
                          monthPay: (
                              ((form.values.sum - form.values.initialFee) *
                                  (((rateType === 'month' ? rate : rate / 12) / 100) *
                                      (termType === 'month'
                                          ? form.values.term
                                          : form.values.term * 12) +
                                      1)) /
                              (termType === 'month' ? form.values.term : form.values.term * 12)
                          ).toFixed(2),
                          dealSum: form.values.sum - form.values.initialFee,
                      }
                    : calculator.type === 'refinancing' && {
                          monthPay: (
                              (form.values.sum *
                                  (((rateType === 'month' ? rate : rate / 12) / 100) *
                                      (termType === 'month'
                                          ? form.values.term
                                          : form.values.term * 12) +
                                      1)) /
                              (termType === 'month' ? form.values.term : form.values.term * 12)
                          ).toFixed(2),
                          saving: (
                              form.values.monthSum -
                              (form.values.sum *
                                  (((rateType === 'month' ? rate : rate / 12) / 100) *
                                      (termType === 'month'
                                          ? form.values.term
                                          : form.values.term * 12) +
                                      1)) /
                                  (termType === 'month' ? form.values.term : form.values.term * 12)
                          ).toFixed(2),
                      },
            )
    }, [calculator, form.values])

    return (
        <CalculatorContext.Provider
            value={{
                calculator,
                setCalculator,
                form,
                calcForm,
                rate,
                termType,
            }}
        >
            {children}
        </CalculatorContext.Provider>
    )
}

export function useCalculator() {
    return useContext(CalculatorContext)
}
