import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const MobileNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  background: ${rgba("#1A1C21", 0.5)};
  transition: visibility 0s 0.35s, opacity 0.35s ease;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  user-select: none;

  ${maxWidth(vars.breakpoints.tablet)`
    ${props =>
    props.visible &&
    css`
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: none;
        transition: visibility 0s, opacity 0.35s ease;

        ${MobileNav.Menu} {
          opacity: 1;
          transform: none;
          transition: transform 0.4s ease-out, opacity 0.3s linear, padding .2s linear;
        }
      `}
    `}
`

MobileNav.Menu = styled.div`
  position: relative;
  height: 100%;
  min-width: 250px;
  width: 100%;
  max-width: 450px;
  margin-right: auto;
  padding-top: ${p => p.smallPadding ? "46px" : "87px"};
  opacity: 0;
  overflow: hidden;
  cursor: default;
  box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.08);
  transform: translateX(-100%);
  transition: transform 0.4s ease-in, opacity 0.3s linear, padding .2s linear;
  ${maxWidth(vars.breakpoints.mMobile)`
    max-width: none;
  `}
`

MobileNav.MenuInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  pointer-events: all;
`

MobileNav.Level = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: ${props => (props.visible ? '#FFFFFF' : 'var(--color-bg-accent)')};
  opacity: ${props => (props.visible ? 1 : 0)};
  overflow: hidden;
  transform: ${props => (props.visible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: ${props =>
    props.visible
        ? 'transform 0.4s ease-out, background 0.4s ease-in, opacity 0.2s ease-out'
        : 'transform 0.4s ease-in, background 0.4s ease-out, opacity 0.3s ease-in'};
`

MobileNav.LevelInner = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`

MobileNav.List = styled.div`
    ${p => p.fixedHeight && css`
        height: calc(100% - 146px);
        overflow: auto;
    `}
`

MobileNav.Item = styled.div`
  border-bottom: 1px solid ${vars.theme.outlineGrey};
  ${p => p.grey && css`
    background: #F0F4FA;
  `}
`

MobileNav.Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  
  svg {
    margin-left: 4px;
  }
  ${p => p.isOpen && `color: ${vars.theme.red};`}
  
  ${p => p.smallPadding && css`
    padding: 8px 20px;
  `}
  
  ${p => p.small && css`
    padding: 8px 32px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    font-weight: 300;
  `}

  ${props =>
    props.arrow &&
    css`
      padding-right: 40px;

      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 6px);
        right: 30px;
        width: 8.5px;
        height: 8.5px;
        border-right: 1.5px solid ${p => p.isOpen ? vars.theme.red : vars.theme.grey};
        border-bottom: 1.5px solid ${p => p.isOpen ? vars.theme.red : vars.theme.grey};
        transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
        transition: all .3s;
      }
    `}

  ${props =>
    props.activeLevelLink &&
    css`
      padding-right: 60px;

      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 4px);
        right: 28px;
        width: 8px;
        height: 8px;
        background: ${vars.theme.red};
        border-radius: 50%;
      }
    `}
`

MobileNav.ExtraList = styled.ul`
  margin-top: ${p => p.isOpen ? "-8px" : 0};
  opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    pointer-events: ${p => p.isOpen ? "all" : "none"};
    height: ${p => p.isOpen ? "auto" : "0"};
    overflow: ${p => p.isOpen ? "visible" : "hidden"};
    transition: ${p => p.isOpen ? "opacity 0.1s ease, visibility 0s ease 0.05s" : "opacity 0.1s ease, visibility 0s ease 0.1s"};   

  ${MobileNav.Item} {
    border-bottom: none;

    &:last-child {
      ${MobileNav.Link} {
        padding-bottom: 16px;
      }
    }
  }
`

MobileNav.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

MobileNav.Back = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 20px 16px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.35px;
  color: ${vars.theme.darkGrey};
`

MobileNav.BackIcon = styled.span`
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 0;
`

MobileNav.Contacts = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px -4px 22px rgba(61, 75, 86, 0.08);
`

MobileNav.PhoneToggle = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-right: 40px;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 6px);
    right: 10px;
    width: 8.5px;
    height: 8.5px;
    border-right: 1.5px solid ${vars.theme.grey};
    border-bottom: 1.5px solid ${vars.theme.grey};
    transform: rotate(45deg);
  }
`

MobileNav.DropdownItem = styled.div`
  color: ${vars.theme.black};
  a {
    display: block;
    padding: 6px 0;
  }
  ${p => p.padding && css`
    padding: 6px 0;
  `}
`

MobileNav.PhoneLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${vars.theme.darkGrey};
`

MobileNav.DropdownInner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.4s ease-in, opacity 0.3s linear, padding .2s linear;
`

MobileNav.Dropdown = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  background: ${rgba("#1A1C21", 0.5)};
  transition: visibility 0s 0.35s, opacity 0.35s ease;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  
  ${props =>
    props.isVisible &&
    css`
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: none;
        transition: visibility 0s, opacity 0.35s ease;

        ${MobileNav.DropdownInner} {
          opacity: 1;
          transform: none;
          transition: transform 0.4s ease-out, opacity 0.3s linear, padding .2s linear;
        }
  `}
`

export default MobileNav
