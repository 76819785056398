import { useState, useEffect } from 'react'
import { StyledHeader, StyledButton, StyledCityChange } from '@/components/styles'
import { SvgIcon, CityChangeModal } from '@/components'
import OutsideClickHandler from 'react-outside-click-handler'
import { useCity } from '@/contexts/CityContext'
import cookies from '@/lib/cookies'

export default function ({ mobile, isVisible, dark }) {
    const { city, setCityCookie } = useCity()

    const [isOpen, setIsOpen] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        const showDropdown = setTimeout(() => {
            if (!cookies.get('atl_city')) {
                setIsOpen(true)
            }
        }, 3000)

        return () => clearTimeout(showDropdown)
    }, [])

    useEffect(() => {
        !isVisible && isOpen && setIsOpen(false)
    }, [isVisible])

    return (
        <>
            <StyledHeader.Action mobile={mobile}>
                <OutsideClickHandler onOutsideClick={() => isOpen && setIsOpen(!isOpen)}>
                    <span onClick={() => setIsOpen(!isOpen)}>
                        <SvgIcon name="location" width="16" height="16" />
                        {city.title}
                    </span>
                    <StyledCityChange.Dropdown isOpen={isOpen} dark={dark}>
                        <StyledCityChange.Title white={dark}>
                            Ваш город <span>{city.title}</span> ?
                        </StyledCityChange.Title>
                        <StyledCityChange.Buttons>
                            <StyledButton
                                width="full"
                                sizeS
                                onClick={() => {
                                    setCityCookie(city)
                                    setIsOpen(false)
                                }}
                            >
                                Верно
                            </StyledButton>
                            <StyledButton
                                width="full"
                                sizeS
                                bg="grey"
                                onClick={() => {
                                    setIsOpen(false)
                                    setModalIsOpen(true)
                                }}
                            >
                                Нет
                            </StyledButton>
                        </StyledCityChange.Buttons>
                    </StyledCityChange.Dropdown>
                </OutsideClickHandler>
            </StyledHeader.Action>
            <CityChangeModal isOpen={modalIsOpen} onClose={setModalIsOpen} />
        </>
    )
}
