import { StyledModal } from '@/components/styles'
import { Modal } from '@/components'

export default function ({ isOpen, onClose }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Не можете войти?"
            text="Вероятно вы забыли свой номер телефона, по которому осуществлялся вход в ЛК или не имеете доступа к указанному номеру телефона. Пожалуйста, обратитесь в службу поддержки для восстановления доступа."
            medium
        >
            <StyledModal.GreyBlock>
                <StyledModal.GreyBlockTitle>Служба поддержки</StyledModal.GreyBlockTitle>
                <StyledModal.GreyBlockText>
                    <a href="tel:+375298883222">+375 29 888-3-222</a>
                </StyledModal.GreyBlockText>
            </StyledModal.GreyBlock>
        </Modal>
    )
}
