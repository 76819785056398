import styled from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Request = styled.div``

Request.Top = styled.div`
    display: grid;
    grid-template-columns: 1fr 170px;
    gap: 150px;
    margin-bottom: 32px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 4px;
    ${maxWidth(vars.breakpoints.tablet)`
        gap: 64px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
        gap: 16px;
        grid-template-columns: 1fr 40px;
        button {
            padding: 10px;
        }
    `}
`

Request.Conditions = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 48px;
    ${maxWidth(vars.breakpoints.mTablet)`
        gap: 16px;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: repeat(2, 1fr);
    `}
`

Request.Condition = styled.div``

Request.ConditionValue = styled.div`
    margin-bottom: 4px;
    font-weight: 500;
`

Request.ConditionTitle = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: ${vars.theme.darkGrey};
`

Request.TopButton = styled.div`
    svg {
        display: none;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        svg {
            display: block;
        }
        span {
            display: none;
        }
    `}
`

Request.Step = styled.div`
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Request.Progress = styled.div`
    margin-bottom: 16px;
    background: ${vars.theme.outlineGrey};
    border-radius: 1px;
`

Request.ProgressInner = styled.div`
    height: 4px;
    width: ${p => p.width}%;
    background: ${vars.theme.red};
    border-radius: 1px;
    transition: .3s;
`

Request.ProgressText = styled.div`
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
    span {
        font-weight: 500;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 24px;
    `}
`

Request.Title = styled.div`
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 16px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 16px;
        line-height: 24px;
    `}
`

Request.Separator = styled.div`
    height: 2px;
    margin-bottom: 24px;
    background: ${vars.theme.outlineGrey};
`

Request.Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 24px;
    background: rgba(225, 232, 242, 0.5);
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.reverse ? "flex-direction: column-reverse;" : "display: block;"}
    `}
`

Request.Guarantee = styled.div`
    display: flex;
    align-items: center;
    margin-right: 32px;
    color: ${vars.theme.darkGrey};
    svg {
        margin-right: 20px;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        margin: 0 0 16px;
    `}
`

Request.Buttons = styled.div`
    display: grid;
    grid-template-columns: 200px 160px;
    gap: 20px;
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 2fr 1.6fr;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: 1fr;
        gap: 16px;
        button:last-child {
            order: -1;
        }
    `}
`

Request.Back = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    color: ${vars.theme.darkGrey};
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-top: 16px;
    `}
`

Request.SubmitButton = styled.div`
    width: 268px;
    ${maxWidth(vars.breakpoints.mMobile)`
        width: 100%;
    `}
`

export default Request
