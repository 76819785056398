import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const MobileOffices = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 32px;
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: 1fr;
        gap: 8px;
        margin-bottom: 24px;
    `}
`

MobileOffices.Item = styled.div`
    background: ${vars.theme.lightGrey};
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 8px;
    cursor: pointer;
`

MobileOffices.ItemTop = styled.div`
    display: grid;
    grid-template-areas: 'city location' 'address location';
    gap: 8px 20px;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid ${vars.theme.outlineGrey};
    ${maxWidth(vars.breakpoints.mTablet)`
        gap: 4px 16px;
        padding: 16px;
    `}
`

MobileOffices.City = styled.div`
    grid-area: city;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 16px;
        line-height: 24px;
    `}
`

MobileOffices.Address = styled.div`
    grid-area: address;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

MobileOffices.Location = styled.div`
    grid-area: location;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 22px rgba(61, 75, 86, 0.08);
    border-radius: 50%;
    svg {
        fill: ${vars.theme.red};
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        width: 40px;
        height: 40px;
    `}
`

MobileOffices.Date = styled.div`
    padding: 16px 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 12px 16px;
        font-size: 14px;
        line-height: 20px;
    `}
`

MobileOffices.FilterWrapper = styled.div`
    display: flex;
    margin: 0 -8px;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin: 24px 0 0;
        border: 1px solid ${vars.theme.outlineGrey};
        border-radius: 4px;
    `}
`

MobileOffices.Filter = styled.div`
    position: relative;
    margin: 0 8px;
    ${maxWidth(vars.breakpoints.mTablet)`
        position: static;
        flex: 1;
        margin: 0;
    `}
`

MobileOffices.FilterValue = styled.div`
    position: relative;
    width: 240px;
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 4px;
    cursor: pointer;
    
    &::after {
        content: '';
        position: absolute;
        top: ${p => p.isOpen ? "calc(50% - 1px)" : "calc(50% - 6px)"};
        right: 20px;
        width: 8.5px;
        height: 8.5px;
        border-right: 1.5px solid ${p => p.isOpen ? vars.theme.red : vars.theme.grey};
        border-bottom: 1.5px solid ${p => p.isOpen ? vars.theme.red : vars.theme.grey};
        transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
        transition: all .3s;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        width: 100%;
        border: none;
        border-radius: 0;
        text-align: center;
        &:not(:last-child) {
            border-right: 1px solid ${vars.theme.outlineGrey};
        }
        
        &:after {
            display: none;
        }
    `}
`

MobileOffices.FilterDropdown = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    max-height: 320px;
    padding: 10px 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 4px;
    z-index: 1;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    pointer-events: ${p => p.isOpen ? "all" : "none"};
    width: ${p => p.isOpen ? "320px" : "0"};
    overflow: ${p => p.isOpen ? "auto" : "hidden"};
    transition: ${p => p.isOpen ? "opacity 0.1s ease, visibility 0s ease 0.05s" : "opacity 0.1s ease, visibility 0s ease 0.1s"};  
    ${maxWidth(vars.breakpoints.sDesktop)`
        left: auto;
        right: 0;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        padding: 0 20px 10px;
        z-index: 12;
        transform: ${p => p.isOpen ? "none" : "translateY(100%)"};
        transition: transform 0.4s ease-out, opacity 0.3s linear, visibility 0s ease 0.05s;
    `}
`

MobileOffices.FilterCity = styled.div`
    padding: 6px 0;
    cursor: pointer;
    transition: all .3s;
    
    &:hover {
        color: ${vars.theme.red};
    }
    
    ${p => p.active && css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${vars.theme.red};
        &:after {
            content: "Сбросить";
            font-size: 14px;
            line-height: 24px;
            text-align: right;
            color: ${vars.theme.darkGrey};
        }
    `}
`

MobileOffices.FilterClose = styled.div`
    display: none;
    ${maxWidth(vars.breakpoints.mTablet)`
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        margin: 0 -20px 14px;
        padding: 20px;
        background: #fff;
        border-bottom: 1px solid ${vars.theme.outlineGrey};
        color: ${vars.theme.darkGrey};
        
        svg {
            margin-right: 12px;
        }
    `}
`

export default MobileOffices
