import { useState, useRef, useCallback } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledText,
    StyledTitle,
    StyledProductDesc,
} from '@/components/styles'
import { SliderBlock, Tabs, HelpCard, Services, SvgIcon, Questions } from '@/components'
import Slider from 'react-slick'

export default function ({ tabs = [], onBtnClick }) {
    const [tab, setTab] = useState(tabs[0].id)
    const currentTab = tabs.find((item) => item.id === tab)
    const tabIndex = tabs.findIndex((item) => item.id === currentTab.id)

    const sliderRef = useRef()

    const settings = {
        slidesToShow:
            tabIndex < 2
                ? currentTab.blocks[0]?.blocks?.length < 3
                    ? currentTab.blocks[0].blocks.length
                    : 3
                : currentTab.blocks[0]?.blocks?.length < 4
                ? currentTab.blocks[0].blocks.length
                : 4,
        slidesToScroll: 1,
        variableWidth: tabIndex !== 2,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow:
                        currentTab.blocks[0]?.blocks?.length < 3
                            ? currentTab.blocks[0].blocks.length
                            : 3,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    dots: true,
                },
            },
        ],
    }

    return (
        <StyledSection pt={tabs[0].blocks[0].type !== 'type_slider_blocks' && 'small'} pb="small">
            <StyledContainer>
                <Tabs tabs={tabs} tab={tab} setTab={setTab} />
                <StyledProductDesc>
                    {currentTab.blocks.map((block, idx) =>
                        block.type === 'type_text' ? (
                            <StyledText key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: block.content }} />
                            </StyledText>
                        ) : block.type === 'type_file_list' && block?.blocks.length > 0 ? (
                            <div
                                key={idx}
                                style={{
                                    'margin-top':
                                        idx !== 0 &&
                                        currentTab.blocks.find(
                                            (item) => item.type === 'type_text',
                                        ) &&
                                        '24px',
                                    'margin-bottom':
                                        idx !== currentTab.blocks.length - 1 &&
                                        currentTab.blocks.find(
                                            (item) => item.type === 'type_text',
                                        ) &&
                                        '24px',
                                }}
                            >
                                <StyledTitle small>{block.title}</StyledTitle>
                                <StyledProductDesc.Documents>
                                    {block.blocks.map((item) => (
                                        <StyledProductDesc.Document key={item.id}>
                                            <StyledProductDesc.DocumentIcon>
                                                <SvgIcon name="download" width="14" height="18" />
                                            </StyledProductDesc.DocumentIcon>
                                            <StyledProductDesc.DocumentTitle>
                                                {item.title}
                                            </StyledProductDesc.DocumentTitle>
                                            <StyledProductDesc.DocumentSize>
                                                {(item.size / 1024).toFixed(2)} кбайт
                                            </StyledProductDesc.DocumentSize>
                                            <a href={item.file} target="_blank" />
                                        </StyledProductDesc.Document>
                                    ))}
                                </StyledProductDesc.Documents>
                            </div>
                        ) : block.type === 'type_unique_conditions' ? (
                            <Services tariffs={block.tariffs} key={idx} />
                        ) : (
                            block.type === 'type_slider_blocks' && (
                                <SliderBlock
                                    key={idx}
                                    title={block.title}
                                    length={block.blocks.length}
                                    count={tabIndex !== 2 ? 3 : 4}
                                    sliderRef={sliderRef}
                                    gradient={tabIndex !== 2}
                                >
                                    <Slider {...settings} ref={sliderRef}>
                                        {block.blocks.map((item, itemIdx) => (
                                            <HelpCard
                                                key={item.id}
                                                counter={itemIdx + 1}
                                                icon={item.icon}
                                                img={item?.image?.small}
                                                title={item.title}
                                                text={item.description}
                                                fixedWidth={tabIndex !== 2}
                                                onBtnClick={
                                                    item.type === 'type_button_title_description' &&
                                                    onBtnClick
                                                }
                                                greyCard={item.is_background}
                                                blurImg
                                            />
                                        ))}
                                    </Slider>
                                </SliderBlock>
                            )
                        ),
                    )}
                    {currentTab.blocks?.length > 0 &&
                        currentTab.blocks[0].type === 'type_question_answer' && (
                            <Questions questions={currentTab.blocks} />
                        )}
                </StyledProductDesc>
            </StyledContainer>
        </StyledSection>
    )
}
