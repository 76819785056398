import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {StyledContainer} from '@/components/styles'

const Nav = styled.div`
    display: flex;
    ${p => !p.contacts && "flex: 1;"}
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => !p.mobileVisible && css`
            display: none;
        `}
    `}
`

Nav.Dropdown = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;
    display: block;
    max-height: 448px;
    background: ${p => p.dark ? "#112F43" : "#FFFFFF"};
    box-shadow: 0px 4px 32px rgba(138, 140, 149, 0.1);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s ease-in, visibility 0s 0.3s ease-in;
    cursor: default;
    overflow: hidden;
    
    ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: none;
      transition: none;
      transition: opacity 0.3s ease, visibility 0s;

      ${Nav.DropdownInner} {
        opacity: 1;
        transform: none;
        transition: opacity 0.2s ease-out, transform 0.3s ease-out;
      }
    `}

    
    ${StyledContainer} {
        height: 100%;
    }
`

Nav.DropdownInner = styled.div`
    display: flex;
    height: 100%;
    ${p => p.padding && "padding: 40px 0;"}
    opacity: 0;
    transform: translateY(25px);
    transition: opacity 0.4s ease, transform 0.45s ease;
`

Nav.DropdownTitle = styled.div`
    margin-bottom: ${p => p.mbLarge ? "24px" : "16px"};
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${vars.theme.grey};
`

Nav.Menu = styled.div`
    flex-shrink: 0;
    display: flex;
    flex: 8;
`

Nav.Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 448px;
    overflow: auto;
    padding: 16px 0 40px;
    border-right: 1px solid ${p => p.lightBorder ? vars.theme.darkGrey : vars.theme.outlineGrey};
    &:not(:first-child) {
        padding: 28px 32px;
    }
    &:nth-child(3) {
        flex: 1.42;
        border: none;
    }
`

Nav.ColumnInner = styled.div`
    flex: 1;
`

Nav.MenuLink = styled.a`
`

Nav.MenuItem = styled.div`
    ${p => !p.submenu && "padding-right: 40px;"}
    a {
        cursor: pointer;
    }
    ${Nav.MenuLink} {
        position: relative;
        display: flex;
        align-items: center;
        padding: ${p => p.submenu ? "8px 0" : "12px 0"};
        ${p => !p.submenu && css`
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.2px;
        `}
        ${p => p.arrow && css`
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: calc(50% - 5px);
                display: block;
                width: 8.5px;
                height: 8.5px;
                border-right: 1px solid ${vars.theme.darkGrey};
                border-bottom: 1px solid ${vars.theme.darkGrey};
                transform: rotate(-45deg);
                transition: .3s;
            }
        `}
        
        span {
            display: block;
            margin-left: 12px;
            padding: 4px 8px;
            background: #F0F4FA;
            border-radius: 12px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.04em;
            color: ${vars.theme.grey};
        }
        
        ${p => p.active && css`
            color: ${vars.theme.red};
            &:after {
                border-color: ${vars.theme.red};
            }
        `}
        
        &:hover {
            color: ${vars.theme.red};
            &:after {
                border-color: ${vars.theme.red};
            }
        }
    }
`

Nav.List = styled.div`
    padding-left: 16px;
    border-left: 1px solid ${vars.theme.outlineGrey};
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    height: ${p => p.isOpen ? "auto" : "0"};
    transition: ${p => p.isOpen ? "opacity 0.25s ease 0.1s, visibility 0s ease 0.1s" : "opacity 0.1s ease 0s, visibility 0s ease 0.1s"};
`

Nav.Item = styled.div`
    &:not(:last-child) {
        padding: 0 16px;
    }
    &:last-child {
        padding-left: 16px;
        margin-left: auto;
    }
    ${p => p.withDropdown && css`
        position: relative;
        display: flex;
        align-items: center;
        &:after {
            content: '';
            margin-bottom: ${p => p.isOpen ? "0" : "4px"};
            margin-left: 8px;
            width: 8px;
            height: 8px;
            border-right: 1.5px solid ${vars.theme.grey};
            border-bottom: 1.5px solid ${vars.theme.grey};
            background: transparent;
            transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
            transition: all .3s;
        }
    `}
    ${maxWidth(vars.breakpoints.sDesktop)`
        &:not(:last-child) {
            padding: 0 12px;
        }
    `}
    ${maxWidth(1155)`
        &:not(:last-child) {
            padding: 0 8px;
        }
    `}
    ${maxWidth(1100)`
        &:not(:last-child) {
            padding: 0 6px;
        }
        &:last-child {
            padding-left: 6px;
            margin-left: auto;
        }
    `}
    ${maxWidth(vars.breakpoints.tablet)`
        position: static;
        &:after {
            display: none;
        }
    `}
`

Nav.ItemTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    ${p => !p.noPointer && "cursor: pointer;"}
    ${p => !p.link && "padding: 30px 0;"}
    transition: .2s;
    a {
        display: block;
        padding: 30px 0;
    }
    svg {
        margin-left: 6px;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${vars.theme.red};
        transform: scale(0);
        transform-origin: 0 0;
        transition: transform 0.2s linear 0s;
    }
    &:hover {
        color: ${vars.theme.darkGrey};
        &:after {
            transform: none;
        }
    }
    ${p => p.active && css`
        &:after {
            transform: none;
        }
    `}
    ${p => p.mobile && css`
        display: none;
    `}
    ${maxWidth(vars.breakpoints.tablet)`
        a {
            padding: 17px 0;
        }
        ${p => !p.link && "padding: 17px 0;"}
        ${p => p.desktop && css`
            display: none;
        `}
        ${p => p.mobile && css`
            display: flex;
        `}
        svg {
            margin: 0;
        }
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 12px;
        line-height: 24px;
    `}
`

Nav.ItemDropdown = styled.div`
    position: absolute;
    top: calc(100% - 16px);
    right: 0;
    z-index: 5;
    display: block;
    width: 320px;
    background: #fff;
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(20px);
    transition: opacity 0.3s ease-in, visibility 0s 0.3s ease-in, transform 0.3s ease-out;
    cursor: default;
    overflow: hidden;
    
    ${props =>
    props.isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: none;
      transition: none;
      transition: opacity 0.3s ease, visibility 0s, transform 0.3s ease-out;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        border-radius: 0;
        top: 100%;
        left: 0;
        width: 100%;
    `}
`

Nav.ItemDropdownRow = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  padding: 0 16px;
    &:first-child, &:last-child {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  ${p => p.border && `border-top: 1px solid ${vars.theme.outlineGrey};`}
    & > svg {
        margin-left: 8px;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.contacts && css`
            a {
                padding: 17px 0;
            }
        `}
    `}
`

Nav.Contract = styled.div`
    display: grid;
    grid-template-areas: 'number status' ${p => p.date && "'date date'"};
  grid-template-columns: max-content;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  width: 100%;
    padding: 16px;
    background: #F0F4FA;
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.tablet)`
    `}
`

Nav.ContractNumber = styled.div`
    grid-area: number;
    ${maxWidth(vars.breakpoints.tablet)`
    `}
`

Nav.ContractStatus = styled.div`
    grid-area: status;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  color: ${p => p.paid ? vars.theme.blue : vars.theme.red};
  &:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    margin-right: 6px;
    border-radius: 50%;
    background: ${p => p.paid ? vars.theme.blue : vars.theme.red};
  }
    ${maxWidth(vars.breakpoints.tablet)`
    `}
`

Nav.ContractDate = styled.div`
    grid-area: date;
    font-size: 12px;
    color: #5C6A79;
    ${maxWidth(vars.breakpoints.tablet)`
    `}
`

Nav.Popular = styled.div`
    flex: 4.5;
    padding: 28px 60px;
`

Nav.PopularImg = styled.div`
    margin-bottom: 20px;
    background: ${vars.theme.lightGrey};
    background-image: radial-gradient(#fff 10%, rgba(255,255,255,0) 20%);
    background-size: 20px 20px;
    img {
        max-height: 204px;
        margin: 0 auto;
    }
`

Nav.PopularTitle = styled.div`
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
`

Nav.PopularConditions = styled.div`
    display: flex;
    margin: 0 -8px;
`

Nav.PopularCondition = styled.div`
    flex: 1;
    margin: 0 8px;
`

Nav.PopularConditionTitle = styled.div`
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
`

Nav.PopularConditionTitle = styled.div`
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
`

Nav.NewsLeft = styled.div`
    flex: 1;
    margin-right: 20px;
`

Nav.NewsRight = styled.div`
    flex: 3;
`

Nav.NewsInner = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
`

Nav.SubmenuTitle = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    transition: .3s;
    &:after {
        content: '';
        display: block;
        width: 8.5px;
        height: 8.5px;
        margin-left: 12px;
        border-right: 1px solid ${vars.theme.darkGrey};
        border-bottom: 1px solid ${vars.theme.darkGrey};
        transform: rotate(-45deg);
        transition: .3s;
    }
`

Nav.SubmenuText = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Nav.SubmenuItem = styled.div`
    position: relative;
    width: calc((100% - 60px) / 4);
    &:not(:last-child) {
        margin-right: 20px;
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    
    &:hover {
        ${Nav.SubmenuTitle} {
            color: ${vars.theme.red};
            &:after {
                margin-left: 16px;
                border-color: ${vars.theme.red};
            }
        }
    }
`

Nav.ProfileEdit = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    font-weight: normal;
    font-size: 14px;
    color: #5C6A79;
    cursor: pointer;
    
    svg {
        margin-right: 4px;
    }
    
    &:hover {
        opacity: .8;
    }
`

export default Nav
