import styled, {css} from 'styled-components'
import vars from '@/assets/styles/vars'
import { maxWidth } from '@/assets/styles/mixins.css'

const Categories = styled.div`
    display: flex;
    margin-top: -45px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px ${p => p.noLeftRadius ? 0 : "8px"};
    // filter: drop-shadow(0px 4px 32px rgba(61, 75, 86, 0.1));
    box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
    ${maxWidth(vars.breakpoints.mTablet)`
        flex-wrap: wrap;
        margin: 0;
        border-radius: 8px;
    `}
`

Categories.Section = styled.section`
    position: relative;
    z-index: 1;
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.grey ? css`
            padding: 0 0 32px;
            background: ${vars.theme.lightGrey};
        ` : css`
            padding: 32px 0;
            border-bottom: 1px solid ${vars.theme.outlineGrey};
        `}
    `}
`

Categories.Inner = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    ${maxWidth(vars.breakpoints.tablet)`
    `}
`

Categories.Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: ${vars.theme.lightGrey};
    border-radius: 8px;
    
    svg {
        fill: #fff;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        display: none;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        display: flex;
    `}
`

Categories.Content = styled.div`
    margin-right: 16px;
`

Categories.Title = styled.div`
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    transition: .3s;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin: 0;
        font-size: 16px;
        line-height: 24px;
    `}
`

Categories.Text = styled.div`
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Categories.Submenu = styled.div`
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 0 24px 18px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
    z-index: 2;
    height: ${p => p.visible ? "auto" : 0};
    opacity: ${p => p.visible ? 1 : 0};
    visibility: ${p => p.visible ? "visible" : "hidden"};
    transition: ${p => p.visible ? "opacity 0.25s ease, visibility 0s ease" : "opacity 0.1s ease 0s, visibility 0s ease"} , height .3s;
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 0 16px 8px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        position: static;
        padding: ${p => p.visible ? "8px 0 0" : 0};
    `}
`

Categories.SubmenuItem = styled.div`
    a {
        display: block;
        padding: 6px 0;
        transition: .3s;
        &:hover {
            color: ${vars.theme.red};
        }
    }
`

Categories.Item = styled.div`
    position: relative;
    flex: 1;
    padding: 24px;
    &:not(:first-child) {
        ${p => !p.isButton && `border-left: 1px solid ${vars.theme.outlineGrey};`}
    }
    & > a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    ${p => p.center && "text-align: center;"}
    ${p => p.arrow && css`
        ${Categories.Text} {
            &:after {
                content: '';
                display: block;
                width: 7px;
                height: 7px;
                margin-left: 7px;
                margin-bottom: ${p => p.active ? 0 : "4px"};
                border-right: 1px solid ${vars.theme.darkGrey};
                border-bottom: 1px solid ${vars.theme.darkGrey};
                transform: rotate(${p => p.active ? "-135deg" : "45deg"});
                transition: .3s;
            }
        }
    `}
    ${p => p.link && css`
        &:hover {
            ${Categories.Title} {
                color: ${vars.theme.red};
            }
        }
    `}
    
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
        flex: initial;
        text-align: left;
        &:not(:first-child) {
            border-left: none;
        }
        ${p => p.category ? css`
            width: 100%;
            &:not(:first-child) {
                border-top: 1px solid ${vars.theme.outlineGrey};
            }   
        ` : css`
            ${p.isButton ? css`
                width: 100%;
            ` : css`
                width: 50%;
            `}
            ${!p.button && !p.isButton && css`
                &:nth-child(2n + 1) {
                    border-right: 1px solid ${vars.theme.outlineGrey};
                }
                &:nth-child(1), &:nth-child(2) {
                    border-bottom: 1px solid ${vars.theme.outlineGrey};
                }
                text-align: center;
            `}
            ${p.button && css`
                &:nth-child(3) {
                    padding: 0 16px 8px;
                }
            `}
        `}
    `}
`

export default Categories
