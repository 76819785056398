import Lightbox from 'react-image-lightbox'

export default function ({ images, photoIndex, onChange }) {
    return (
        <div>
            {photoIndex !== null && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(+photoIndex + 1) % images.length]}
                    prevSrc={images[(+photoIndex + images.length - 1) % images.length]}
                    clickOutsideToClose={true}
                    enableZoom={false}
                    onCloseRequest={() => onChange(null)}
                    onMovePrevRequest={() =>
                        onChange((+photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() => onChange((+photoIndex + 1) % images.length)}
                />
            )}
        </div>
    )
}
