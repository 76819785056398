import styled, {css} from 'styled-components'
import {rgba, maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Quote = styled.div`
    position: relative;
    ${p => p.mt && "margin-top: 56px;"}
    padding-left: 16px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: ${p => p.grey ? vars.theme.darkGrey : vars.theme.black};
    &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        display: block;
        width: 2px;
        height: calc(100% - 8px);
        background: ${vars.theme.red};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.mt && "margin-top: 24px;"}
        font-size: 14px;
        line-height: 20px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.mt && "margin-top: -12px;"}
    `}
`

export default Quote
