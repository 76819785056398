import { useMemo, useRef } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock, ProductCard } from '@/components'
import Slider from 'react-slick'

export default function ({ title, blocks }) {
    const sliderRef = useRef()

    const settings = useMemo(
        () => ({
            slidesToShow: blocks.length < 3 ? blocks.length : 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: blocks.length < 2 ? blocks.length : 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        variableWidth: true,
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 650,
                    settings: {
                        variableWidth: true,
                        slidesToShow: 1,
                    },
                },
            ],
        }),
        [blocks],
    )

    return (
        <StyledSection pb="small" pt="small">
            <StyledContainer>
                <SliderBlock
                    title={title}
                    length={blocks.length}
                    count="3"
                    dots
                    sliderRef={sliderRef}
                >
                    <Slider {...settings} ref={sliderRef}>
                        {blocks.map((item) => (
                            <ProductCard
                                key={item.id}
                                type="partner"
                                title={item.title}
                                img={item.image.banner_385x248}
                                conditions={item.list}
                                fullImg
                            />
                        ))}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
        </StyledSection>
    )
}
