import { StyledNews } from '@/components/styles'
import { SvgIcon, Image } from '@/components'
import Link from 'next/link'
import numberToString from '@/lib/numberToString'
import moment from 'moment'

export default function ({
    img,
    title,
    views,
    date,
    link,
    url,
    slug,
    small,
    smallImg,
    fixedWidth,
}) {
    return (
        <StyledNews.Card fixedWidth={fixedWidth}>
            <StyledNews.Img mbSmall={small} logo={link} small={smallImg}>
                <img src={smallImg ? img.preview : img.x1} alt="" />
            </StyledNews.Img>
            <StyledNews.Title small mb="small" xSmall={small} as="div">
                {title}
            </StyledNews.Title>
            <StyledNews.Info small={small}>
                {!link && (
                    <>
                        <StyledNews.Views>
                            {views} {numberToString(views, ['просмотр', 'просмотра', 'просмотров'])}
                        </StyledNews.Views>
                        {moment(date).format('DD MMMM YYYY')}
                    </>
                )}
                {link && (
                    <>
                        <SvgIcon name="link" width="16" height="16" />
                        Перейти на {url ?? link}
                    </>
                )}
            </StyledNews.Info>
            {slug && (
                <Link href="/press-center/[articleSlug]" as={`/press-center/${slug}`}>
                    <a />
                </Link>
            )}
            {link && <a href={link} target="_blank" />}
        </StyledNews.Card>
    )
}
