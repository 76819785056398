import { StyledCard, StyledText } from '@/components/styles'

export default function ({ year, text, img }) {
    return (
        <StyledCard type="history" fixedWidth>
            <StyledCard.Year>{year}</StyledCard.Year>
            <StyledCard.Image blurImg="0.8">
                <img src={img} alt="" />
            </StyledCard.Image>
            <StyledCard.Text white>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </StyledCard.Text>
        </StyledCard>
    )
}
