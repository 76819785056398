import { StyledSection, StyledTitle, StyledSlider } from '@/components/styles'
import { SliderArrows } from '@/components'

export default function ({
    children,
    length,
    count,
    title,
    sliderRef,
    gradient,
    currentSlide,
    clients,
    sertificates,
    whiteContent,
    showArrows,
}) {
    showArrows = showArrows?? false;
    return (
        <>
            <StyledSection.Top>
                <StyledTitle mb="none" white={whiteContent} as="h2">
                    {title}
                </StyledTitle>
                {length > count && (
                    <SliderArrows
                        sliderRef={sliderRef}
                        currentSlide={currentSlide}
                        length={length}
                        opacity={whiteContent}
                        showArrows={showArrows}
                    />
                )}
            </StyledSection.Top>
            <StyledSlider
                gradient={gradient}
                clients={clients}
                sertificates={sertificates}
                whiteDots={whiteContent}
            >
                {children}
            </StyledSlider>
        </>
    )
}
