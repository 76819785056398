import { useState } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledTitle,
    StyledMobileOffices,
    StyledButton,
} from '@/components/styles'
import { MobileOffice, Filter } from '@/components'
import moment from 'moment'
import 'moment/locale/ru'
import { useRouter } from 'next/router'
import { DayPickerRangeController } from 'react-dates'
import getWindowWidth from '@/lib/getWindowWidth'

moment.locale('ru')

export default function ({ offices, cities }) {
    const [allIsVisible, setAllIsOpen] = useState(offices.length <= 10)
    const [dateIsOpen, setDateIsOpen] = useState(false)
    const [focusedInput, setFocusedInput] = useState('startDate')
    const [cityIsOpen, setCityIsOpen] = useState(false)
    const windowWidth = getWindowWidth()

    const router = useRouter()
    const query = router.query

    const handleChange = (type, value) => {
        let newQuery = { ...query, [type]: value }

        router.push(
            { pathname: router.pathname, query: newQuery },
            { pathname: location.pathname, query: newQuery },
            { shallow: false },
        )
    }

    return (
        <StyledSection pb="small">
            <StyledContainer>
                <StyledSection.Top tabletColumn>
                    <StyledTitle mb="none">Расписание мобильного офиса</StyledTitle>
                    <StyledMobileOffices.FilterWrapper>
                        <Filter
                            isOpen={dateIsOpen}
                            setIsOpen={setDateIsOpen}
                            value={
                                query?.dateFrom
                                    ? `${moment(query.dateFrom).format('DD.MM.YYYY')} ${
                                          query?.dateTo
                                              ? ` - ${moment(query.dateTo).format('DD.MM.YYYY')}`
                                              : ''
                                      }`
                                    : windowWidth > 768
                                    ? 'Выбрать дату'
                                    : 'Дата'
                            }
                            mobileTitle="Выбор даты"
                        >
                            <DayPickerRangeController
                                startDate={query?.dateFrom && moment(query.dateFrom)}
                                endDate={query?.dateTo && moment(query.dateTo)}
                                isDayBlocked={(day) => day.isBefore(moment(new Date()))}
                                minimumNights={0}
                                keepOpenOnDateSelect={true}
                                monthFormat="MMMM"
                                numberOfMonths={1}
                                orientation="horizontal"
                                renderMonthElement={null}
                                renderMonthText={(month) => moment(month).format('MMMM')}
                                renderDayContents={(day) => moment(day).format('D')}
                                onDatesChange={(dates) => {
                                    handleChange(
                                        'dateFrom',
                                        moment(dates.startDate).format('YYYY-MM-DD'),
                                    )
                                    dates.endDate &&
                                        handleChange(
                                            'dateTo',
                                            moment(dates.endDate).format('YYYY-MM-DD'),
                                        )
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={(focusedInput) =>
                                    setFocusedInput(focusedInput || 'startDate')
                                }
                            />
                        </Filter>
                        <Filter
                            isOpen={cityIsOpen}
                            setIsOpen={setCityIsOpen}
                            value={
                                query?.city?.length
                                    ? cities.find((item) => item.id == query.city).title
                                    : windowWidth > 768
                                    ? 'Выбрать город'
                                    : 'Город'
                            }
                            mobileTitle="Выбор города"
                        >
                            {query?.city?.length > 0 && (
                                <StyledMobileOffices.FilterCity
                                    active
                                    onClick={() => {
                                        handleChange('city', null)
                                        setCityIsOpen(false)
                                    }}
                                >
                                    {cities.find((item) => item.id == query.city).title}
                                </StyledMobileOffices.FilterCity>
                            )}
                            {cities
                                .filter((item) => item.id !== query?.city)
                                .map((item) => (
                                    <StyledMobileOffices.FilterCity
                                        key={item.id}
                                        onClick={() => {
                                            handleChange('city', item.id)
                                            setCityIsOpen(false)
                                        }}
                                    >
                                        {item.title}
                                    </StyledMobileOffices.FilterCity>
                                ))}
                        </Filter>
                    </StyledMobileOffices.FilterWrapper>
                </StyledSection.Top>
                {offices?.length > 0 ? (
                    <StyledMobileOffices>
                        {(offices.length > 10 && !allIsVisible
                            ? offices.slice(0, 10)
                            : offices
                        ).map((item) => (
                            <MobileOffice key={item.id} office={item} />
                        ))}
                    </StyledMobileOffices>
                ) : (
                    <StyledTitle small mb="none">
                        Ничего не найдено
                    </StyledTitle>
                )}
                {offices.length > 10 && !allIsVisible && (
                    <StyledButton width="full" bg="white" onClick={() => setAllIsOpen(true)}>
                        Показать больше
                    </StyledButton>
                )}
            </StyledContainer>
        </StyledSection>
    )
}
