import styled from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'
import {StyledText} from '@/components/styles'

const Test = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-image: linear-gradient(102.88deg, #89A6BE 10%, transparent 50%, #68869F 85%), radial-gradient(${rgba(vars.theme.outlineGrey, .2)} 10%, transparent 20%), linear-gradient(102.88deg, #89A6BE 15.26%, #68869F 68.03%);
    background-size: cover, 20px 20px, cover;
    background-repeat: no-repeat, repeat, no-repeat;
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
        background: linear-gradient(102.88deg, #89A6BE 15.26%, #68869F 68.03%);
    `}
`

Test.Close = styled.div`
    text-align: right;
    svg {
        cursor: pointer;
    }
`

Test.Wrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 16px;
    z-index: 4;
    opacity: ${p => p.isVisible ? 1 : 0};
    transform: ${p => p.isVisible ? "none" : "translateY(100%)"};
    transition: transform 0.4s ease-out 0s, opacity 0.3s linear 0s;
    ${maxWidth(vars.breakpoints.mTablet)`
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 100%);
    `}
`

Test.Title = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    span {
        font-weight: 300;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        span {
            display: none;
        }
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
        font-weight: 300;
    `}
`

Test.Buttons = styled.div`
    display: grid;
    grid-template-columns: 120px 190px;
    gap: 16px;
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 2fr 3fr;
    `}
`

Test.Modal = styled.div`
    display: grid;
    grid-template-columns: 5fr 7fr;
    height: 640px;
    max-height: calc(100vh - 120px);
    ${maxWidth(vars.breakpoints.tablet)`
        height: 480px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 1fr;
        height: auto;
        max-height: inherit;
        min-height: calc(100vh - 80px);
    `}
`

Test.Left = styled.div`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 7.12%, rgba(0, 0, 0, 0.75) 100%), url('/images/test.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 48px;
    ${maxWidth(vars.breakpoints.sDesktop)`
        padding: 32px 24px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        display: none;
    `}
`

Test.Inner = styled.div`
    position: relative;
    padding: 48px 48px 32px;
    form {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    ${maxWidth(vars.breakpoints.sDesktop)`
        padding: 48px 24px 32px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 48px 20px 20px;
    `}
`

Test.Top = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    ${maxWidth(vars.breakpoints.sDesktop)`
        margin-bottom: 16px;
    `}
`

Test.Back = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    color: ${vars.theme.darkGrey};
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
`

Test.Counter = styled(StyledText)`
    margin-left: auto;
`

Test.Progress = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${p => p.width}%;
    height: 3px;
    background: ${vars.theme.red};
    transition: .3s;
`

Test.Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

Test.FormFields = styled.div``

Test.Button = styled.div`
    button {
        margin-left: auto;
    }
`

Test.Result = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

Test.Cards = styled.div`
    flex: 1;
`

Test.Card = styled.div`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    padding: 16px;
    background: ${vars.theme.lightGrey};
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 4px;
`

Test.CardTitle = styled.div`
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
`

Test.CardLogo = styled.div`
    float: right;
`

Test.CardText = styled(StyledText)`
    margin-bottom: 16px;
`

Test.Again = styled(StyledText)`
    display: flex;
    align-items: center;
    font-weight: normal;
    color: ${vars.theme.darkGrey};
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
`

export default Test
