import { useEffect, useState } from 'react'
import {
    ObjectsBlock,
    CardsBlock,
    HistoryBlock,
    PromoBlock,
    Quarantees,
    ProductDesc,
    Seo,
    Sertificates,
    Advantages,
    PartnerProgramms,
    CreditLawyer,
    ServicesBlock,
    ClientsBlock,
    CalculatorTabsBlock,
    CallbackModal,
} from '@/components'
import { useCalculator } from '@/contexts/CalculatorContext'
import api from '@/lib/api'

const components = {
    type_list_blocks: 'List',
    type_slider_blocks: 'Slider',
    type_block_big_image_left_title_button_description: PromoBlock,
    type_title_description_image_fact: PromoBlock,
    type_tab_list: ProductDesc,
    type_title_description: Seo,
    type_slider_images: Sertificates,
    type_our_advantages: 'Advantages',
    type_block_title_description_blocks: Advantages,
    type_credit_attorney: CreditLawyer,
    type_unique_conditions: ServicesBlock,
    type_success_our_clients: ClientsBlock,
    type_calculator_list: CalculatorTabsBlock,
}

function DynamicComponent({ block, createAdvantages, advPB, onBtnClick }) {
    const { setCalculator } = useCalculator()

    useEffect(() => {
        block.type === 'type_calculator_list' && setCalculator(block.blocks[0].calculator)
    }, [block])

    const Component = components[block.type]

    if (!Component) {
        return null
    }

    return Component === PromoBlock ? (
        <Component
            title={block.title}
            text={block.description}
            img={block.image.banner_360}
            quote={block.fact}
            onBtnClick={onBtnClick}
            grey={block.is_background}
            reverse={
                block.image_location === 'right' ||
                block.type === 'type_title_description_image_fact'
            }
            border={block.type === 'type_title_description_image_fact'}
            largeContent={
                block.image_location === 'right' ||
                block.type === 'type_title_description_image_fact'
            }
        />
    ) : Component === 'List' ? (
        block.blocks?.length > 0 &&
        (block.blocks[0].type === 'type_block_right_image_title_description' ? (
            <ObjectsBlock title={block.title} blocks={block.blocks} />
        ) : (
            <Quarantees title={block.title} blocks={block.blocks} />
        ))
    ) : Component === 'Slider' ? (
        block.blocks?.length > 0 &&
        (block.blocks[0].type === 'type_title_image_description' ? (
            <HistoryBlock title={block.title} blocks={block.blocks} />
        ) : block.blocks[0].type === 'type_block_image_title_list' ? (
            <PartnerProgramms title={block.title} blocks={block.blocks} />
        ) : (
            <CardsBlock
                title={block.title}
                blocks={block.blocks}
                count={
                    block.title === 'Схема работы' ||
                    (block.blocks[0].type === 'type_block_icon_description' &&
                        block.blocks[0].position === 'horizontal') ||
                    block.blocks?.length < 4
                        ? 3
                        : 4
                }
                onBtnClick={onBtnClick}
            />
        ))
    ) : Component === ProductDesc ? (
        <Component tabs={block.blocks} onBtnClick={onBtnClick} />
    ) : Component === 'Advantages' ? (
        createAdvantages()
    ) : Component === Advantages ? (
        <Component
            pt="medium"
            pb={advPB ?? 'medium'}
            title={block.title}
            subtitle={block.description}
            blocks={block.blocks}
        />
    ) : Component === CalculatorTabsBlock ? (
        <Component blocks={block.blocks} calculator={block.blocks[0].calculator} />
    ) : (
        <Component
            title={block.title}
            subtitle={block.description}
            text={block.description}
            blocks={block.blocks}
            images={block.images}
            count={block.title === 'Схема работы' || block.blocks?.length < 4 ? 3 : 4}
            onBtnClick={onBtnClick}
            tariffs={block.tariffs}
        />
    )
}

function EBlocks({ blocks = [], createAdvantages, advPB, title }) {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    return (
        <>
            {blocks.map((block) => (
                <DynamicComponent
                    block={block}
                    key={block.id}
                    createAdvantages={createAdvantages}
                    advPB={advPB}
                    onBtnClick={() => setModalIsOpen(true)}
                />
            ))}
            <CallbackModal
                isOpen={modalIsOpen}
                onClose={setModalIsOpen}
                title="Оставьте заявку на получение денег"
                text="Укажите свои данные, чтобы мы смогли с вам связаться."
                onSubmitMethod={(values) =>
                    api.customRecord({
                        ...values,
                        credit_type: `Онлайн-заявка "${title}"`,
                    })
                }
                metrics
            />
        </>
    )
}

export default EBlocks
