import { default as StyledSocials } from '@/components/styles/StyledSocials'
export default function () {
    return (
        <StyledSocials>
            <StyledSocials.List>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://www.instagram.com/center_finance/" target="blank">
                        <span class="icon-ig"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://www.youtube.com/@center_finance" target="blank">
                        <span class="icon-yt"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://www.tiktok.com/@center_credit" target="blank">
                        <span class="icon-tt"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://www.facebook.com/centerfinancecfc" target="blank">
                        <span class="icon-fb"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://vk.com/center_finance" target="blank">
                        <span class="icon-vk"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://ok.ru/centerfinance" target="blank">
                        <span class="icon-ok"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
                <StyledSocials.Item>
                    <StyledSocials.Link href="https://t.me/crckredit" target="blank">
                        <span class="icon-tg"></span>
                    </StyledSocials.Link>
                </StyledSocials.Item>
            </StyledSocials.List>
        </StyledSocials>
    )
}