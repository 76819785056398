import { StyledCard } from '@/components/styles'
import { RouteLink } from '@/components'

export default function ({ title, text, img, icon, slug, city }) {
    return (
        <StyledCard type="avto">
            <StyledCard.Avto>
                {img && (
                    <StyledCard.Image>
                        <img src={img} alt="" />
                    </StyledCard.Image>
                )}
                {title && <StyledCard.Title mb={text ? 'small' : 'none'}>{title}</StyledCard.Title>}
                {text && <StyledCard.Text grey>{text}</StyledCard.Text>}
                {icon && (
                    <StyledCard.Icon white mbReset>
                        <img src={icon} alt="" />
                    </StyledCard.Icon>
                )}
                {slug && (
                    <RouteLink slug={slug} city={city}>
                        <a />
                    </RouteLink>
                )}
            </StyledCard.Avto>
        </StyledCard>
    )
}
