import {
    StyledSection,
    StyledContainer,
    StyledTitle,
    StyledObjects,
    StyledText,
} from '@/components/styles'
import { RouteLink } from '@/components'

export default function ({ title, blocks }) {
    return (
        <StyledSection pt="small" pb="small">
            <StyledContainer>
                <StyledTitle>{title}</StyledTitle>
                <StyledObjects>
                    {blocks.map((item) => (
                        <StyledObjects.Item key={item.id} grey={item.is_background}>
                            <StyledObjects.ItemInner>
                                <StyledObjects.ItemTitle>{item.title}</StyledObjects.ItemTitle>
                                <StyledText grey>{item.description}</StyledText>
                                {(item?.page?.slug || item?.page?.category?.slug) && (
                                    <StyledObjects.ItemLink>
                                        <StyledObjects.ItemLinkText>
                                            Подробнее
                                        </StyledObjects.ItemLinkText>
                                        <RouteLink
                                            slug={item?.page?.category?.slug ?? item?.page?.slug}
                                            city={item.page.city}
                                        >
                                            <a />
                                        </RouteLink>
                                    </StyledObjects.ItemLink>
                                )}
                            </StyledObjects.ItemInner>
                            <StyledObjects.ItemImg>
                                <img src={item.image.banner_200} alt="" />
                            </StyledObjects.ItemImg>
                        </StyledObjects.Item>
                    ))}
                </StyledObjects>
            </StyledContainer>
        </StyledSection>
    )
}
