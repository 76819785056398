import { StyledSection, StyledContainer } from '@/components/styles'
import { Services } from '@/components'

export default function ({ tariffs }) {
    return (
        <StyledSection dottedBackground pt="large">
            <StyledContainer>
                <Services tariffs={tariffs} />
            </StyledContainer>
        </StyledSection>
    )
}
