import { StyledClient } from '@/components/styles'

export default function ({ active, photo, name, desc, type }) {
    return (
        <StyledClient.Card active={active}>
            <StyledClient.Photo>
                <img src={photo} alt="" />
            </StyledClient.Photo>
            <StyledClient.Desc>
                <StyledClient.Name>{name}</StyledClient.Name>
                <StyledClient.Info>{desc}</StyledClient.Info>
                <StyledClient.Type>{type}</StyledClient.Type>
            </StyledClient.Desc>
        </StyledClient.Card>
    )
}
