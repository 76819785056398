import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Arvantages = styled.div`
    display: grid;
    grid-template-columns: 1fr 2.2fr;
    gap: 46px;
    ${maxWidth(vars.breakpoints.tablet - 1)`
        display: block;
  `}
`

Arvantages.Title = styled.h2`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${p => p.white ? vars.theme.grey : "#5C6A79"};
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 16px;
    `}
`

Arvantages.Inner = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 44px 28px;
    ${maxWidth(vars.breakpoints.tablet)`
        gap: 32px 20px;
  `}
    ${maxWidth(vars.breakpoints.mTablet)`
        gap: 24px;
  `}
    ${maxWidth(vars.breakpoints.lMobile)`
        grid-template-columns: 1fr;
        gap: 20px;
  `}
`

Arvantages.Item = styled.div`
    display: flex;
`

Arvantages.ItemIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-right: 24px;
    background: ${p => p.opacity ? "rgba(255, 255, 255, 0.1)" : "#FFFFFF"};
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 22px rgba(61, 75, 86, 0.08);
    border-radius: 12px;
    
    svg {
        fill: ${p => (p.opacity || p.grey) ? vars.theme.outlineGrey : "#fff"};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        width: 56px;
        height: 56px;
        margin-right: 16px;
    `}
`

Arvantages.ItemContent = styled.div``

Arvantages.ItemTitle = styled.div`
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    ${p => p.white && "color: #fff;"}
    ${maxWidth(vars.breakpoints.tablet - 1)`
        font-size: 16px;
        line-height: 24px;
    `}
`

export default Arvantages
