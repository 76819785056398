import { useMemo, useRef } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock, NewsCard, SEO } from '@/components'
import Slider from 'react-slick'

export default function ({ articles }) {
    const sliderRef = useRef()

    const settings = useMemo(
        () => ({
            slidesToShow: articles.length < 3 ? articles.length : 3,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            lazyLoad: true,
            responsive: [
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: articles.length < 2 ? articles.length : 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: articles.length < 2 ? articles.length : 2,
                        variableWidth: true,
                    },
                },
            ],
        }),
        [articles],
    )

    return (
        <>
        <StyledSection pt="small" pb="small">
            <StyledContainer>
                <SEO/>
            </StyledContainer>
        </StyledSection>
        <StyledSection pt="small" pb="small">
            <StyledContainer>
                <SliderBlock
                    title="Полезные статьи."
                    length={articles.length}
                    count="3"
                    sliderRef={sliderRef}
                >
                    <Slider {...settings} ref={sliderRef}>
                        {articles.map((item) => (
                            <NewsCard
                                key={item.id}
                                img={item.preview}
                                title={item.title}
                                views={item.views}
                                date={item.publication_date}
                                slug={item.slug}
                                fixedWidth
                            />
                        ))}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
        </StyledSection>
        </>
    )
}
