import { StyledMain, StyledContainer, StyledTitle, StyledButton } from '@/components/styles'
import { Breadcrumbs } from '@/components'

export default function ({
    title,
    text,
    img,
    backImage,
    breadcrumbs,
    buttonText,
    largeContent,
    onBtnClick,
    noDots,
    pb,
    whiteContent,
    aboutCompany,
    largeButton,
    facts,
    largeImg,
}) {
    return (
        <StyledMain.Section green={aboutCompany} dottedBackground={!noDots} backImage={backImage}>
            <StyledContainer>
                <StyledMain pb={pb} ptLarge={largeImg}>
                    {img && (
                        <StyledMain.Img largeImg={largeImg}>
                            <picture>
                                <source media="(max-width: 768px)" srcSet={img.preview_300} />
                                <img src={img.preview_530} alt="" />
                            </picture>
                        </StyledMain.Img>
                    )}
                    <StyledMain.Content
                        large={largeContent}
                        reverse={breadcrumbs}
                        smallHeight={breadcrumbs}
                    >
                        <StyledMain.Top reverse={breadcrumbs}>
                            <StyledTitle large mb="small" white={whiteContent} as="h1">
                                {title}
                            </StyledTitle>
                            <StyledMain.Text white={whiteContent}>{text}</StyledMain.Text>
                        </StyledMain.Top>
                        <StyledMain.Bottom reverse={breadcrumbs}>
                            {buttonText && !breadcrumbs && (
                                <StyledButton
                                    width={largeButton ? 'x-large' : 'large'}
                                    onClick={onBtnClick}
                                >
                                    {buttonText}
                                </StyledButton>
                            )}
                            {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                            {aboutCompany && (
                                <StyledMain.Conditions>
                                    <StyledMain.Condition medium>{facts[0]}</StyledMain.Condition>
                                    <StyledMain.Condition medium>{facts[1]}</StyledMain.Condition>
                                    <StyledMain.Condition small>{facts[2]}</StyledMain.Condition>
                                </StyledMain.Conditions>
                            )}
                        </StyledMain.Bottom>
                    </StyledMain.Content>
                </StyledMain>
            </StyledContainer>
        </StyledMain.Section>
    )
}
