import { StyledSection, StyledContainer, StyledTitle, StyledSlider } from '@/components/styles'
import { HelpCard } from '@/components'
import styled from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'
import Slider from 'react-slick'

const settings = {
    slidesToShow: 1,
    variableWidth: true,
    dots: true,
    arrows: false,
    slidesToScroll: 1,
}

export default function ({ title, blocks }) {
    return (
        <StyledSection pt="small" pb="small">
            <StyledContainer>
                <StyledTitle>{title}</StyledTitle>
                <Wrapper>
                    {blocks.map((item, idx) => (
                        <HelpCard
                            key={item.id}
                            counter={idx + 1}
                            icon={item.icon}
                            title={item.title}
                            text={item.description}
                            greyCard={item.is_background}
                            horizontal={item.position === 'horizontal'}
                        />
                    ))}
                </Wrapper>
                <MobileSlider>
                    <StyledSlider>
                        <Slider {...settings}>
                            {blocks.map((item, idx) => (
                                <HelpCard
                                    key={item.id}
                                    counter={idx + 1}
                                    icon={item.icon}
                                    title={item.title}
                                    text={item.description}
                                    greyCard={item.is_background}
                                    horizontal={item.position === 'horizontal'}
                                />
                            ))}
                        </Slider>
                    </StyledSlider>
                </MobileSlider>
            </StyledContainer>
        </StyledSection>
    )
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: none;
    `}
`

const MobileSlider = styled.div`
    display: none;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
    `}
`
