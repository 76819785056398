import { useEffect } from 'react'
import { StyledInput } from '@/components/styles'
import { SvgIcon, InputWrapper } from '@/components'

export default function ({
    label,
    type,
    error = [],
    setError,
    value,
    placeholder,
    as,
    mb,
    size,
    onChange,
    onClick,
}) {
    useEffect(() => {
        value &&
            setError(
                value.reduce(
                    (acc, item) => [
                        ...acc,
                        item.size > 6000000 ? 'Размер файла превышает 6MB' : '',
                    ],
                    [],
                ),
            )
    }, [value])

    return (
        <InputWrapper label={label} error={error} mb={mb}>
            <StyledInput.File
                type={type ? type : 'text'}
                placeholder={placeholder}
                value={value && value.name}
                onChange={(e) => onChange(Array.from(e.target.files))}
                onClick={onClick}
                error={error}
                size={size}
                as={as}
            >
                <StyledInput.FileUpload>
                    <SvgIcon name="attach" width="24" height="24" />
                </StyledInput.FileUpload>
                <StyledInput.FileInput
                    type="file"
                    multiple
                    key={value ? value.name : +new Date()}
                    files={value && value.name}
                    accept="image/jpeg, image/png, pdf"
                    onChange={(e) => onChange(Array.from(e.target.files))}
                />
            </StyledInput.File>
        </InputWrapper>
    )
}

export const Files = ({ value, onChange, error }) => {
    return value
        ? value.map((file, idx) => (
              <div key={idx}>
                  <StyledInput.FileValue>
                      <p>
                          {file.name}
                          <StyledInput.FileSize>
                              ({Math.round(file.size / 1000)} КБ)
                          </StyledInput.FileSize>
                      </p>
                      <StyledInput.FileRemove
                          onClick={() => onChange(value.filter((item) => item.name !== file.name))}
                      >
                          <SvgIcon name="close" width="16" height="16" />
                      </StyledInput.FileRemove>
                  </StyledInput.FileValue>
                  {error && error[idx] && (
                      <StyledInput.FileError>{error[idx]}</StyledInput.FileError>
                  )}
              </div>
          ))
        : null
}
