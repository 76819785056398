import { useEffect } from 'react'
import { StyledMobileOffices } from '@/components/styles'
import { SvgIcon } from '@/components'
import OutsideClickHandler from 'react-outside-click-handler'
import getWindowWidth from '@/lib/getWindowWidth'

export default function ({ isOpen, setIsOpen, value, mobileTitle, children }) {
    const windowWidth = getWindowWidth()

    useEffect(() => {
        document.body.className = isOpen && windowWidth <= 768 ? 'overflow' : ''
    }, [isOpen])

    return (
        <StyledMobileOffices.Filter>
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                <StyledMobileOffices.FilterValue isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                    {value}
                </StyledMobileOffices.FilterValue>
                <StyledMobileOffices.FilterDropdown isOpen={isOpen}>
                    <StyledMobileOffices.FilterClose onClick={() => setIsOpen(false)}>
                        <SvgIcon name="close" width="20" height="20" />
                        {mobileTitle}
                    </StyledMobileOffices.FilterClose>
                    {children}
                </StyledMobileOffices.FilterDropdown>
            </OutsideClickHandler>
        </StyledMobileOffices.Filter>
    )
}
