import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Video = styled.div`
    position: relative;
    display: ${p => p.mobile ? "none" : "block"};
    ${({size}) => {
        switch (size) {
            case 'large':
                return css`
                    padding-bottom: 81%;
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding-bottom: 415px;
                    `}
                    ${maxWidth(vars.breakpoints.mMobile)`
                        padding-bottom: 121%;
                    `}
                `
            case 'medium':
                return css`
                    padding-bottom: 78.25%;
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding-bottom: 400px;
                    `}
                    ${maxWidth(vars.breakpoints.mMobile)`
                        padding-bottom: 110%;
                    `}
                `
            case 'small':
                return css`
                    padding-bottom: 73.25%;
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding-bottom: 360px;
                    `}
                    ${maxWidth(vars.breakpoints.mMobile)`
                        padding-bottom: 111%;
                    `}
                `
            default:
                return css`
                    padding-bottom: 76.25%;
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding-bottom: 380px;
                    `}
                    ${maxWidth(vars.breakpoints.mMobile)`
                        padding-bottom: 380px;
                    `}
                `
        }
    }}
    height: 0;
    video {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        max-width: 500px;
        width: 100%;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        display: ${p => p.mobile ? "block" : "none"};
        max-width: 100%;
    `}
`

export default Video
