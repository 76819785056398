import { useState } from 'react'
import { Collapse } from '@/components'

export default function ({ questions }) {
    const [isOpen, setIsOpen] = useState(questions.length ? questions[0].id : null)

    return questions.map((item) => (
        <Collapse
            key={item.id}
            title={item.question}
            text={item.answer}
            isOpen={isOpen === item.id}
            setIsOpen={() => setIsOpen(isOpen === item.id ? null : item.id)}
        />
    ))
}
