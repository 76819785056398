import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {StyledTitle} from '@/components/styles'

const News = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px 20px;
    ${p => p.mb && "margin-bottom: 64px;"}
    ${maxWidth(vars.breakpoints.tablet)`
        gap: 40px 20px;
    `}
    ${maxWidth(850)`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${maxWidth(vars.breakpoints.mobile)`
        grid-template-columns: 1fr;
        gap: 32px;
    `}
`

News.Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    ${p => p.fixedWidth && "max-width: 390px;"}
    a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &:hover {
        ${News.Title} {
            color: ${vars.theme.red};
        }
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.fixedWidth && css`
            width: 280px;
            max-width: 80vw;
        `}
    `}
`

News.Img = styled.div`
    position: relative;
    ${p => p.small ? css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 80px;
        background: ${vars.theme.lightGrey};
        border: 1px solid ${vars.theme.outlineGrey};
        border-radius: 8px;
        margin-bottom: 24px;
    ` : css`
        height: 0;
        margin-bottom: ${p => p.mbSmall ? "16px" : "24px"};
        padding-bottom: 64%;
        img {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            width: 100%;
            height: 100%;
            object-fit: ${p => p.logo ? "contain" : "cover"};
        }
    `}
    
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 16px;
    `}
`

News.Title = styled(StyledTitle)`
    flex: 1;
    ${p => p.xSmall && css`
        font-size: 20px;
        line-height: 28px;
    `}
    transition: all .3s;
    ${maxWidth(vars.breakpoints.tablet)`
        font-size: 20px;
        line-height: 28px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 16px;
        line-height: 24px;
    `}
`

News.Info = styled.div`
    display: flex;
    align-items: center;
    ${p => p.mb && "margin-bottom: 48px;"}
    color: ${vars.theme.darkGrey};
    ${p => p.small && css`
        font-size: 14px;
        line-height: 20px;
    `}
    svg {
        margin-right: 12px;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.mb && "margin-bottom: 32px;"}
        font-size: 14px;
        line-height: 20px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.mb && "margin-bottom: 24px;"}
    `}
`

News.Views = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 12px;
    padding-right: 16px;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        display: block;
        width: 6px;
        height: 6px;
        background: ${vars.theme.darkGrey};
        opacity: 0.5;
        border-radius: 50%;
    }
`

News.ArticleImg = styled.div`
    margin-bottom: 48px;
    img {
        width: 100%;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 32px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 24px;
    `}
    ${maxWidth(vars.breakpoints.mobile)`
        margin: 0 -20px 24px;
    `}
`

export default News
