import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {StyledText} from "./index"

const Modal = styled.div``

Modal.Top = styled.div`
    ${p => p.mb && "margin-bottom: 32px;"}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
    `}
`

Modal.Subtitle = styled(StyledText)`
    margin-bottom: 16px;
    font-weight: normal;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
    `}
`

Modal.Close = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    background: transparent;
    border: none;
    outline: none;
    z-index: 1;
    cursor: pointer;
`

Modal.Inner = styled.div`
    padding: ${p => p.padding === 'small' ? "16px" : p.padding ? 0 : "32px"};
    ${maxWidth(vars.breakpoints.tablet)`
        padding: ${p => p.padding === 'small' ? "16px" : p.padding ? 0 : "24px"};
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: ${p => p.padding ? 0 : "16px"};
    `}
`

Modal.Map = styled.div`
    position: relative;
    height: calc(100vh - 112px);
    border-radius: 6px;
    overflow: hidden;
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
    `}
`

Modal.Block = styled.div`
    &:not(:last-child) {
        margin-bottom: 32px;
    }
`

Modal.BlockTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
    `}
`

Modal.Title = styled.div`
    ${p => !p.mbNone && "margin-bottom: 24px;"}
    font-weight: 500;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
    `}
`

Modal.Table = styled.div``

Modal.TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid ${vars.theme.outlineGrey};
    }
    &:not(:first-child) {
        padding-top: 16px;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        display: block;
    `}
`

Modal.InfoTitle = styled.div`
    color: ${vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-bottom: 4px;
    `}
`

Modal.InfoText = styled.div`
    text-align: right;
    ${maxWidth(vars.breakpoints.mMobile)`
        text-align: left;
    `}
`

Modal.ButtonWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    ${maxWidth(vars.breakpoints.mobile)`
        grid-template-columns: 1fr;
    `}
`

Modal.Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border-top: 1px solid ${vars.theme.outlineGrey};
    ${maxWidth(vars.breakpoints.mobile)`
        padding: 16px;
    `}
`

Modal.GreyBlock = styled.div`
    padding: 24px;
    background: ${vars.theme.lightGrey};
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.mobile)`
        padding: 16px;
    `}
`

Modal.GreyBlockTitle = styled.div`
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    ${maxWidth(vars.breakpoints.mobile)`
        margin-bottom: 4px;
    `}
`

Modal.GreyBlockText = styled.div`
    font-size: 20px;
    line-height: 32px;
    ${maxWidth(vars.breakpoints.mobile)`
        font-size: 16px;
        line-height: 24px;
    `}
`

Modal.Action = styled.div`
    ${p => p.as == 'button' && css`
        display: block;
        margin: 0 auto;
    `}
    color: ${vars.theme.red};
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
    &:active {
        opacity: 0.5;
    }
`

Modal.Error = styled.div`
    margin-top: 6px;
    color: ${vars.theme.red};
    font-weight: 400;
    font-size: 14px;
    text-align: center;
`

export default Modal
