import { useState } from 'react'
import { StyledContainer, StyledCategories } from '@/components/styles'
import { SvgIcon, RouteLink } from '@/components'
import { useInfo } from '@/contexts/InfoContext'
import OutsideClickHandler from 'react-outside-click-handler'

export default function ({}) {
    const [activeCategory, setActiveCategory] = useState(null)
    const { categories } = useInfo()

    return (
        <StyledCategories.Section>
            <StyledContainer>
                <StyledCategories
                    noLeftRadius={
                        categories.header.findIndex((item) => activeCategory === item.id) === 0
                    }
                >
                    {(categories.header.length > 3
                        ? categories.header.slice(0, 3)
                        : categories.header
                    ).map((item) => (
                        <StyledCategories.Item
                            key={item.id}
                            category
                            arrow={item.children?.length > 0}
                            active={activeCategory === item.id}
                            link={item.link}
                        >
                            <OutsideClickHandler
                                onOutsideClick={
                                    item.children?.length > 0 && activeCategory === item.id
                                        ? () => setActiveCategory(null)
                                        : () => {}
                                }
                            >
                                <StyledCategories.Inner
                                    onClick={
                                        item.children?.length > 0
                                            ? () =>
                                                  setActiveCategory(
                                                      activeCategory === item.id ? null : item.id,
                                                  )
                                            : undefined
                                    }
                                >
                                    <StyledCategories.Content>
                                        <StyledCategories.Title>{item.name}</StyledCategories.Title>
                                        <StyledCategories.Text>
                                            {item.children?.length > 0
                                                ? `${item.children.length} направления`
                                                : 'Снижаем кредитную нагрузку'}
                                        </StyledCategories.Text>
                                    </StyledCategories.Content>
                                    <StyledCategories.Icon>
                                        {item.icon && (
                                            <img
                                                src={
                                                    item.icon.icon ??
                                                    `/icons/white/${item.icon.title}.svg`
                                                }
                                                alt=""
                                                width="32"
                                                height="32"
                                            />
                                        )}
                                    </StyledCategories.Icon>
                                </StyledCategories.Inner>
                                {item.children?.length > 0 && (
                                    <StyledCategories.Submenu visible={activeCategory === item.id}>
                                        {item.children.map((subItem) => (
                                            <StyledCategories.SubmenuItem key={subItem.id}>
                                                <RouteLink slug={subItem.slug}>
                                                    <a>{subItem.name}</a>
                                                </RouteLink>
                                            </StyledCategories.SubmenuItem>
                                        ))}
                                    </StyledCategories.Submenu>
                                )}
                            </OutsideClickHandler>
                            {item.has_page && (
                                <RouteLink slug={item.slug}>
                                    <a />
                                </RouteLink>
                            )}
                        </StyledCategories.Item>
                    ))}
                </StyledCategories>
            </StyledContainer>
        </StyledCategories.Section>
    )
}
