import { StyledModal } from '@/components/styles'
import { Modal, Map } from '@/components'

export default function ({ office, isOpen, onClose }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} large padding="small">
            <StyledModal.Map>
                <Map placemarkList={[office]} />
            </StyledModal.Map>
        </Modal>
    )
}
