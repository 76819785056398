import { StyledCreditTest } from '@/components/styles'
import { useEffect, useRef, useState } from 'react'

export default function ({question, trueAnswer, num, func,hiddenClass}) {
    const [disabled, setDisabled] = useState(false)
    const [activeId, setActiveId] = useState()
    const listAnswers = useRef(null)

    useEffect(() => {
        const listAnswersElements = Array.from(listAnswers.current.children)
        const checkTrue = (e) => {
            let input = e.target.querySelector('input:checked')
            if(!input && e.target.nodeName == 'INPUT') {
                input = e.target;
            }
            setDisabled(true);
            setActiveId(input.value);

            if(input.value == trueAnswer) {
                listAnswersElements[input.value].classList.add('correct');

            } else {
                listAnswersElements[input.value].classList.add('error');

                listAnswersElements[trueAnswer].classList.add('correct');
                listAnswersElements[trueAnswer].classList.add('is-checked');
            }
        }
        listAnswersElements.forEach(answer => {
            answer.addEventListener('change', checkTrue)
        })
        
    }, [listAnswers])

    return (
        <>
            <h3>{num + 1}. {question.question}</h3>
            {question.answers ? (
            <StyledCreditTest.Answers data-num={num} ref={listAnswers}>
                {question.answers && question.answers.map((item, i)=> (
                    <StyledCreditTest.Answer className={activeId == i ? "is-checked" : null} key={`answer-${num}-${i}`}>
                        <StyledCreditTest.Label 
                            type="list"
                            checked={activeId == i}
                        >
                            <input 
                                type="radio"
                                name={num}
                                id={num}
                                value={i}
                                disabled = {disabled}
                                onChange={func}
                                checked={activeId == i}
                            />
                            {item}
                        </StyledCreditTest.Label>
                    </StyledCreditTest.Answer>
                ))}
            </StyledCreditTest.Answers>
            ) : ('')}
        </>
    )
}