import { useState } from 'react'
import { StyledTest, StyledContainer, StyledButton } from '@/components/styles'
import { TestModal, CallbackModal, SvgIcon } from '@/components'
import { useInfo } from '@/contexts/InfoContext'
import getWindowWidth from '@/lib/getWindowWidth'

export default function ({}) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalCallbackIsOpen, setModalCallbackIsOpen] = useState(false)
    const { setMayBeVisible, mayBeVisible, testIsVisible } = useInfo()
    const windowWidth = getWindowWidth()

    return (
        <StyledTest.Wrapper isVisible={testIsVisible && mayBeVisible}>
            <StyledContainer>
                <StyledTest>
                    {(windowWidth <= 550) && <StyledTest.Close onClick={() => setMayBeVisible(false)} >
                        <SvgIcon name="close" width="24" height="24" />
                    </StyledTest.Close>}
                    <StyledTest.Title>
                        Индивидуальный кредитный калькулятор
                        <br />
                        <span>Пройди тест и узнай, какой тип кредита подходит именно Вам!</span>
                    </StyledTest.Title>
                    <StyledTest.Buttons>
                        {(windowWidth <= 550) && <StyledButton
                            bg="transparent"
                            width="full"
                            onClick={() => setModalCallbackIsOpen(true)}
                        >
                            Оставить заявку
                        </StyledButton>}
                        {(windowWidth > 550) && <StyledButton
                                bg="transparent"
                                width="full"
                                onClick={() => setMayBeVisible(false)}
                            >
                                Закрыть
                        </StyledButton>}
                        <StyledButton
                            bg="white"
                            width="full"
                            onClick={() => {
                                setMayBeVisible(false)
                                setModalIsOpen(true)
                            }}
                        >
                            Пройти тест
                        </StyledButton>
                    </StyledTest.Buttons>
                </StyledTest>
            </StyledContainer>
            <TestModal isOpen={modalIsOpen} onClose={setModalIsOpen} />
            {(windowWidth <= 550) && 
            <CallbackModal
                isOpen={modalCallbackIsOpen}
                onClose={setModalCallbackIsOpen}
                title="Оставьте заявку на получение денег"
                text="Укажите свои данные, чтобы мы смогли с вам связаться."
                onSubmitMethod={(values) =>
                    api.customRecord({
                        ...values,
                        credit_type: `Онлайн-заявка "${title}"`,
                    })
                }
            />
        }
        </StyledTest.Wrapper>
    )
}
