import { useState } from 'react'
import { StyledSection, StyledContainer, StyledTitle, StyledDocuments } from '@/components/styles'
import { Lightbox } from '@/components'

export default function ({ title, docs }) {
    const [photoIndex, setPhotoIndex] = useState(null)

    return (
        <StyledSection pt="medium">
            <StyledContainer>
                <StyledTitle large>{title}</StyledTitle>
                <StyledDocuments>
                    {docs.map((item) => (
                        <StyledDocuments.Item key={item.id}>
                            <StyledDocuments.Img
                                onClick={() =>
                                    setPhotoIndex(docs.findIndex((doc) => doc.id === item.id))
                                }
                            >
                                <img src={item.image.small} alt="" />
                            </StyledDocuments.Img>
                            <StyledDocuments.Title>{item.description}</StyledDocuments.Title>
                            <StyledDocuments.Download>
                                <a href={item.file} target="_blank">
                                    Скачать
                                </a>
                            </StyledDocuments.Download>
                        </StyledDocuments.Item>
                    ))}
                </StyledDocuments>
                <Lightbox
                    images={docs.map((item) => item.image.large)}
                    photoIndex={photoIndex}
                    onChange={setPhotoIndex}
                />
            </StyledContainer>
        </StyledSection>
    )
}
