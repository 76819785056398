import { StyledBreadcrumbs } from '@/components/styles'
import Link from 'next/link'

export default function ({ breadcrumbs }) {
    return (
        <StyledBreadcrumbs>
            {breadcrumbs.map((item, idx) => (
                <StyledBreadcrumbs.Item key={idx}>
                    {item.link ? (
                        <Link href={item.link} as={item.as}>
                            <a>{item.title}</a>
                        </Link>
                    ) : (
                        item.title
                    )}
                </StyledBreadcrumbs.Item>
            ))}
        </StyledBreadcrumbs>
    )
}
