import { useState, useEffect } from 'react'
import { StyledSection, StyledContainer, StyledCalculator, StyledText } from '@/components/styles'
import { SvgIcon, Calculator, CallbackModal } from '@/components'
import { useCalculator } from '@/contexts/CalculatorContext'
import api from '@/lib/api'

export default function ({ blocks, calculator: initialCalculator }) {
    const { calculator, setCalculator, form, termType } = useCalculator()

    const [tab, setTab] = useState(blocks[0].id)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        setCalculator(blocks.find((item) => item.id === tab).calculator)
    }, [tab])

    return (
        <StyledSection pt="small" pb="none">
            <StyledContainer>
                <StyledCalculator.Wrapper mb>
                    <StyledCalculator.Tabs>
                        {blocks.map((item) => (
                            <StyledCalculator.Tab
                                key={item.id}
                                active={tab === item.id}
                                onClick={() => setTab(item.id)}
                            >
                                <StyledCalculator.TabIcon>
                                    {item.icon && (
                                        <img
                                            src={
                                                item.icon.icon ??
                                                `/icons/white/${item.icon.title}.svg`
                                            }
                                            alt=""
                                            width="32"
                                            height="32"
                                        />
                                    )}
                                </StyledCalculator.TabIcon>
                                <StyledCalculator.TabContent>
                                    <StyledCalculator.TabTitle>
                                        {item.title}
                                    </StyledCalculator.TabTitle>
                                    <StyledCalculator.TabText>{item.text}</StyledCalculator.TabText>
                                </StyledCalculator.TabContent>
                            </StyledCalculator.Tab>
                        ))}
                    </StyledCalculator.Tabs>
                    <Calculator
                        calculator={calculator ?? initialCalculator}
                        onBtnClick={() => setModalIsOpen(true)}
                        main
                        noLeftRadius={blocks.findIndex((item) => item.id === tab) === 0}
                        noRightRadius={
                            blocks.findIndex((item) => item.id === tab) === blocks.length - 1
                        }
                    />
                </StyledCalculator.Wrapper>
            </StyledContainer>
            <CallbackModal
                isOpen={modalIsOpen}
                onClose={setModalIsOpen}
                title="Оставьте заявку на получение денег"
                text="Укажите свои данные, чтобы мы смогли с вам связаться."
                onSubmitMethod={async (values) => {
                    await api.record({
                        ...values,
                        calculator_id: calculator.id,
                        credit_type: `Заявка на главной странице "${
                            blocks.find((item) => item.id === tab).title
                        }"`,
                        sum: form.values.sum,
                        pay_month_sum: form.values.monthSum,
                        prepaid: form.values.initialFee,
                        term_month: termType === 'month' ? form.values.term : null,
                        term_year: termType === 'year' ? form.values.term : null,
                    })
                }}
            />
        </StyledSection>
    )
}
