import { StyledCard, StyledButton } from '@/components/styles'
import { SvgIcon, RouteLink } from '@/components'

export default function ({
    title,
    text,
    img,
    icon,
    slug,
    city,
    counter,
    fixedWidth,
    onBtnClick,
    greyCard,
    blurImg,
    horizontal,
    fixedImg,
}) {
    return (
        <StyledCard
            type="help"
            fixedWidth={fixedWidth}
            greyCard={(greyCard && !onBtnClick) || slug}
            horizontal={horizontal}
            fixedImg={fixedImg}
        >
            {!onBtnClick && icon && (
                <StyledCard.Icon
                    counter={icon?.title === 'list'}
                    darkCounter={icon?.title === 'blacklist'}
                    white={greyCard}
                >
                    {icon?.title === 'list' ? (
                        `0${counter}`
                    ) : icon?.title === 'blacklist' ? (
                        <span>{counter}</span>
                    ) : (
                        icon && (
                            <img
                                src={
                                    icon.icon ??
                                    (greyCard
                                        ? `/icons/${icon.title}.svg`
                                        : `/icons/white/${icon.title}.svg`)
                                }
                                alt=""
                                width="32"
                                height="32"
                            />
                        )
                    )}
                </StyledCard.Icon>
            )}
            {img && (
                <StyledCard.Image blurImg={blurImg && 0.5}>
                    <img src={img} alt="" />
                </StyledCard.Image>
            )}
            {title && <StyledCard.Title mb={text ? 'small' : 'none'}>{title}</StyledCard.Title>}
            {text && (
                <StyledCard.Text grey={title} mb={onBtnClick && 'large'}>
                    {text}
                </StyledCard.Text>
            )}
            {slug && (
                <RouteLink slug={slug} city={city}>
                    <a />
                </RouteLink>
            )}
            {onBtnClick && (
                <StyledButton width="full" onClick={onBtnClick}>
                    Оставить заявку
                </StyledButton>
            )}
        </StyledCard>
    )
}
