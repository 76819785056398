import { useState, useRef } from 'react'
import {
    StyledButton,
    StyledForm,
    StyledOffice,
    StyledText,
    StyledModal,
} from '@/components/styles'
import { Form, Input, Modal, ReviewRadio } from '@/components'
import useForm from '../../lib/hooks/form'
import api from '../../lib/api'

const initialValues = { name: '', email: '', message: '', rating: '' }

export default function ({ employee, isOpen, onClose }) {
    const nameRef = useRef()

    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)

    async function onSubmit() {
        form.submit(async () => {
            await api.employeeReview({
                department_employee_id: employee.id,
                ...form.values,
            })
            form.setValues(initialValues)
            form.setErrors({})
            onClose(false)
            setSuccess(true)
        })
    }

    function onOpen() {
        nameRef.current && nameRef.current.focus()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Оцените работу сотрудника"
                onAfterOpen={onOpen}
            >
                <StyledOffice.ReviewTop flex>
                    <StyledOffice.ReviewImg>
                        <img src={employee.preview?.x1} alt="" />
                    </StyledOffice.ReviewImg>
                    <StyledText>{employee.name}</StyledText>
                </StyledOffice.ReviewTop>
                <Form onSubmit={onSubmit}>
                    <StyledModal.Subtitle>
                        Оцените работу сотрудника по пятибальной шкале
                    </StyledModal.Subtitle>
                    <ReviewRadio {...form.radio('rating')} />
                    <StyledModal.Subtitle>Заполните информацию о себе</StyledModal.Subtitle>
                    <StyledForm.Inner>
                        <Input
                            label="Имя"
                            placeholder="Введите имя"
                            inputRef={nameRef}
                            mb="medium"
                            {...form.input('name')}
                        />
                        <Input
                            label="E-mail"
                            placeholder="Введите E-mail"
                            type="email"
                            mb="medium"
                            {...form.input('email')}
                        />
                    </StyledForm.Inner>
                    <Input label="Комментарий" as="textarea" {...form.input('message')} />
                    <StyledButton width="full" disabled={form.isLoading} isLoading={form.isLoading}>
                        Отправить
                    </StyledButton>
                </Form>
            </Modal>
            <Modal
                small
                isOpen={success}
                onClose={setSuccess}
                title="Спасибо, ваш отзыв успешно обработан"
                text="Ваша оценка очень важна для нас"
            />
        </>
    )
}
