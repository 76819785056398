import {
    StyledSection,
    StyledContainer,
    StyledNews,
    StyledTitle,
    StyledText,
} from '@/components/styles'
import { Breadcrumbs } from '@/components'
import numberToString from '@/lib/numberToString'
import moment from 'moment'

export default function ({ title, views, date, img, content }) {
    return (
        <StyledSection pt="x-small">
            <StyledContainer sizeS>
                <Breadcrumbs
                    breadcrumbs={[
                        { title: 'Пресс-центр', link: '/press-center' },
                        { title: title },
                    ]}
                />
                <StyledTitle large mobileMedium mb="small" as="h1">
                    {title}
                </StyledTitle>
                <StyledNews.Info mb>
                    <StyledNews.Views>
                        {views} {numberToString(views, ['просмотр', 'просмотра', 'просмотров'])}
                    </StyledNews.Views>
                    {moment(date).format('DD MMMM YYYY')}
                </StyledNews.Info>
                <StyledNews.ArticleImg>
                    <img src={img.x2} alt="" />
                </StyledNews.ArticleImg>
                <StyledText>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </StyledText>
            </StyledContainer>
        </StyledSection>
    )
}
