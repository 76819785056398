import styled from 'styled-components'
import vars from '@/assets/styles/vars'

const Breadcrumbs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
`

Breadcrumbs.Item = styled.div`
    position: relative;
    &:not(:last-child) {
        margin-right: 12px;
        padding-right: 12px;
        &:after {
            content: '';
            position: absolute;
            top: 4px;
            right: 0;
            width: 1px;
            height: calc(100% - 8px);
            background: ${vars.theme.grey};
        }
    }
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
    &:last-child {
        color: ${vars.theme.red};
    }
`

export default Breadcrumbs
