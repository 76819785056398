import { StyledInput } from '@/components/styles'
import { InputWrapper } from '@/components'

export default function ({
    label,
    error,
    value,
    placeholder,
    onChange,
    onClick,
    mb,
    inputRef,
    ...props
}) {
    return (
        <InputWrapper label={label} error={error} mb={mb} withError>
            <StyledInput.Mask
                placeholder={placeholder}
                value={value}
                type="tel"
                mask="+375 99 999 99 99"
                maskChar=""
                onChange={(e) => onChange(e.target.value)}
                onClick={onClick}
                error={error}
                small={!label}
                {...props}
            >
                {(inputProps) => <input ref={inputRef} {...inputProps} />}
            </StyledInput.Mask>
        </InputWrapper>
    )
}
