import styled, {css} from 'styled-components'
import {maxWidth, minWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {StyledText} from '@/components/styles'

const Card = styled.div`
    position: relative;
    ${p => p.fixedWidth && "width: 333px"};
    height: 100%;
    padding: 24px;
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 8px;
    background: ${p => p.grey ? vars.theme.lightGrey : "#fff"};
    transition: .3s;
    
    ${minWidth(vars.breakpoints.mTablet)`
        ${p => (p.type === 'product' || p.type === 'help' || p.type === 'avto' || p.type === 'service') && css`
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: ${p => (p.type === 'help' || p.type === 'avto') ? "2px" : "4px"};
                background: ${p => (p.type === 'help' || p.type === 'avto') ? vars.theme.darkGrey : vars.theme.red};
                border-radius: 8px 8px 0 0;
                transform: scale(0);
                transform-origin: 50% 0;
                transition: transform 0.2s linear 0s;
            }
            &:hover {
                &:before {
                    transform: none;
                }
            }
        `}
    `}
    
    ${p => p.center && css`
        text-align: center;
    `}
    
    ${p => p.fixedImg && css`
        ${Card.Image} {
            height: 0;
            padding-bottom: 63%;
            img {
                position: absolute;
                top: 0;
            }
        }
    `}
    
    &:hover {
        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    }
    
    ${({type}) => {
        switch (type) {
            case 'product':
                return css`
                    padding: 0;
                    max-width: 610px;
                    ${minWidth(vars.breakpoints.mTablet)`
                        &:hover {
                            background: #fff;
                            div:first-child {
                                &:before {
                                    opacity: 0;
                                    transition: all .3s;
                                }
                            }
                        }
                    `}
                  `
            case 'partner':
                return css`
                    padding: 0;
                    border: none;
                    &:hover {
                        background: #fff;
                        div:first-child {
                            &:before {
                                opacity: 0;
                                transition: all .3s;
                            }
                        }
                    }
                  `
            case 'service':
                return css`
                    display: flex;
                    flex-direction: column;
                    padding: 32px;
                    ${maxWidth(vars.breakpoints.tablet)`
                        padding: 24px;
                    `}
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding: 16px;
                    `}
                  `
            case 'help':
                return css`
                    border: none;
                    ${p => p.greyCard ? css`
                        background: rgba(240, 244, 250, 0.75);
                    ` : css`
                        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
                    `}
                    ${p => p.horizontal && css`
                        display: flex;
                        align-items: center;
                        ${Card.Icon} {
                            margin: 0 24px 0 0;
                        }
                    `}
                    &:hover {
                        ${Card.Image} img {
                            opacity: 1;
                        }
                    }
                    ${maxWidth(vars.breakpoints.tablet)`
                        ${p => p.fixedWidth && "width: 280px"};
                        ${p => p.horizontal && css`
                            ${Card.Icon} {
                                margin: 0 16px 0 0;
                            }
                        `}
                    `}
                  `
            case 'avto':
                return css`
                    border: none;
                    background: rgba(240, 244, 250, 0.75);
                    ${Card.Image} {
                        height: 0;
                        padding-bottom: 63%;
                        img {
                            position: absolute;
                            top: 0;
                        }
                    }
                    ${maxWidth(vars.breakpoints.tablet)`
                        ${p => p.fixedWidth && "width: 280px"};
                    `}
                  `
            case 'history':
                return css`
                    padding: 0 32px 0 0;
                    border: none;
                    background: transparent;
                    &:hover {
                        box-shadow: none;
                        
                        ${Card.Image} img {
                            opacity: 1;
                        }
                    }
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding-right: 16px;
                    `}
                  `
            case 'media':
                return css`
                    padding: 36px 24px;
                    img {
                        display: inline;
                        max-height: 80px;
                        filter: grayscale(100%) brightness(130%);
                        transition: .3s;
                    }
                    &:hover {
                        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
                        border-color: transparent;
                        img {
                            filter: none;
                        }
                    }
                    ${maxWidth(vars.breakpoints.tablet)`
                        img {
                            filter: none;
                        }
                    `}
                    ${maxWidth(vars.breakpoints.mTablet)`
                        padding: 24px 16px;
                    `}
                  `
            default:
                return css`
                    ${maxWidth(vars.breakpoints.tablet)`
                        padding: 24px;
                    `}
                    ${maxWidth(vars.breakpoints.tablet)`
                        padding: 16px;
                    `}
                `
        }
    }}
    
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.type !== 'service' && css`
            width: 280px;
            max-width: 80vw;
        `}
    `}
    
    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`

Card.Inner = styled.div`
    padding: 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
    `}
`

Card.Avto = styled.div`
    display: grid;
    grid-template-areas: 'img img' 'title icon' 'text icon';
    grid-template-columns: 1fr 56px;
    grid-template-rows: max-content;
    gap: 0 16px;
`

Card.Title = styled.div`
    grid-area: title;
    margin-bottom: ${p => p.mb === 'small' ? "8px" : p.mb === "none" ? 0 : "16px"};
    font-size: ${p => p.large ? "24px" : "20px"};
    line-height: ${p => p.large ? "32px" : "28px"};
    letter-spacing: 0.2px;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: ${p => p.mb === "none" ? 0 : "8px"};
        font-size: 16px;
        line-height: 24px; 
    `}
`

Card.Text = styled(StyledText)`
    grid-area: text;
    ${p => p.mb && `margin-bottom: ${p.mb === 'large' ? "28px" : "16px"};`}
`

Card.Separator = styled.div`
    margin: 0 auto 32px;
    width: 61px;
    height: 2px;
    background: ${vars.theme.outlineGrey};
`

Card.OfferImage = styled.div`
    position: relative;
    background-image: radial-gradient(#fff 10%, rgba(255,255,255,0) 20%);
    background-size: 20px 20px;
    border-radius: 8px 8px 0 0;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(100% 150% at 35% 0%, rgba(255,255,255,0) 0%, ${vars.theme.lightGrey} 100%);
        transition: .3s;
    }
    img {
        position: relative;
        display: block;
        max-height: 100%;
        margin: 0 auto;
    }
    
    ${p => p.fullImg && css`
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: initial;
            min-height: 100%;
            max-width: inherit;
            min-width: 100%;
        }
    `}
`

Card.Image = styled.div`
    position: relative;
    grid-area: img;
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden; 
    img {
        display: block;
        margin: 0 auto;
        ${p => p.blurImg && css`
            opacity: ${p.blurImg};
        `}
        transition: all .3s;
    }
`

Card.Icon = styled.div`
    grid-area: icon;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    ${p => !p.mbReset && "margin-bottom: 24px;"}
    ${p => p.white ? css`
        background: #fff;
        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 22px rgba(61, 75, 86, 0.08);
    ` : css`
        background: ${vars.theme.lightGrey};
    `}
    border-radius: 12px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: ${p => p.darkCounter ? "#fff" : "#68869F"};
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: linear-gradient(102.88deg, #89A6BE 15.26%, #68869F 68.03%);
        border-radius: 50%;
    }
    svg {
        fill: ${p => p.white ? vars.theme.outlineGrey : "#fff"};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
    `}
`

Card.Year = styled.div`
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #fff;
`

Card.BankIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-bottom: 24px;
    img {
        max-height: 80px;
    }
`

Card.Conditions = styled.div`
    flex: 1;
    ${p => p.mb && "margin-bottom: 24px;"}
`

Card.Condition = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 26px;
    &:not(:last-child) {
        margin-bottom: 14px;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        background: ${p => p.greyCircle ? vars.theme.lightGrey : "#FFFFFF"};
        box-shadow: 0px 1px 6px rgba(61, 75, 86, 0.08);
        border-radius: 50%;
    }
    &:after {
        content: '';
        position: absolute;
        left: 5px;
        top: calc(50% - 4px);
        display: block;
        width: 8px;
        height: 6px;
        margin-bottom: 2px;
        border-left: 2px solid #68869F;
        border-bottom: 2px solid #68869F;
        transform: rotate(-45deg);
    }
    
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 14px;
        line-height: 20px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    `}
`

export default Card
