import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Objects = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: 1fr;
    `}
`

Objects.Item = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    ${p => p.grey ? css`
        background: ${vars.theme.lightGrey};
        background-image: linear-gradient(70deg, ${vars.theme.lightGrey} 50%, rgba(255,255,255,0) 100%), radial-gradient(#fff 10%, rgba(255,255,255,0) 20%);
        background-size: cover, 25px 25px;
        background-repeat: no-repeat, repeat;
    ` : css`
        background: #FFFFFF;
        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    `}
    
`

Objects.ItemInner = styled.div`
    max-width: 328px;
    padding: 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        max-width: inherit;
        padding: 16px;
    `}
`

Objects.ItemTitle = styled.div`
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 16px;
        line-height: 24px;
    `}
`

Objects.ItemImg = styled.div`
    img {
        height: 200px;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        display: none;
    `}
`

Objects.ItemLink = styled.div`
    margin-top: 24px;
    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        position: relative;
        margin-top: 8px;
    `}
`

Objects.ItemLinkText = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: ${vars.theme.red};
    &:after {
        content: '';
        display: block;
        width: 8.5px;
        height: 8.5px;
        margin-left: 12px;
        border-right: 1px solid ${vars.theme.red};
        border-bottom: 1px solid ${vars.theme.red};
        transform: rotate(-45deg);
        transition: .3s;
    }
`

export default Objects
