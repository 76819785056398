import { useState, useRef, useEffect } from 'react'
import { StyledModal } from '@/components/styles'
import { Form, Input, Modal, ConfirmationModal } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'

const initialValues = { code: '' }

export default function ({ isOpen, onClose, phone, name, repeatCode, codeError, goToPrevStep }) {
    const inputRef = useRef()

    const form = useForm(initialValues)

    const [confirmationIsOpen, setConfirmationIsOpen] = useState(false)

    function onSubmit() {
        form.submit(
            async () => {
                await api.auth.register({
                    verification_code: form.values.code,
                    phone: phone,
                    name: name,
                })
                form.setValues(initialValues)
                form.setErrors({})
                onClose(false)
                setConfirmationIsOpen(true)
            },
            (e) => {
                if (e.status == 'Такой пользователь уже существует') {
                    goToPrevStep({ phone: e.status })
                } else {
                    form.setErrors(e.errors)
                }
            },
        )
    }

    function onOpen() {
        inputRef.current && inputRef.current.focus()
    }

    useEffect(() => {
        form.values.code.length === 4 && onSubmit()
    }, [form.values.code])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Введите код из СМС"
                text={
                    <>
                        Мы отправили СМС-сообщение с кодом подтверждения на номер{' '}
                        <strong>{phone}</strong>.
                    </>
                }
                onAfterOpen={onOpen}
                medium
            >
                <Form onSubmit={onSubmit}>
                    <Input
                        label="Код"
                        placeholder="••••"
                        mb="large"
                        maxLength="4"
                        white
                        inputRef={inputRef}
                        {...form.input('code')}
                    />
                </Form>
                <StyledModal.Action as="button" onClick={repeatCode}>
                    Отправить еще раз
                </StyledModal.Action>
                {codeError.visible && <StyledModal.Error>{codeError.text}</StyledModal.Error>}
            </Modal>
            <ConfirmationModal isOpen={confirmationIsOpen} onClose={setConfirmationIsOpen} />
        </>
    )
}
