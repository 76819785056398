import { StyledSection, StyledContainer, StyledTitle, SrtledText } from '@/components/styles'
import styled, { css } from 'styled-components'
import { StyledText } from '@/components/styles'

export default function ({ title, content }) {
    return (
        <StyledSection pt="medium">
            <StyledContainer>
                <StyledTitle large>{title}</StyledTitle>
                <StyledText>
                    <Content dangerouslySetInnerHTML={{ __html: content }} />
                </StyledText>
            </StyledContainer>
        </StyledSection>
    )
}

const Content = styled.div`
    & > p,
    & > ol > li {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
    li p {
        margin-bottom: 8px;
        font-weight: 500;
    }
`
