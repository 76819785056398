import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Form = styled.form`
  
`

Form.Inner = styled.div`
    display: grid;
    grid-template-columns: repeat(${p => p.columns ? p.columns : 2}, 1fr);
    gap: 20px;
    ${p => p.mb && "margin: 0 0 16px;"}
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
        ${p => p.mb && "margin: 0 0 5px;"}
    `}
`

Form.Separator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    margin-bottom: 24px;
    background: ${vars.theme.ashenGrey};
    ${p => p.or && css`
        &:before {
            content: "или";
            padding: 0 10px 3px;
            background: ${vars.theme.whiteGrey};
            font-size: 16px;
            color: ${vars.theme.darkGrey};
        }
    `}
`

Form.AddButton = styled.div`
    display: flex;
    align-items: center;
    max-width: max-content;
    margin-bottom: 24px;
    font-weight: 500;
    cursor: pointer;
    svg {
        margin-right: 16px;
    }
`

Form.Info = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: ${vars.theme.darkBlue};
    svg {
        margin-right: 12px;
    }
`

Form.Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    ${maxWidth(vars.breakpoints.mTablet)`
        flex-direction: column-reverse;
        align-items: flex-start;
    `}
`

export default Form
