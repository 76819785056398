import { StyledRadio } from '@/components/styles'

const options = [1, 2, 3, 4, 5]

export default function ({ mb, value, onChange }) {
    return (
        <StyledRadio mb={mb}>
            <StyledRadio.Labels type="review">
                {options.map((item) => (
                    <StyledRadio.Label checked={value == item} key={item} type="review">
                        <input
                            type="radio"
                            onChange={() => onChange(item)}
                            checked={value == item}
                        />
                        {item}
                    </StyledRadio.Label>
                ))}
            </StyledRadio.Labels>
        </StyledRadio>
    )
}
