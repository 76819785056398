import React, { useContext, useState, useEffect, useRef } from 'react'
import Cookies from '@/lib/cookies'
import { useRouter } from 'next/router'

const CityContext = React.createContext()

export function CityProvider({ children, city: initialCity, needSetCookie, areas, cities }) {
    const router = useRouter()
    const isFirstLoad = useRef(true)

    const [city, setCity] = useState(initialCity)

    const setCityCookie = (city) => {
        setCity(city)
        Cookies.set('atl_city', JSON.stringify(city), {
            expires: new Date(Date.now() + 3600 * 1000 * 24 * 365),
        })
    }

    useEffect(() => {
        needSetCookie && setCityCookie(initialCity)
    }, [])

    useEffect(() => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false
            return
        }

        if (router.pathname.includes('slug') || router.pathname === '/') {
            router.push(
                { pathname: city.is_default ? '/' : '/[slug]' },
                { pathname: city.is_default ? '/' : `/${city.slug}` },
                { shallow: false },
            )
        }
    }, [city.slug])

    return (
        <CityContext.Provider value={{ city, setCityCookie, areas, cities }}>
            {children}
        </CityContext.Provider>
    )
}

export function useCity() {
    return useContext(CityContext)
}
