import { useMemo, useRef } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock, HelpCard, AvtoCard, BankCard } from '@/components'
import Slider from 'react-slick'

export default function ({ pt, pb, title, blocks = [], onBtnClick, count = 4 }) {
    const sliderRef = useRef()

    const settings = useMemo(
        () => ({
            slidesToShow: blocks.length < count ? blocks.length : +count,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            lazyLoad: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: blocks.length < 3 ? blocks.length : 3,
                        dots: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: blocks.length < 2 ? blocks.length : 2,
                        variableWidth: true,
                        dots: true,
                    },
                },
            ],
        }),
        [],
    )

    return (
        <StyledSection pb={pb || 'small'} pt={pt || 'small'}>
            <StyledContainer>
                <SliderBlock
                    title={title}
                    length={blocks.length}
                    count={count}
                    sliderRef={sliderRef}
                >
                    <Slider {...settings} ref={sliderRef}>
                        {blocks.map((item, idx) =>
                            item.type === 'type_block_image_title_description_mini_image' ? (
                                <AvtoCard
                                    key={item.id}
                                    img={item.image.banner_237x150}
                                    icon={item.image_mini.smallest}
                                    title={item.title}
                                    text={item.description}
                                    slug={item?.page?.slug}
                                    city={item?.page?.city}
                                />
                            ) : item.type === 'type_block_image_line_title_description' ? (
                                <BankCard
                                    key={item.id}
                                    name={item.title}
                                    text={item.description}
                                    img={item.image?.smallest}
                                />
                            ) : (
                                <HelpCard
                                    key={item.id}
                                    counter={idx + 1}
                                    icon={item.icon}
                                    img={item?.image?.banner_237x150}
                                    title={item.title}
                                    text={item.description}
                                    onBtnClick={
                                        item.type === 'type_button_title_description' && onBtnClick
                                    }
                                    greyCard={item.is_background}
                                    slug={item?.page?.slug}
                                    city={item?.page?.city}
                                    horizontal={item.position === 'horizontal'}
                                    fixedImg={item.type === 'type_image_title_description'}
                                />
                            ),
                        )}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
        </StyledSection>
    )
}
