import React, { useState, useEffect, useContext } from 'react'
import cookies from '../lib/cookies'
import Router from 'next/router'

const AuthContext = React.createContext()

export function AuthProvider({ user: initialUser, children }) {
    const [user, setUser] = useState(initialUser)

    async function logOut() {
        await Router.push('/')
        cookies.deleteCookie('atl_token')
        setUser(null)
    }

    useEffect(() => {
        initialUser && setUser(initialUser)
    }, [initialUser])

    return <AuthContext.Provider value={{ user, setUser, logOut }}>{children}</AuthContext.Provider>
}

export function useAuth() {
    return useContext(AuthContext)
}
