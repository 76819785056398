import { useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {
    StyledSection,
    StyledContainer,
    StyledTitle,
    StyledButton,
    StyledAppeal,
    StyledForm,
} from '@/components/styles'
import { Form, Input, Radio, FileInput, SvgIcon } from '@/components'
import { Files } from '@/components/form/FileInput'
import useForm from '@/lib/hooks/form'
import Link from 'next/link'
import moment from 'moment'
import { statuses } from '@/lib/config'
import api from '@/lib/api'
import { useSocket } from '@/contexts/SocketContext'
import { useAuth } from '../../contexts/AuthContext'

const initialValues = { text: '', files: '' }

export default function ({ dialog: initialDialog, messages: initialMessages }) {
    const { data, setData } = useSocket()
    const messagesRef = useRef()
    const form = useForm(initialValues)

    const [dialog, setDialog] = useState(initialDialog)
    const [messages, setMessages] = useState(initialMessages)

    const status = statuses.find((status) => status.key === dialog.status)

    const onSubmit = () => {
        if (!form.errors?.files?.filter((item) => item).length) {
            form.submit(
                async () => {
                    await api.dialogs.send({
                        dialog_id: dialog.dialog_id,
                        ...form.values,
                    })
                    form.setValues(initialValues)
                },
                (e) => {
                    e.errors &&
                        form.setErrors({
                            ...e.errors,
                            files: form.values.files.map(
                                (item, idx) => e.errors[`files.${idx}`] ?? '',
                            ),
                        })
                },
            )
        }
    }

    const onNo = async () => {
        await api.dialogs.resolve({
            dialog_id: dialog.dialog_id,
        })
        setDialog({ ...dialog, status: 'resolved' })
    }

    const onYes = async (messageId) => {
        await api.dialogs.questions({
            message_id: messageId,
        })
        setMessages(messages.filter((message) => message.id != messageId))
    }

    function scrollToBottom() {
        const el = messagesRef.current
        el.scrollTop = el.scrollHeight - el.clientHeight
    }

    useEffect(() => {
        messagesRef && scrollToBottom()
    }, [])

    useEffect(() => {
        if (data && data.dialog) {
            const { message, dialog: newDialog } = data

            if (message.dialog_id == dialog.dialog_id) {
                setDialog({ ...dialog, status: newDialog.status })
                setMessages((messages) => [...messages, { ...message, id: message.message_id }])
                scrollToBottom()
            }
            setData(null)
        }
    }, [data])

    return (
        <StyledSection
            pt="small"
            pb={dialog.status != 'resolved' ? 'x-small' : 'none'}
            style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
            <StyledContainer style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <StyledAppeal fullHeight={dialog.status == 'resolved'}>
                    <StyledAppeal.Top border>
                        <StyledAppeal.Back>
                            <Link href="/appeal">
                                <a>
                                    <SvgIcon name="back" width={32} height={32} />
                                </a>
                            </Link>
                        </StyledAppeal.Back>
                        <StyledAppeal.Desc>
                            <StyledTitle mb="none" small>
                                {dialog.title}
                            </StyledTitle>
                            <StyledAppeal.Number>
                                №{dialog.dialog_id} создан{' '}
                                {moment(dialog.created_at).format('DD.MM.YYYY')}
                            </StyledAppeal.Number>
                            <StyledAppeal.Status color={status?.color}>
                                {status?.title}
                            </StyledAppeal.Status>
                        </StyledAppeal.Desc>
                    </StyledAppeal.Top>
                    <StyledAppeal.ListWrapper>
                        <StyledAppeal.List ref={messagesRef}>
                            <StyledAppeal.ListInner>
                                {messages.map(
                                    (item) =>
                                        (item.text ||
                                            (item.any_questions &&
                                                dialog.status != 'resolved')) && (
                                            <Message
                                                key={item.id}
                                                {...item}
                                                onNo={onNo}
                                                onYes={onYes}
                                            />
                                        ),
                                )}
                            </StyledAppeal.ListInner>
                        </StyledAppeal.List>
                    </StyledAppeal.ListWrapper>
                    {dialog.status != 'resolved' && (
                        <Form onSubmit={onSubmit}>
                            <StyledAppeal.Form>
                                <Input
                                    placeholder="Введите сообщение..."
                                    white
                                    mb="none"
                                    default
                                    as="textarea"
                                    {...form.input('text')}
                                />
                                <StyledAppeal.FormFiles>
                                    <Files {...form.input('files')} />
                                </StyledAppeal.FormFiles>
                                <StyledAppeal.FormBottom>
                                    <StyledButton
                                        onClick={onSubmit}
                                        disabled={
                                            form.isLoading ||
                                            !form.values.text ||
                                            form.errors?.files?.filter((item) => item).length
                                        }
                                        isLoading={form.isLoading}
                                    >
                                        Отправить
                                    </StyledButton>
                                    <FileInput mb="none" {...form.input('files')} />
                                </StyledAppeal.FormBottom>
                            </StyledAppeal.Form>
                        </Form>
                    )}
                </StyledAppeal>
            </StyledContainer>
        </StyledSection>
    )
}

const Message = ({ id, name, sender, text, media, created_at, any_questions, onYes, onNo }) => {
    return (
        <StyledMessage>
            <StyledMessage.Image back={sender != 'admin'}>
                {sender == 'admin' ? (
                    <img src="/images/logo.svg" alt="" />
                ) : (
                    <SvgIcon name="user-white" width={24} height={24} />
                )}
            </StyledMessage.Image>
            <StyledMessage.Inner>
                <StyledMessage.Top>
                    <StyledMessage.Name>{name}</StyledMessage.Name>
                    <StyledMessage.Date>{moment(created_at).format('DD MMM')}</StyledMessage.Date>
                </StyledMessage.Top>
                <StyledMessage.Text>
                    {any_questions ? 'Остались ли у вас какие-то вопросы?' : text}
                </StyledMessage.Text>
                {any_questions && (
                    <StyledMessage.Buttons>
                        <StyledButton onClick={() => onYes(id)} width="full">
                            Да
                        </StyledButton>
                        <StyledButton onClick={onNo} width="full">
                            Нет
                        </StyledButton>
                    </StyledMessage.Buttons>
                )}
                {(media.files.length > 0 || media.images.length > 0) && (
                    <StyledMessage.Files>
                        {media.files.map((item, idx) => (
                            <StyledMessage.File href={item} target="_blank" key={idx}>
                                <SvgIcon name="attach" width="16" height="16" />
                                <p>
                                    {decodeURI(
                                        item.slice(item.lastIndexOf('/') + 1, item.indexOf('?')),
                                    )}
                                </p>
                            </StyledMessage.File>
                        ))}
                        {media.images.map((image, idx) => (
                            <StyledMessage.File href={image} target="_blank" key={idx}>
                                <SvgIcon name="attach" width="16" height="16" />
                                <p>
                                    {decodeURI(
                                        image.slice(image.lastIndexOf('/') + 1, image.indexOf('?')),
                                    )}
                                </p>
                            </StyledMessage.File>
                        ))}
                    </StyledMessage.Files>
                )}
            </StyledMessage.Inner>
        </StyledMessage>
    )
}

const StyledMessage = styled.div`
    display: flex;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

StyledMessage.Image = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    ${(p) => p.back && 'background: #b5bfcd;'}
    border-radius: 50%;
    color: #fff;
`

StyledMessage.Inner = styled.div`
    flex: 1;
    font-size: 14px;
    line-height: 20px;
`

StyledMessage.Date = styled.div`
    color: #5c6a79;
`

StyledMessage.Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
`

StyledMessage.Name = styled.div`
    font-weight: 500;
`

StyledMessage.Text = styled.div``

StyledMessage.Files = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`

StyledMessage.File = styled.a`
    display: flex;
    align-items: center;
    width: 280px;
    &:not(:last-child) {
        margin-bottom: 4px;
    }
    padding: 6px 8px;
    background: ${vars.theme.lightGrey};
    svg {
        margin-right: 8px;
    }
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${maxWidth(vars.breakpoints.mobile)`
        width: 200px;
    `}
`

StyledMessage.Buttons = styled.div`
    display: grid;
    grid-template-columns: 100px 100px;
    gap: 16px;
    margin-top: 16px;
    button {
        padding: 6px;
    }
`
