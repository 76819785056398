import { useEffect, useRef } from 'react'
import {
    MainSlider,
    Categories,
    ProductsBlock,
    EBlocks,
    ArticlesBlock,
    UsefulBlock,
    Test,
    ReviewsBlock,
} from '@/components'
import ReactDOM from 'react-dom'
import { useInfo } from '@/contexts/InfoContext'
import getWindowWidth from '@/lib/getWindowWidth'

export default function ({ data, articles }) {
    const mainRef = useRef()
    const { setTestIsVisible, testIsVisible } = useInfo()
    const windowWidth = getWindowWidth()

    const onScroll = () => {
        console.log(windowWidth)
        if(!testIsVisible || windowWidth > 550) {
            mainRef.current &&
                setTestIsVisible(
                    ReactDOM.findDOMNode(mainRef.current).getBoundingClientRect().bottom < 200
                )
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [testIsVisible])

    return (
        <>
            <MainSlider blockRef={mainRef} blocks={data.sliders} />
            <Categories />
            {data.relation_pages?.length > 0 && (
                <ProductsBlock title="Популярные предложения" products={data.relation_pages} />
            )}
            <EBlocks blocks={data.blocks} advPB="small" title="Главная страница" />
            <ReviewsBlock reviews={data.reviews} />
            <ArticlesBlock articles={articles} />
            {data.useful_for_you?.length > 0 && <UsefulBlock blocks={data.useful_for_you} />}
            <Test />
        </>
    )
}
