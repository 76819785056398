import { StyledCard } from '@/components/styles'
import { RouteLink } from '@/components'

export default function ({ title, img, conditions = [], type = 'product', fullImg, slug, city }) {
    return (
        <StyledCard type={type} grey>
            <StyledCard.OfferImage fullImg={fullImg}>
                <img src={img} alt="" />
            </StyledCard.OfferImage>
            <StyledCard.Inner>
                <StyledCard.Title large>{title}</StyledCard.Title>
                <StyledCard.Conditions>
                    {conditions.map((item, idx) => (
                        <StyledCard.Condition key={item?.id ?? idx}>
                            {item.title}
                        </StyledCard.Condition>
                    ))}
                </StyledCard.Conditions>
            </StyledCard.Inner>
            {slug && (
                <RouteLink slug={slug} city={city}>
                    <a />
                </RouteLink>
            )}
        </StyledCard>
    )
}
