import { useState } from 'react'
import { StyledModal, StyledButton, StyledOffice } from '@/components/styles'
import { Modal, EmployeeReviewModal } from '@/components'

export default function ({
    office,
    employees,
    isOpen,
    onClose,
    onMessageModalOpen,
    onOpenReviewModal,
}) {
    const [listIsOpen, setListIsOpen] = useState(false)
    const [{ employeeModalIsOpen, employee }, setEmployeeModalState] = useState({
        employeeModalIsOpen: false,
        employee: {},
    })

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} title={office.title}>
                <StyledModal.Block>
                    <StyledModal.Title>Подробная информация</StyledModal.Title>
                    <StyledModal.Table>
                        <StyledModal.TableRow>
                            <StyledModal.InfoTitle>Телефон</StyledModal.InfoTitle>
                            <StyledModal.InfoText>
                                {office.phone.split(', ').map((phone, idx) => (
                                    <div key={idx}>
                                        <a href={`tel:${phone}`}>{phone}</a>
                                    </div>
                                ))}
                            </StyledModal.InfoText>
                        </StyledModal.TableRow>
                        <StyledModal.TableRow>
                            <StyledModal.InfoTitle>Адрес</StyledModal.InfoTitle>
                            <StyledModal.InfoText>{office.address}</StyledModal.InfoText>
                        </StyledModal.TableRow>
                        <StyledModal.TableRow>
                            <StyledModal.InfoTitle>График работы</StyledModal.InfoTitle>
                            <StyledModal.InfoText
                                dangerouslySetInnerHTML={{ __html: office.schedule }}
                            />
                        </StyledModal.TableRow>
                    </StyledModal.Table>
                </StyledModal.Block>
                {employees?.length > 0 && (
                    <StyledModal.Block>
                        <StyledModal.BlockTop>
                            {listIsOpen ? (
                                <StyledModal.Title mbNone>Сотрудники</StyledModal.Title>
                            ) : (
                                <StyledOffice.EmployeePhotos>
                                    {(employees.length > 3 ? employees.slice(2) : employees).map(
                                        (item) => (
                                            <StyledOffice.EmployeePhotosItem key={item.id}>
                                                <img src={item.preview.x1} alt="" />
                                            </StyledOffice.EmployeePhotosItem>
                                        ),
                                    )}
                                    {employees.length > 3 && (
                                        <StyledOffice.EmployeePhotosItem empty>
                                            +{employees.length - 3}
                                        </StyledOffice.EmployeePhotosItem>
                                    )}
                                </StyledOffice.EmployeePhotos>
                            )}
                            <StyledOffice.EmployeeToggle
                                isOpen={listIsOpen}
                                onClick={() => setListIsOpen(!listIsOpen)}
                            >
                                {listIsOpen ? 'Скрыть' : 'Сотрудники'}
                            </StyledOffice.EmployeeToggle>
                        </StyledModal.BlockTop>
                        {listIsOpen && (
                            <StyledModal.Table>
                                {employees.map((item) => (
                                    <StyledOffice.Employee key={item.id}>
                                        <StyledOffice.EmployeeImg>
                                            <img src={item.preview.x1} alt="" />
                                        </StyledOffice.EmployeeImg>
                                        <StyledOffice.EmployeeName>
                                            {item.name}
                                        </StyledOffice.EmployeeName>
                                        <StyledOffice.EmployeeRating>
                                            Рейтинг {item.rating}/5.0
                                        </StyledOffice.EmployeeRating>
                                        <StyledOffice.EmployeeButton>
                                            <StyledButton
                                                width="full"
                                                bg="grey"
                                                onClick={() => {
                                                    onClose(false)
                                                    setEmployeeModalState({
                                                        employeeModalIsOpen: true,
                                                        employee: item,
                                                    })
                                                }}
                                            >
                                                Оценить работу
                                            </StyledButton>
                                        </StyledOffice.EmployeeButton>
                                    </StyledOffice.Employee>
                                ))}
                            </StyledModal.Table>
                        )}
                    </StyledModal.Block>
                )}
                <StyledModal.Block>
                    <StyledModal.ButtonWrap>
                        <StyledButton width="full" bg="grey" onClick={onMessageModalOpen}>
                            Написать директору
                        </StyledButton>
                        <StyledButton width="full" onClick={onOpenReviewModal}>
                            Оставить отзыв
                        </StyledButton>
                    </StyledModal.ButtonWrap>
                </StyledModal.Block>
            </Modal>
            <EmployeeReviewModal
                employee={employee}
                isOpen={employeeModalIsOpen}
                onClose={() => setEmployeeModalState({ employeeModalIsOpen: false, employee: {} })}
            />
        </>
    )
}
