import Head from 'next/head'

export default function CustomHead({ meta }) {
    return (
        <Head>
            <title key="page">{meta?.title ?? 'Центр финансовых консультаций: помощь в подборе кредита и лизинга'}</title>

            <meta
                property="og:title"
                content={meta?.title ?? 'Центр финансовых консультаций: помощь в подборе кредита и лизинга'}
                key="title"
            />
            <meta
                property="og:description"
                content={meta?.description ?? 'Центр финансовых консультаций: поможем подобрать выгодный кредит в Беларуси. Товары в лизинг. Даже с плохой кредитной историей. Звоните: +375(29)888-3-22'}
                key="ogDescription"
            />
            <meta
                name="description"
                content={meta?.description ?? 'Центр финансовых консультаций: поможем подобрать выгодный кредит в Беларуси. Товары в лизинг. Даже с плохой кредитной историей. Звоните: +375(29)888-3-22'}
                key="description"
            />
            {meta?.is_robots_disallows && <meta name="robots" content="noindex, nofollow" />}
        </Head>
    )
}
