import { useState, useRef, useEffect } from 'react'
import { StyledButton, StyledForm, StyledText, StyledModal } from '@/components/styles'
import { Form, PhoneInput, Modal, PasswordModal, HelpModal, RegistrationModal } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'

const initialValues = { phone: '', verification_code: '' }

export default function ({ isOpen, onClose }) {
    const inputRef = useRef()

    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)
    const [registrationIsOpen, setRegistrationIsOpen] = useState(false)
    const [helpIsOpen, setHelpIsOpen] = useState(false)
    const [codeError, setCodeError] = useState({ visible: false, text: '' })

    useEffect(() => {
        if (codeError.text) {
            const timeout = setTimeout(() => setCodeError({ visible: false, text: '' }), 60 * 1000)
            // clearTimeout(timeout)
        }
    }, [codeError.text])

    function onSubmit() {
        if (!codeError.text) {
            form.submit(
                async () => {
                    try {
                        await api.auth.login({
                            phone: form.values.phone,
                        })
                    } catch (e) {
                        if (e.errors.phone) {
                            form.setErrors({ phone: e.errors.phone })
                        } else {
                            await api.auth.sms(form.values)
                            form.setErrors({})
                            onClose(false)
                            setSuccess(true)
                        }
                    }
                },
                (e) => {
                    if (e.errors?.phone == 'Смс код можно запрашивать раз в минуту.') {
                        onClose(false)
                        setSuccess(true)
                        setCodeError({
                            visible: success,
                            text: e.errors.phone,
                        })
                    } else {
                        form.setErrors(e.errors)
                    }
                },
            )
        } else if (codeError.text && !success) {
            onClose(false)
            setSuccess(true)
        } else {
            setCodeError({ visible: success, text: 'Смс код можно запрашивать раз в минуту.' })
        }
    }

    function onOpen() {
        inputRef.current && inputRef.current.focus()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Войдите в личный кабинет"
                onAfterOpen={onOpen}
                medium
                bottomComponent={
                    <>
                        <StyledText grey style={{ marginRight: '4px' }}>
                            Нет аккаунта?
                        </StyledText>
                        <StyledModal.Action
                            onClick={() => {
                                onClose(false)
                                setRegistrationIsOpen(true)
                            }}
                        >
                            Регистрация
                        </StyledModal.Action>
                    </>
                }
            >
                <Form onSubmit={onSubmit}>
                    <PhoneInput
                        label="Телефон"
                        placeholder="Введите телефон"
                        mb="large"
                        white
                        withError
                        inputRef={inputRef}
                        {...form.input('phone')}
                    />
                    <StyledButton
                        width="full"
                        disabled={form.isLoading}
                        isLoading={form.isLoading}
                        style={{ marginBottom: '24px' }}
                    >
                        Войти
                    </StyledButton>
                    <StyledModal.Action
                        onClick={() => {
                            onClose(false)
                            setHelpIsOpen(true)
                        }}
                    >
                        Не можете войти?
                    </StyledModal.Action>
                </Form>
            </Modal>
            <PasswordModal
                isOpen={success}
                onClose={setSuccess}
                form={form}
                repeatCode={onSubmit}
                codeError={codeError}
            />
            <RegistrationModal
                isOpen={registrationIsOpen}
                onClose={setRegistrationIsOpen}
                signIn={() => {
                    setRegistrationIsOpen(false)
                    onClose(true)
                }}
            />
            <HelpModal isOpen={helpIsOpen} onClose={setHelpIsOpen} />
        </>
    )
}
