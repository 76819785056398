import React, { useRef, useEffect, useContext, useState } from 'react'
import { useAuth } from './AuthContext'
import Centrifuge from 'centrifuge'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const SocketContext = React.createContext()

export function SocketProvider({ children }) {
    const ws = useRef(null)

    const [data, setData] = useState(null)

    const { user } = useAuth()

    useEffect(() => {
        if (user) {
            ws.current = new Centrifuge(publicRuntimeConfig.socketUrl)

            const channel = ws.current

            channel.setToken(user.jwt_token)

            channel.on('connect', function (ctx) {
                // console.log("connected", ctx)
            })

            channel.on('disconnect', function (ctx) {
                // console.log("disconnected", ctx)
            })

            channel.subscribe(user.socket_notifications_key, function (ctx) {
                setData(ctx.data)
            })

            channel.connect()
        } else if (data && !user) {
            setData(null)
        }
    }, [user])

    return <SocketContext.Provider value={{ ws, data, setData }}>{children}</SocketContext.Provider>
}

export function useSocket() {
    return useContext(SocketContext)
}
