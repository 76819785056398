import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Tabs = styled.div`
    display: flex;
    margin-bottom: ${p => p.mb === 'large' ? "48px" : p.mb === "none" ? 0 : "24px"};
    ${p => !p.noBorder && `border-bottom: 1px solid ${vars.theme.outlineGrey};`}
    ${p => p.ml && `margin-left: 32px;`}
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: ${p => p.mb === 'large' ? "32px" : p.mb === "none" ? 0 : "24px"};
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: ${p => p.mb === "none" ? 0 : "24px"};
        margin-right: -20px;
        overflow-x: auto;
        overflow-y: hidden;
    `}
`

Tabs.Tab = styled.div`
    position: relative;
    margin-right: 24px;
    padding: ${p => p.largePadding ? "29px 0" : "0 0 16px"};
    ${p => !p.small && css`
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
    `}
    color: ${p => p.active ? vars.theme.darkGrey : vars.theme.grey};
    ${p => p.black && `color: ${vars.theme.black};`}
    letter-spacing: 0.04em;
    white-space: nowrap;
    cursor: pointer;
    transition: .3s;
    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${p => p.grey ? vars.theme.darkGrey : vars.theme.red};
        transform: scale(0);
        transform-origin: ${p => p.active ? "0 100%" : "100% 0"};
        transition: transform 0.2s linear 0s;
    }
    ${p => p.active && css`
        &:after {
            transform: none;
        }
    `}
    &:hover {
        color: ${vars.theme.darkGrey};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.largePadding && "padding: 17px 0;"}
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        &:after {
            bottom: 0;
        }
        &:last-child {
            padding-right: 20px;
        }
    `}
`

export default Tabs
