import { StyledSection, StyledContainer } from '@/components/styles'
import { Calculator } from '@/components'

export default function ({ calculator, onBtnClick, blockRef }) {
    return (
        <StyledSection pt="medium" pb="small" ref={blockRef}>
            <StyledContainer>
                <Calculator calculator={calculator} onBtnClick={onBtnClick} />
            </StyledContainer>
        </StyledSection>
    )
}
