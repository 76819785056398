import Modal from 'react-modal'
import { StyledTitle, StyledModal, StyledText } from '@/components/styles'
import { SvgIcon } from '@/components'

export default function ({
    children,
    title,
    text,
    small,
    large,
    medium,
    padding,
    isOpen,
    onClose,
    className,
    onAfterOpen,
    bottomComponent,
}) {
    const customStyles = {
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            background: 'rgba(26, 28, 33, .6)',
            overflow: 'auto',
        },
        content: {
            position: 'absolute',
            top: '40px',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: large ? '1200px' : medium ? '504px' : small ? '384px' : '768px',
            maxWidth: 'calc(100% - 40px)',
            marginBottom: '40px',
            WebkitOverflowScrolling: 'touch',
            border: 'none',
            overflow: 'hidden',
        },
    }

    Modal.setAppElement('body')

    return isOpen ? (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => onClose(false)}
            style={customStyles}
            closeTimeoutMS={500}
            className={className}
            onAfterOpen={onAfterOpen}
        >
            <StyledModal.Close onClick={() => onClose(false)}>
                <SvgIcon name="close" width="20" height="20" />
            </StyledModal.Close>
            <StyledModal.Inner padding={padding}>
                <StyledModal.Top mb={children}>
                    {title && (
                        <StyledTitle small mb={text ? 'small' : 'none'} modal>
                            {title}
                        </StyledTitle>
                    )}
                    {text && <StyledText dangerouslySetInnerHTML={{ __html:text}}/>}
                </StyledModal.Top>
                {children}
            </StyledModal.Inner>
            {bottomComponent && <StyledModal.Bottom>{bottomComponent}</StyledModal.Bottom>}
        </Modal>
    ) : null
}
