import { StyledInput } from '@/components/styles'
import { InputWrapper, SvgIcon } from '@/components'
import InputRange from 'react-input-range'
import numberToString from '@/lib/numberToString'
import { useRef, createRef, useEffect } from 'react';

const prepareValue = (v) => {
    if(!v) return 0;
    return parseInt(v.replace(/[^\d]/g, ''))
}


export default function ({
    label,
    error,
    value = 0,
    min = 0,
    max,
    average,
    step = 1,
    mb,
    placeholder,
    measure,
    as,
    onChange,
    onClick,
    onBlur,
    inputRef,
    ...props
}) {
    step =  step ? parseInt(step) : 1;
    const mobValue = useRef();
    const rInp = useRef();
    const getMobString = (v) => Array.isArray(measure)
            ? `${v} ${numberToString(v, measure)}`
            : `${v} ${measure}` 

    useEffect(() => {
        if('0' === value || '' === value) {
            value = 0;
            rInp.current.value = 0;
            mobValue.current.innerHTML = getMobString(0);
        }
        if('0' === min || '' === min) {
            min = 0;
        }
    }, [])

    const onBlurCheckValue = (inp) => {
        value = (value < min)? min : value;
        value = (value > max)? max : value;
        inp.value = value + 0;
        mobValue.current.innerHTML = getMobString(value);
    }

    return (
        <InputWrapper label={label} error={error} mb={mb || 'large'} range>
            <StyledInput.RangeButton minus onClick={() => {
                if('' === value || '0' === value) value = 0;
                let newVal = value - step;
                let vInp = newVal < min ? min : newVal ;
                onChange(vInp)
            }}>
                <SvgIcon name="minus" width="24" height="24" />
            </StyledInput.RangeButton>
            <StyledInput
                type="text"
                placeholder={placeholder}
                value={value}
                min={min}
                max={max}
                onChange={(e) => onChange(prepareValue(e.target.value))}
                onClick={onClick}
                onBlur={(e) => onBlurCheckValue(e.target)}
                error={error}
                as={as}
                ref={inputRef}
                range
                {...props}
            />
            <StyledInput.RangeButton plus onClick={() => {
                if('' === value || '0' === value) value = 0;
                let newVal = value + step;
                let vInp = newVal > max ? max : newVal;
                onChange(vInp);
            }}>
                <SvgIcon name="plus" width="24" height="24" />
            </StyledInput.RangeButton>
            <StyledInput.MobileValue ref={mobValue}>
                {Array.isArray(measure)
                    ? `${value} ${numberToString(value, measure)}`
                    : `${value} ${measure}`}
            </StyledInput.MobileValue>
            <InputRange
                ref={rInp}
                maxValue={+max}
                minValue={+min}
                step={+step || 1}
                value={+value ?? 0}
                formatLabel={(value) => getMobString(value) }
                onChange={(value) => onChange(value)}
            />
            {average && (
                <StyledInput.RangeAverage>
                    {max / 2} {measure}
                </StyledInput.RangeAverage>
            )}
        </InputWrapper>
    )
}
