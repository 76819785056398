import { useState, useEffect } from 'react'
import { StyledInput } from '@/components/styles'
import { InputWrapper } from '@/components'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'

registerLocale('ru', ru)

export default function ({ error, value, placeholder, onChange, onClick, label, mb }) {
    const [startDate, setStartDate] = useState('')

    useEffect(() => {
        value && setStartDate(value)
    }, [value])

    return (
        <InputWrapper label={label} error={error} mb={mb}>
            <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                onChange={(date) => {
                    setStartDate(date)
                    onChange(date)
                }}
                onClick={onClick}
                showDisabledMonthNavigation
                locale="ru"
                value={value}
                showMonthDropdown
                showYearDropdown
                placeholderText={placeholder}
                customInput={
                    <StyledInput.Mask
                        type="text"
                        mask="99.99.9999"
                        maskChar=""
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        onClick={onClick}
                        error={error}
                    />
                }
            />
        </InputWrapper>
    )
}
