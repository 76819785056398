import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import InputMask from 'react-input-mask'

export const inputStyles = () => css`
    width: 100%;
    padding: ${p => p.small ? "16px" : "32px 16px 12px"};
    ${p => !p.small && css`
        background: ${p => p.white ? "#fff" : vars.theme.lightGrey};
        border: 1px solid ${p.error ? vars.theme.red : vars.theme.outlineGrey};
    `}
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    
    ${(p) =>
    p.noRightBorderRadius &&
    css`
            border-radius: 4px 0 0 4px;
        `}
    
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: ${p => p.small ? "15px 16px" : "31px 16px 11px"};
        
        ${p => p.range && css`
            padding: 31px 50px 11px;
            color: transparent;
            caret-color: ${vars.theme.black};
        `}
    `}
`

const Input = styled.input`
    ${inputStyles}
    
    ${(p) => p.as === 'textarea' &&
        css`
            min-height: ${p => p.default ? '60px' : '120px'};
    `}
`

Input.Mask = styled(InputMask)`
    ${inputStyles}
`

Input.Top = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 17px;
    color: ${vars.theme.grey};
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 15px;
    `}
`

Input.Label = styled.label`
    position: ${p => p.static ? "static" : "absolute"};
    ${p => p.static && "margin-bottom: 8px;"}
    top: 12px;
    left: 16px;
    display: block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.35px;
    color: #5C6A79;
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.range && "left: 50px;"}
        ${p => p.mb && "margin-bottom: 8px;"}
        font-size: 12px;
        line-height: 15px;
    `}
`

Input.Title = styled.div`
    margin-bottom: 16px;
    font-weight: normal;
`

Input.Inner = styled.div`
    position: relative;
`

Input.Error = styled.div`
    margin-top: 4px;
    color: ${vars.theme.red};
    font-size: 14px;
`

Input.File = styled.label`
    cursor: pointer;
`

Input.FileUpload = styled.div`
    display: inline-block;
`

Input.FileValue = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 440px;
    margin-bottom: 4px;
    padding: 6px 8px;
    background: ${vars.theme.lightGrey};
    font-size: 14px;
    line-height: 20px;  
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

Input.FileSize = styled.span`
    margin-left: 8px;
    color: ${vars.theme.darkGrey};
`

Input.FileRemove = styled.div`
    flex-shrink: 0;
    padding: 2px;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
`

Input.FileError = styled.div`
    margin-bottom: 6px;
    font-size: 12px;
    color: ${vars.theme.red};
`

Input.FileInput = styled.input`
    display: none;
`

Input.Select = styled.div`
    ${inputStyles}
    position: relative;
    padding: 0;
    width: 100%;
    text-align: left;

    &:after {
        content: '';
        position: absolute;
        right: ${(p) => (p.mini ? '10px' : '20px')};
        top: ${(p) => (p.error ? 'calc(50% - 15px)' : 'calc(50% - 5px)')};
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(${(props) => (props.select ? '-135deg' : '45deg')});
        pointer-events: none;
        transition: all 0.3s;
    }
`

Input.SelectChoice = styled.div`
    padding: ${(p) => (p.mini ? '6px 8px' : '14px 30px 14px 15px')};
    ${(p) => p.color && `color: ${p.color};`}
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

Input.SelectList = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: ${(p) => (p.select ? 'block' : 'none')};
    width: 100%;
    background: #fff;
    box-shadow: 16px 12px 59px rgba(4, 4, 5, 0.23);
    z-index: 3;
`

Input.SelectOption = styled.div`
    width: 100%;
    padding: ${(p) => (p.mini ? '6px 8px' : '15px')};
    background: #fff;
    cursor: pointer;
`

Input.Wrapper = styled.div`
    position: relative;
    width: 100%;
    ${({mb}) => {
    switch (mb) {
        case 'large':
            return css`
                  margin-bottom: 32px;
                  ${maxWidth(vars.breakpoints.tablet)`
                    margin-bottom: 24px;
                  `}
              `
        case 'medium':
            return css`
                  margin-bottom: 20px;
                  ${maxWidth(vars.breakpoints.tablet)`
                    margin-bottom: 16px;
                  `}
              `
        case 'small':
            return css`
                  margin-bottom: 16px;
              `
        case 'none':
            return css`
                  margin-bottom: 0;
              `

        default:
            return css`
                  margin-bottom: 24px;
                  ${maxWidth(vars.breakpoints.tablet)`
                    margin-bottom: 20px;
                  `}
              `
    }
}}
    ${(p) =>
        p.w50p &&
        css`
            width: 50%;
        `}
    ${(p) =>
        p.horMargin &&
        css`
            width: calc(50% - 30px);
            margin-left: 15px;
            margin-right: 15px;
        `}
    ${(p) =>
        p.horMargin66 &&
        css`
            width: calc(100% / 3 * 2 - 30px);
            margin-left: 15px;
            margin-right: 15px;
        `}
    ${(p) =>
        p.horMargin33 &&
        css`
            width: calc(100% / 3 - 30px);
            margin-left: 15px;
            margin-right: 15px;
        `}
    ${(p) =>
        p.horMargin25 &&
        css`
            width: calc(25% - 30px);
            margin-left: 15px;
            margin-right: 15px;
        `}
    ${maxWidth(vars.breakpoints.tablet)`
        ${(p) =>
            p.w50p &&
            css`
                width: 100%;
            `}
        ${(p) =>
            p.horMargin &&
            css`
                width: calc(100% - 30px);
            `}
        ${(p) =>
            p.horMargin66 &&
            css`
                width: calc(100% - 30px);
            `}
        ${(p) =>
            p.horMargin33 &&
            css`
                width: calc(50% - 30px);
            `}
        ${(p) =>
            p.horMargin25 &&
            css`
                width: calc(50% - 30px);
            `}
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        ${(p) =>
            p.mobile100 &&
            css`
                width: calc(100% - 30px);
            `}
    `}
`

Input.Required = styled.div`
    position: absolute;
    left: -20px;
    top: 18px;
    color: #ff2856;
    ${maxWidth(vars.breakpoints.lMobile)`
        left: -10px;
    `}
`

Input.RangeAverage = styled.div`
    position: absolute;
    bottom: -1.4rem;
    left: calc(50% - 40px);
    width: 80px;
    font-size: 12px;
    line-height: 16px;
    color: #aaaaaa;
    text-align: center;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: none;
    `}
`

Input.RangeButton = styled.div`
    position: absolute;
    top: 0;
    padding: 12px;
    display: none;
    height: 100%;
    ${p => p.minus ? "left" : "right"}: 0;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: flex;
        align-items: center;
    `}
`

Input.MobileValue = styled.div`
    position: absolute;
    top: 32px;
    left: 50px;
    display: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    pointer-events: none;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
    `}
`

export default Input
