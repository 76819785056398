import { useState } from 'react'
import { StyledVacancies } from '@/components/styles'
import { SvgIcon, VacancyModal } from '@/components'

export default function ({ vacancy }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <StyledVacancies.Item onClick={() => setIsOpen(true)}>
                <StyledVacancies.ItemTitle>{vacancy.title}</StyledVacancies.ItemTitle>
                <StyledVacancies.ItemRegion>
                    {vacancy.city.title}, {vacancy.city.area.title}
                </StyledVacancies.ItemRegion>
                <SvgIcon name="arrow" width="18" height="14" />
            </StyledVacancies.Item>
            <VacancyModal isOpen={isOpen} onClose={setIsOpen} vacancy={vacancy} />
        </>
    )
}
