import { StyledContacts } from '@/components/styles'
import { ContactsOffice } from '@/components'

export default function ({ title, offices, isOpen, onClick }) {
    return (
        <StyledContacts.City isOpen={isOpen}>
            <StyledContacts.CityHead isOpen={isOpen} onClick={onClick}>
                {title}
            </StyledContacts.CityHead>
            <StyledContacts.CityContent isOpen={isOpen}>
                {offices.map((item) => (
                    <ContactsOffice key={item.id} office={item} />
                ))}
            </StyledContacts.CityContent>
        </StyledContacts.City>
    )
}
