import { StyledTabs } from '@/components/styles'

export default function ({ tabs, tab, setTab, grey, small, mb, contacts }) {
    return (
        <StyledTabs mb={mb} noBorder={contacts} ml={contacts}>
            {tabs.map((item) => (
                <StyledTabs.Tab
                    key={item.id}
                    active={tab === item.id}
                    onClick={() => setTab(item.id)}
                    grey={grey}
                    small={small}
                    largePadding={contacts}
                    black={contacts}
                >
                    {item.title}
                </StyledTabs.Tab>
            ))}
        </StyledTabs>
    )
}
