import { useState } from 'react'
import { SvgIcon, RouteLink } from '@/components'
import { StyledFooter, StyledContainer } from '@/components/styles'
import { useInfo } from '@/contexts/InfoContext'

export default function ({ footerStyle }) {
    const { categories, social } = useInfo()
    const [telIsOpen, setTelIsOpen] = useState(false)
    const [dropdownIsOpen, setDropdownIsOpen] = useState(null)
    const year = new Date().getFullYear();
    const toTopFunc = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <StyledFooter darkBack={footerStyle === 'dark'}>
            <StyledFooter.Top darkBack={footerStyle === 'dark'}>
                <StyledContainer>
                    <StyledFooter.Inner>
                        <StyledFooter.Social dark={footerStyle === 'dark'}>
                            {social.map((item) => (
                                <StyledFooter.SocialItem
                                    key={item.id}
                                    href={item.value}
                                    target="_blank"
                                    dark={footerStyle === 'dark'}
                                >
                                    <SvgIcon
                                        name={
                                            footerStyle === 'dark' && item.type != 'tik_tok' && item.type != 'yandex_zen'
                                                ? `${item.type}-white`
                                                : item.type
                                        }
                                        width={item.type == 'tik_tok' || item.type == 'yandex_zen' ? 36 : 16}
                                        height={item.type == 'tik_tok' || item.type == 'yandex_zen' ? 36 : 16}
                                    />
                                </StyledFooter.SocialItem>
                            ))}
                        </StyledFooter.Social>
                        <StyledFooter.Contacts
                            onMouseEnter={() => setTelIsOpen(true)}
                            onMouseLeave={() => setTelIsOpen(false)}
                        >
                            <StyledFooter.ContactsTitle
                                isOpen={telIsOpen}
                                white={footerStyle === 'dark'}
                            >
                                +375 (29) 888-3-222 (МТС)
                            </StyledFooter.ContactsTitle>
                            <StyledFooter.ContactsDropdown
                                isOpen={telIsOpen}
                                dark={footerStyle === 'dark'}
                            >
                                <StyledFooter.Phone href="tel:+375298883222">+375 (29) 888-3-222 (МТС)</StyledFooter.Phone>
                                <StyledFooter.Phone href="tel:+375291226800">+375 (29) 122-6-800 (А1)</StyledFooter.Phone>
                            </StyledFooter.ContactsDropdown>
                        </StyledFooter.Contacts>
                    </StyledFooter.Inner>
                </StyledContainer>
            </StyledFooter.Top>
            <StyledFooter.Nav>
                <StyledContainer>
                    <StyledFooter.NavInner>
                        {categories.footer.map((column, idx) => (
                            <StyledFooter.NavColumn key={idx}>
                                {column.map((navItem) => (
                                    <StyledFooter.NavItem
                                        key={navItem.id}
                                        dark={footerStyle === 'dark'}
                                    >
                                        <StyledFooter.NavTitle
                                            white={footerStyle === 'dark'}
                                            mb={navItem.children?.length > 0}
                                            arrow={navItem.children?.length > 0}
                                            isOpen={dropdownIsOpen === navItem.id}
                                            onClick={() =>
                                                setDropdownIsOpen(
                                                    dropdownIsOpen === navItem.id
                                                        ? null
                                                        : navItem.id,
                                                )
                                            }
                                        >
                                            <RouteLink slug={navItem.slug}>
                                                <a>{navItem.name}</a>
                                            </RouteLink>
                                        </StyledFooter.NavTitle>
                                        {navItem.children?.length > 0 && (
                                            <StyledFooter.NavSubmenu
                                                isOpen={dropdownIsOpen === navItem.id}
                                            >
                                                {navItem?.children.map((item) => (
                                                    <StyledFooter.NavSubmenuItem key={item.id}>
                                                        <RouteLink slug={item.slug}>
                                                            <a>{item.name}</a>
                                                        </RouteLink>
                                                    </StyledFooter.NavSubmenuItem>
                                                ))}
                                            </StyledFooter.NavSubmenu>
                                        )}
                                    </StyledFooter.NavItem>
                                ))}
                            </StyledFooter.NavColumn>
                        ))}
                    </StyledFooter.NavInner>
                </StyledContainer>
            </StyledFooter.Nav>
            <StyledFooter.Bottom dark={footerStyle === 'dark'} large={footerStyle === 'large'}>
                <StyledContainer>
                    <StyledFooter.Inner>
                        <StyledFooter.Copyright>
                            Центр финансовых консультаций © 2019-{year} | Все права защищены. 
                            <br />
                            УНП 193679768, 220040, г.Минск, 3-й пер-к Можайского, 11, пом. 107
                            <br />
                            <a href="/Политика_обработки_персональных_данных_Центра_финансовых_консультаций.pdf">Политика конфиденциальности</a>
                        </StyledFooter.Copyright>
                        <StyledFooter.Dev
                            href="/"
                            target="_blank"
                        >
                            <SvgIcon name="logo-small" width="48" height="48" />
                        </StyledFooter.Dev>
                    </StyledFooter.Inner>
                </StyledContainer>
            </StyledFooter.Bottom>
            <StyledFooter.Arrow onClick={toTopFunc}>
                <SvgIcon name="arrow-top" width="23" height="15" />
            </StyledFooter.Arrow>
        </StyledFooter>
    )
}
