import styled from 'styled-components'
import { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useRouter } from 'next/router'
import { Header, Footer, ContactsHeader, ProfileHeader } from '@/components'
import { useAuth } from '@/contexts/AuthContext'

export default function Page({ children, header, footer, mobileHeader, userType }) {
    const router = useRouter()
    const { user } = useAuth()

    const pageRef = useRef(null)
    const [prevScrollPos, setPrevScrollPos] = useState(0)
    const [menuIsVisible, setMenuIsVisible] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const onScroll = async () => {
        let currentScrollPos =
            pageRef.current && ReactDOM.findDOMNode(pageRef.current).getBoundingClientRect().y
        let visible = currentScrollPos >= prevScrollPos || currentScrollPos >= 0;
        pageRef.current && setMenuIsVisible(visible)
        pageRef.current && setPrevScrollPos(currentScrollPos)      
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [prevScrollPos])

    useEffect(() => {
        if (userType == 'user' && !user) {
            router.push('/')
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (userType == 'user' && !user) {
            setIsLoading(true)
            router.push('/')
            setIsLoading(false)
        }
    }, [user])

    return (
        <StyledPage ref={pageRef}>
            {header === 'contacts' ? (
                <ContactsHeader isVisible={menuIsVisible} />
            ) : header === 'user' ? (
                user ? (
                    <ProfileHeader
                        isVisible={menuIsVisible}
                        headerStyle={header}
                        mobileHeader={mobileHeader}
                    />
                ) : null
            ) : (
                <Header isVisible={menuIsVisible} headerStyle={header} />
            )}
            <StyledContent>
                {(userType == 'all' || (!isLoading && userType == 'user' && user)) && children}
            </StyledContent>
            {footer !== 'none' && <Footer footerStyle={footer} />}
        </StyledPage>
    )
}

const StyledPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 320px;
`

const StyledContent = styled.main`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
`
