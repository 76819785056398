import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Office = styled.div`
    padding: 32px;
    &:not(:last-child) {
        border-bottom: 1px solid ${vars.theme.outlineGrey};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 24px;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        padding: 20px 0;
    `}
`

Office.Inner = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1.1fr 1.1fr .9fr;
    grid-template-rows: max-content;
    gap: 24px;
    margin-bottom: 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 2fr 1fr;
        margin-bottom: 20px;
    `}
    ${maxWidth(vars.breakpoints.mobile)`
        grid-template-columns: 1fr;
        gap: 20px;
    `}
`

Office.Column = styled.div`
    ${maxWidth(vars.breakpoints.mTablet)`
        &:nth-child(2), &:nth-child(3) {
            display: none;
        }
    `}
`

Office.Title = styled.div`
    margin-bottom: 4px;
    ${p => p.main ? css`
        font-weight: 500;
    ` : css`
        color: ${vars.theme.darkGrey};
    `}
`

Office.Rating = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 0;
    span:not(:last-child) {
        padding-right: 1px;
    }
`

Office.RatingTitle = styled.div`
    margin-right: 8px;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Office.Actions = styled.div`
    display: flex;
    ${p => p.mb && "margin-bottom: 24px;"}
    ${maxWidth(vars.breakpoints.mobile)`
        ${p => p.mb && "margin-bottom: 20px;"}
    `}
`

Office.Action = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-right: 70px;
    }
    letter-spacing: 0.2px;
    color: ${vars.theme.red};
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
    ${maxWidth(vars.breakpoints.mobile)`
        &:last-child {
            display: none;
        }
    `}
`

Office.Map = styled.div`
    position: relative;
    overflow: hidden;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    height: ${p => p.isOpen ? "320px" : "0"};
    transition: ${p => p.isOpen ? "opacity 0.25s ease 0.1s, visibility 0s ease 0.1s" : "opacity 0s ease 0.1s, visibility 0s ease 0.1s"}, height .3s;   
`

Office.EmployeePhotos = styled.div`
    display: flex;
`

Office.EmployeePhotosItem = styled.div`
    display: flex;
    width: 40px;
    height: 48px;
    border-radius: 50%;
    ${p => p.empty && css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        background: ${vars.theme.lightGrey};
        color: ${vars.theme.darkGrey};
    `}
    img {
        width: 48px;
        max-width: inherit;
        border-radius: 50%;
        object-fit: cover;
    }
`

Office.EmployeeToggle = styled.div`
    position: relative;
    padding-right: 24px;
    font-weight: normal;
    color: ${vars.theme.red};
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: ${p => p.isOpen ? "calc(50% - 3px)" : "calc(50% - 8px)"};
        display: block;
        width: 8.5px;
        height: 8.5px;
        border-right: 1px solid ${vars.theme.red};
        border-bottom: 1px solid ${vars.theme.red};
        transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
        transition: .3s;
    }
`

Office.Employee = styled.div`
    display: grid;
    grid-template-columns: 56px 1fr 180px;
    grid-template-areas: 'img name button' 'img rating button';
    gap: 0 16px;
    padding: 24px 0;
    border-bottom: 1px solid ${vars.theme.outlineGrey};
    &:first-child {
        border-top: 1px solid ${vars.theme.outlineGrey};
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: 56px 1fr;
        grid-template-areas: 'img name' 'img rating' 'button button';
    `}
`

Office.EmployeeImg = styled.div`
    grid-area: img;
    display: flex;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    img {
        object-fit: cover;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        width: 48px;
        height: 48px;
    `}
`

Office.EmployeeName = styled.div`
    grid-area: name;
    margin-bottom: 4px;
`

Office.EmployeeRating = styled.div`
    grid-area: rating;    
    color: ${vars.theme.red};
`

Office.EmployeeButton = styled.div`
    grid-area: button;    
    ${maxWidth(vars.breakpoints.mMobile)`
        margin-top: 16px;
    `}
`

Office.ReviewTop = styled.div`
    margin-bottom: 24px;
    padding: 16px;
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 8px;
    ${p => p.flex && css`
        display: flex;
        align-items: center;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        
    `}
`

Office.ReviewImg = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    img {
        object-fit: cover;
    }
    ${maxWidth(vars.breakpoints.mMobile)`
        
    `}
`

export default Office
