import { useState } from 'react'
import { StyledService, StyledTitle, StyledText } from '@/components/styles'
import { ServiceCard, SvgIcon, CallbackModal } from '@/components'
import api from '@/lib/api'

export default function ({ tariffs }) {
    const [{ modalIsOpen, service }, setModalState] = useState({ modalIsOpen: false, service: '' })

    return (
        <StyledService>
            <StyledService.Left>
                <StyledTitle mb="medium" as="h2">Уникальные условия на рынке</StyledTitle>
                <StyledText grey>
                    Каждому клиенту доступен один из двух пакетов услуг с разными условими.
                    Подробности и каждый пункт наших обязательств прописан в договоре.
                </StyledText>
                <StyledService.Download
                    onClick={() => setModalState({ modalIsOpen: true, service: '' })}
                >
                    <SvgIcon name="download" width="14" height="18" />
                    Посмотреть договор
                </StyledService.Download>
            </StyledService.Left>
            <StyledService.Inner>
                {tariffs.map((item) => (
                    <ServiceCard
                        key={item.id}
                        name={item.title}
                        desc={item.description}
                        conditions={item.list}
                        onClick={() => setModalState({ modalIsOpen: true, service: item.title })}
                    />
                ))}
            </StyledService.Inner>
            <CallbackModal
                isOpen={modalIsOpen}
                onClose={setModalState}
                title="Получите индивидуальный договор"
                text="Укажите контактные данные - мы перезвоним вам для уточнения пожеланий по продукту и пришлем образец договора в течение 30 минут"
                onSubmitMethod={(values) =>
                    api.customRecord({
                        ...values,
                        credit_type: `Заявка в блоке "Уникальные условия", "${service}"`,
                    })
                }
            />
        </StyledService>
    )
}
