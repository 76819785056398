import numberToString from '@/lib/numberToString'
import { useCalculator } from '@/contexts/CalculatorContext'

export default function (values) {
    const { termType } = useCalculator()

    const conditions = [
        {
            id: 1,
            title: values.sum,
            measure: 'BYN',
            text: 'Сумма',
        },
        {
            id: 2,
            title: values.monthSum,
            measure: 'BYN',
            text: 'Текущий платеж',
        },
        {
            id: 3,
            title: values.initialFee,
            measure: 'BYN',
            text: 'Первый взнос',
        },
        {
            id: 4,
            title: values.term,
            measure:
                termType === 'month' ? 'мес' : numberToString(values.term, ['год', 'года', 'лет']),
            text: 'Срок',
        },
        {
            id: 5,
            title: values.rate,
            measure: '%',
            text: 'Ставка',
        },
        {
            id: 6,
            title: values.monthPay,
            measure: 'BYN',
            text: 'Месячный платеж',
        },
        {
            id: 7,
            title: values.saving,
            measure: 'BYN',
            text: 'Экономия в месяц',
        },
    ]

    return conditions.filter((item) => item.title)
}
