import { useState, useRef, useEffect } from 'react'
import { StyledHeader, StyledContainer, StyledNav } from '@/components/styles'
import { SvgIcon, EditModal } from '@/components'
import Link from 'next/link'
import { useAuth } from '@/contexts/AuthContext'
import { useRouter } from 'next/router'
import moment from 'moment'

const nav = [
    { id: 1, link: '/appeal', title: 'Обращения' },
    { id: 2, link: '/questions', title: 'Вопросы и ответы' },
]

export default function ({ mobileHeader }) {
    const router = useRouter()
    const dropdownRef = useRef()

    const { user, logOut } = useAuth()

    const [isOpen, setIsOpen] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('click', onClickOutside)
        return () => window.removeEventListener('click', onClickOutside)
    }, [isOpen])

    const onClickOutside = (e) => {
        if (isOpen && dropdownRef.current !== e.target) {
            setIsOpen(false)
        }
    }

    return (
        <StyledHeader isVisible desktop={mobileHeader === 'none'}>
            <StyledContainer>
                <StyledHeader.Inner>
                    <StyledHeader.Logo desktop>
                        <Link href="/">
                            <a>
                                <SvgIcon name="logo" />
                            </a>
                        </Link>
                    </StyledHeader.Logo>
                    <StyledHeader.Logo mobile>
                        <Link href="/">
                            <a>
                                <SvgIcon name="logo-small" />
                            </a>
                        </Link>
                    </StyledHeader.Logo>
                    <StyledNav mobileVisible>
                        {nav.map((item) => (
                            <StyledNav.Item key={item.id}>
                                <StyledNav.ItemTitle
                                    link
                                    active={router.pathname.includes(item.link)}
                                >
                                    <Link href={item.link}>
                                        <a>{item.title}</a>
                                    </Link>
                                </StyledNav.ItemTitle>
                            </StyledNav.Item>
                        ))}
                        <StyledNav.Item
                            withDropdown
                            ref={dropdownRef}
                            isOpen={isOpen}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <StyledNav.ItemTitle desktop noPointer>
                                Личный кабинет
                            </StyledNav.ItemTitle>
                            <StyledNav.ItemTitle mobile noPointer>
                                <SvgIcon name="user" width="24" height="24" />
                            </StyledNav.ItemTitle>
                            <StyledNav.ItemDropdown isOpen={isOpen}>
                                <StyledNav.ItemDropdownRow>
                                    {user?.name}
                                    <StyledNav.ProfileEdit onClick={() => setModalIsOpen(true)}>
                                        <SvgIcon name="edit-small" width={16} height={16} />
                                        Изменить
                                    </StyledNav.ProfileEdit>
                                </StyledNav.ItemDropdownRow>
                                {user.contract_number && (
                                    <StyledNav.ItemDropdownRow>
                                        <StyledNav.Contract date={user.contract_time_to}>
                                            <StyledNav.ContractNumber>
                                                {user.contract_number}
                                            </StyledNav.ContractNumber>
                                            <StyledNav.ContractStatus paid={user.paid}>
                                                {user.paid ? 'Оплачено' : 'Не оплачено'}
                                            </StyledNav.ContractStatus>
                                            {user.contract_time_to && (
                                                <StyledNav.ContractDate>
                                                    {!moment(user.contract_time_to).isBefore(
                                                        new Date(),
                                                    )
                                                        ? `Договор действителен до ${moment(
                                                              user.contract_time_to,
                                                          ).format('DD.MM.YYYY')}`
                                                        : 'Договор недействителен'}
                                                </StyledNav.ContractDate>
                                            )}
                                        </StyledNav.Contract>
                                    </StyledNav.ItemDropdownRow>
                                )}
                                <StyledNav.ItemDropdownRow
                                    border={!user.contract_number}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setIsOpen(false)
                                        logOut()
                                    }}
                                >
                                    Выйти
                                    <SvgIcon name="exit" width="17" height="18" />
                                </StyledNav.ItemDropdownRow>
                            </StyledNav.ItemDropdown>
                        </StyledNav.Item>
                    </StyledNav>
                </StyledHeader.Inner>
            </StyledContainer>
            <EditModal isOpen={modalIsOpen} onClose={setModalIsOpen} />
        </StyledHeader>
    )
}
