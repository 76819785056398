import { StyledContainer, StyledCategories, StyledButton } from '@/components/styles'

export default function ({ conditions, onBtnClick, center, grey }) {
    return (
        <StyledCategories.Section grey={grey}>
            <StyledContainer>
                <StyledCategories>
                    {conditions.map((item) => (
                        <StyledCategories.Item
                            key={item.id}
                            center={!onBtnClick || center}
                            button={onBtnClick}
                        >
                            <StyledCategories.Title>{item.title}</StyledCategories.Title>
                            <StyledCategories.Text>{item.text}</StyledCategories.Text>
                        </StyledCategories.Item>
                    ))}
                    {onBtnClick && (
                        <StyledCategories.Item isButton>
                            <StyledButton width="full" onClick={onBtnClick}>
                                Оставить заявку
                            </StyledButton>
                        </StyledCategories.Item>
                    )}
                </StyledCategories>
            </StyledContainer>
        </StyledCategories.Section>
    )
}
