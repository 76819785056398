import styled, {css} from 'styled-components'
import {maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Radio = styled.div`
    margin-bottom: ${p => p.mb === "none" ? 0 : "32px"};
    input {
        position: absolute;
        left: -9999px;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 20px;
    `}
`

Radio.Labels = styled.div`
    ${p => (p.type === 'tab' || p.type === 'review') && css`
        display: flex;
        flex-wrap: wrap;
    `}
    ${p => p.type === 'button' && css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    `}
    ${p => p.type === 'switch' && css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 4px;
        background: #FFFFFF;
        border-radius: 8px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.type === 'tab' && css`
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
            margin-right: -20px;
        `}
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        ${p => p.type === 'button' && css`
            grid-template-columns: 1fr;
            gap: 8px;
        `}
    `}
`

Radio.Label = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: .2s;
    
    ${p => p.type === 'list' && css`
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        padding-left: 36px;
        &:before {
            position: absolute;
            left: 0;
            display: inline-block;
            content: '';
            width: 24px;
            height: 24px;
            background: #fff;
            border: 1px solid ${vars.theme.outlineGrey};
            border-radius: 50%;
        }
        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 6px);
            left: 6px;
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: ${p => p.checked ? vars.theme.red : "transparent"};
            transition: .2s;
        }
    `}
    
    ${p => p.type === 'tab' && css`
        padding: 14px 10px;
        &:not(:last-child) {
            margin-right: 8px;
        }
        background: ${p => p.checked ? vars.theme.darkBlue : p => p.white ? "#fff" : vars.theme.lightGrey};
        border-radius: 48px;
        color: ${p => p.checked ? "#fff" : vars.theme.darkBlue};
        font-weight: normal;
        white-space: nowrap;
    `}
    
    ${p => p.type === 'review' && css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        &:not(:last-child) {
            margin-right: 16px;
        }
        background: ${p => p.checked ? "#fff" : vars.theme.lightGrey};
        ${p => p.checked && "box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 22px rgba(61, 75, 86, 0.08);"}
        border-radius: 50%;
        color: ${p => p.checked ? vars.theme.red : vars.theme.darkGrey};
        font-weight: normal;
        white-space: nowrap;
    `}
    
    ${p => p.type === 'switch' && css`
        justify-content: center;
        padding: 12px;
        background: ${p => p.checked ? vars.theme.darkBlue : "#fff"};
        border-radius: 4px;
        color: ${p => p.checked ? "#fff" : vars.theme.darkBlue};
        text-align: center;
    `}
    
    ${p => p.type === 'button' && css`
        padding: 14px;
        border: 1px solid ${p => p.checked ? vars.theme.red : vars.theme.outlineGrey};
        border-radius: 4px;
        color: ${p => p.checked ? vars.theme.black : vars.theme.darkGrey};
        justify-content: center;
        text-align: center;
    `}
    
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.type == 'tab' && css`
            margin-bottom: 3px;
        `}
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.type == 'review' && css`
            width: 48px;
            height: 48px;
            &:not(:last-child) {
                margin-right: 12px;
            }
        `}
    `}
`

export default Radio
