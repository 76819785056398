export default function (product) {
    const conditions = [
        {
            id: 1,
            title: product.credit_sum,
            text: 'Сумма кредита',
        },
        {
            id: 2,
            title: product.credit_percent,
            text: 'Процентная ставка',
        },
        {
            id: 3,
            title: product.credit_time,
            text: 'Срок кредитования',
        },
        {
            id: 4,
            title: product.consideration_time,
            text: 'Время рассмотрения',
        },
        {
            id: 5,
            title: product.monthly_payment,
            text: 'Ежемесячный платеж',
        },
        {
            id: 6,
            title: product.imprest,
            text: 'Аванс',
        },
        {
            id: 7,
            title: product.sum,
            text: 'Сумма',
        },
        {
            id: 8,
            title: product.lease_rate,
            text: 'Лизинговая ставка',
        },
        {
            id: 9,
            title: product.lease_term,
            text: 'Срок лизинга',
        },
    ]

    return conditions.filter((item) => item.title)
}
