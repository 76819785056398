import { useEffect, useState } from 'react'
import { SvgIcon } from '@/components'
import { StyledInput } from '@/components/styles'
import useForm from '@/lib/hooks/form'
import styled from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import { DebounceInput } from 'react-debounce-input'
import { useRouter } from 'next/router'

export default function ({}) {
    const router = useRouter()
    const query = router.query

    const [isOpen, setIsOpen] = useState(false)

    const form = useForm({ search: '' })

    const search = (value) => {
        const { view, search, ...filters } = query
        let newQuery = value
            ? { ...filters, view: 'list', search: value }
            : { ...filters, view: 'list' }

        form.setValues({ search: query.search })

        router.push(
            { pathname: router.pathname, query: newQuery },
            { pathname: location.pathname, query: newQuery },
            { shallow: false },
        )
    }

    useEffect(() => {
        query.search && form.setValues({ search: query.search })
    }, [])

    return (
        <Search>
            <Search.Icon onClick={() => setIsOpen(true)}>
                <SvgIcon name="search" width="20" height="20" />
            </Search.Icon>
            <Search.Inner isOpen={isOpen}>
                <SvgIcon name="search" width="20" height="20" />
                <DebounceInput
                    element={StyledInput}
                    placeholder="Введите название населенного пункта"
                    mb="none"
                    small
                    minLength={2}
                    debounceTimeout={400}
                    value={form.values.search}
                    onChange={(event) => search(event.target.value)}
                />
                <Search.Close onClick={() => setIsOpen(false)}>
                    <SvgIcon name="close" width="20" height="20" />
                </Search.Close>
            </Search.Inner>
        </Search>
    )
}

const Search = styled.div`
    flex: 1;
    margin-right: 20px;
    ${maxWidth(vars.breakpoints.lMobile)`
        flex: inherit;
        margin: 0 0 0 auto;
    `}
`

Search.Inner = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 0;
    ${maxWidth(vars.breakpoints.lMobile)`
        position: absolute;
        left: 0;
        bottom: ${(p) => (p.isOpen ? '0' : '100%')};
        width: 100%;
        background: #fff;
        transition: .2s;
    `}
`

Search.Icon = styled.div`
    display: none;
    ${maxWidth(vars.breakpoints.lMobile)`
        display: block;
        padding: 18px 5px;
        font-size: 0;
    `}
`

Search.Close = styled.div`
    display: none;
    ${maxWidth(vars.breakpoints.lMobile)`
        display: block;
        padding: 5px;
        font-size: 0;
    `}
`
