import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Vacancies = styled.div`
    display: grid;
    grid-template-columns: 1fr 2.78fr;
    gap: 122px;
    ${maxWidth(vars.breakpoints.sDesktop)`
        gap: 80px;
    `}
    ${maxWidth(vars.breakpoints.tablet)`
        grid-template-columns: 1fr 2.5fr;
        gap: 48px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 1fr;
        gap: 24px;
    `}
`

Vacancies.Regions = styled.div`
    display: ${p => p.mobile ? "none" : "block"};
    ${maxWidth(vars.breakpoints.mTablet)`
        position: relative;
        display: ${p => p.mobile ? "block" : "none"};
    `}
`

Vacancies.ChoosenRegion = styled.div`
    padding: 14px 40px 14px 16px;
    background: #FFFFFF;
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 4px;
    &:after {
        content: '';
        position: absolute;
        right: 20px;
        top: ${p => p.isOpen ? "calc(50% - 7px)" : "calc(50% - 3px)"};
        display: block;
        width: 8.5px;
        height: 8.5px;
        border-right: 1px solid ${vars.theme.darkGrey};
        border-bottom: 1px solid ${vars.theme.darkGrey};
        transform: ${p => p.isOpen ? "rotate(45deg)" : "rotate(-135deg)"};
        transition: .3s;
    }
`

Vacancies.RegionsDropdown = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 6px;
    z-index: 1;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    pointer-events: ${p => p.isOpen ? "all" : "none"};
    transition: ${p => p.isOpen ? "visibility 0s, opacity 0.35s ease" : "visibility 0s 0.35s, opacity 0.35s ease"};
    ${maxWidth(vars.breakpoints.mTablet)`
        
    `}
`

Vacancies.RegionTitle = styled.div`
    transition: .3s;
`

Vacancies.RegionCount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(61, 75, 86, 0.08);
    border-radius: 50%;
    color: ${vars.theme.darkGrey};
    transition: .3s;
    ${maxWidth(vars.breakpoints.mTablet)`
        width: 28px;
        height: 28px;
        font-size: 14px;
    `}
`

Vacancies.Region = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    cursor: pointer;
    
    &:hover {
        ${Vacancies.RegionTitle} {
            color: ${vars.theme.red};
        }
        ${Vacancies.RegionCount} {
            color: ${vars.theme.red};
        }
    }
    
    ${p => p.active && css`
        ${Vacancies.RegionTitle} {
            color: ${vars.theme.red};
        }
        ${Vacancies.RegionCount} {
            color: ${vars.theme.red};
        }
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    `}
`

Vacancies.Inner = styled.div``

Vacancies.Item = styled.div`
    position: relative;
    padding: 24px 30px 24px 0;
    &:not(:last-child) {
        border-bottom: 1px solid ${vars.theme.outlineGrey};
    }
    cursor: pointer;
    svg {
        position: absolute;
        right: 5px;
        top: calc(50% - 7px);
        transition: .3s;
    }
    &:first-child {
        padding-top: 0;
        svg {
            top: calc((100% - 24px) / 2 - 7px);
        }
    }
    &:hover {
        svg {
            right: 0;
        }
    }
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 16px 30px 16px 0;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        svg {
            top: 25px;
        }
        &:first-child {
            padding-top: 16px;
            border-top: 1px solid ${vars.theme.outlineGrey};
            svg {
                top: 25px;
            }
        }
    `}
`

Vacancies.ItemTitle = styled.div`
    margin-bottom: 4px;
`

Vacancies.ItemRegion = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Vacancies.Desc = styled.div`
    p {
        ${p => !p.mbNone && "margin-bottom: 24px;"}
        font-weight: 500;
        ${maxWidth(vars.breakpoints.mTablet)`
    
            margin-bottom: 16px;
        `}
    }
    ul:not(:last-child) {
        margin-bottom: 32px;
    }
    li {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 40px;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 24px;
            height: 24px;
            background: ${vars.theme.lightGrey};
            box-shadow: 0px 1px 6px rgba(61, 75, 86, 0.08);
            border-radius: 50%;
        }
        &:after {
            content: '';
            position: absolute;
            left: 7px;
            top: calc(50% - 5px);
            display: block;
            width: 10px;
            height: 6px;
            margin-bottom: 2px;
            border-left: 2px solid ${vars.theme.red};
            border-bottom: 2px solid ${vars.theme.red};
            transform: rotate(-45deg);
        }
    }
`

export default Vacancies
