import { useState } from 'react'
import { StyledSlider } from '@/components/styles'
import { PieChart } from 'react-minimal-pie-chart'
import vars from '@/assets/styles/vars'

export default function ({ length, sliderRef, currentSlide, chart, opacity, showArrows }) {
    const [arrowState, setArrowState] = useState('default')

    return (
        <StyledSlider.Arrows showArrows={showArrows}>
            <StyledSlider.Arrow
                prev
                opacity={opacity}
                onClick={() => {
                    sliderRef.current.slickPrev()
                }}
            />
            {currentSlide && `${currentSlide} из ${length}`}
            <StyledSlider.Arrow
                next
                opacity={opacity}
                onMouseOver={() => setArrowState('hover')}
                onMouseOut={() => setArrowState('default')}
                onMouseDown={() => setArrowState('active')}
                onMouseUp={() => setTimeout(() => setArrowState('hover'), 500)}
                onClick={() => {
                    sliderRef.current.slickNext()
                }}
            >
                {chart && (
                    <PieChart
                        data={[{ value: 1, key: 1, color: vars.theme.red }]}
                        viewBoxSize={[95, 95]}
                        reveal={arrowState === 'hover' ? 45 : arrowState === 'active' ? 100 : 20}
                        lineWidth={9}
                        startAngle={-90}
                        animate
                    />
                )}
            </StyledSlider.Arrow>
        </StyledSlider.Arrows>
    )
}
