import { useState } from 'react'
import {
    StyledTitle,
    StyledCalculator,
    StyledButton,
    StyledText,
    StyledForm,
} from '@/components/styles'
import { RangeInput, Radio, SvgIcon, CallbackModal, } from '@/components'
import numberToString from '@/lib/numberToString'
import api from '@/lib/api'
import { useCalculator } from '@/contexts/CalculatorContext'

export default function ({ calculator, onBtnClick, main, noLeftRadius, noRightRadius }) {
    const { form, rate, termType, calcForm } = useCalculator()
    const [modalIsOpen, setModalIsOpen] = useState(false)

    return (
        <>
        <StyledCalculator noLeftRadius={noLeftRadius} noRightRadius={noRightRadius} main={main}>
            <StyledCalculator.Form>
                <StyledTitle small mb="small" as="h2">
                    {calculator.type === 'credit' || calculator.type === 'credit_v2'
                        ? 'Рассчитайте кредит'
                        : calculator.type === 'leasing'
                        ? 'Рассчитайте лизинг'
                        : 'Рассчитайте рефинансирование'}
                </StyledTitle>
                {calculator.type === 'credit' && (
                    <>
                        <RangeInput
                            label="Сумма"
                            measure="BYN"
                            min={calculator.points[0].amount_from}
                            max={calculator.points[calculator.points.length - 1].amount_to}
                            average
                            step={calculator.step}
                            {...form.input('sum')}
                        />
                        <Radio
                            type="tab"
                            title="Срок"
                            options={Array.from(Array(calculator.deadline_to.year + 1).keys())
                                .slice(calculator.deadline_from.year)
                                .reduce(
                                    (acc, item) => [
                                        ...acc,
                                        {
                                            key: item,
                                            value: `${item} ${numberToString(item, [
                                                'год',
                                                'года',
                                                'лет',
                                            ])}`,
                                        },
                                    ],
                                    [],
                                )}
                            mb="none"
                            {...form.radio('term')}
                        />
                    </>
                )}
                {calculator.type === 'credit_v2' && (
                    <>
                        <RangeInput
                            label={
                                calculator.record_type === 'real_estate'
                                    ? 'Стоимость недвижимости'
                                    : 'Сумма'
                            }
                            measure="BYN"
                            min={calculator.points[0].amount_from}
                            max={calculator.points[calculator.points.length - 1].amount_to}
                            step={calculator.step}
                            {...form.input('sum')}
                        />
                        <StyledForm.Inner>
                            <RangeInput
                                label="Первоначальный взнос"
                                measure="BYN"
                                min="0"
                                max={form.values.sum * 0.9}
                                step={calculator.step}
                                {...form.input('initialFee')}
                            />
                            <RangeInput
                                label="Срок кредитования"
                                measure={termType === 'month' ? 'мес' : ['год', 'года', 'лет']}
                                min={
                                    calculator.deadline_from?.month ?? calculator.deadline_from.year
                                }
                                max={calculator.deadline_to?.month ?? calculator.deadline_to.year}
                                {...form.input('term')}
                            />
                        </StyledForm.Inner>
                    </>
                )}
                {calculator.type === 'leasing' && (
                    <>
                        <RangeInput
                            label="Сумма"
                            measure="BYN"
                            min={calculator.points[0].amount_from}
                            max={calculator.points[calculator.points.length - 1].amount_to}
                            step={calculator.step}
                            {...form.input('sum')}
                        />
                        {calculator.points[0].prepaid ? (
                            <StyledForm.Inner>
                                <RangeInput
                                    label="Срок"
                                    measure={termType === 'month' ? 'мес' : ['год', 'года', 'лет']}
                                    min={
                                        calculator.deadline_from?.month ??
                                        calculator.deadline_from.year
                                    }
                                    max={
                                        calculator.deadline_to?.month ?? calculator.deadline_to.year
                                    }
                                    {...form.input('term')}
                                />
                                <RangeInput
                                    label="Аванс"
                                    measure="BYN"
                                    min="0"
                                    max={form.values.sum * 0.9}
                                    step={calculator.step}
                                    {...form.input('initialFee')}
                                />
                            </StyledForm.Inner>
                        ) : (
                            <RangeInput
                                label="Срок"
                                measure={termType === 'month' ? 'мес' : ['год', 'года', 'лет']}
                                min={
                                    calculator.deadline_from?.month ?? calculator.deadline_from.year
                                }
                                max={calculator.deadline_to?.month ?? calculator.deadline_to.year}
                                {...form.input('term')}
                            />
                        )}
                    </>
                )}
                {calculator.type === 'refinancing' && (
                    <>
                        <StyledForm.Inner>
                            <RangeInput
                                label="Сумма"
                                measure="BYN"
                                min={calculator.points[0].amount_from}
                                max={calculator.points[calculator.points.length - 1].amount_to}
                                step={calculator.step}
                                {...form.input('sum')}
                            />
                            <RangeInput
                                label="Сейчас плачу в месяц"
                                measure="BYN"
                                min={calculator.pay_month_from}
                                max={calculator.pay_month_to}
                                step={calculator.step}
                                {...form.input('monthSum')}
                            />
                        </StyledForm.Inner>
                        <Radio
                            type="tab"
                            title="Срок"
                            options={Array.from(Array(calculator.deadline_to.year + 1).keys())
                                .slice(calculator.deadline_from.year)
                                .reduce(
                                    (acc, item) => [
                                        ...acc,
                                        {
                                            key: item,
                                            value: `${item} ${numberToString(item, [
                                                'год',
                                                'года',
                                                'лет',
                                            ])}`,
                                        },
                                    ],
                                    [],
                                )}
                            mb="none"
                            {...form.radio('term')}
                        />
                    </>
                )}
            </StyledCalculator.Form>
            <StyledCalculator.Info main={main}>
                <StyledCalculator.InfoTop>
                    <StyledTitle small mb="medium">
                        Наше предложение
                    </StyledTitle>
                    {form.values.sum < calculator.points[0].amount_from ? (
                        <StyledCalculator.Subtitle>
                            Подбор кредита возможен от {calculator.points[0].amount_from} BYN
                            <br />
                            Попробуйте рассчитать кредит с другими параметрами
                        </StyledCalculator.Subtitle>
                    ) : form.values.sum >
                      calculator.points[calculator.points.length - 1].amount_to ? (
                        <StyledCalculator.Subtitle>
                            Подбор кредита возможен до{' '}
                            {calculator.points[calculator.points.length - 1].amount_to} BYN
                            <br />
                            Попробуйте рассчитать кредит с другими параметрами
                        </StyledCalculator.Subtitle>
                    ) : (
                        <StyledCalculator.Conditions>
                            {(calculator.type === 'credit' || calculator.type === 'credit_v2') && (
                                <>
                                    {isFinite(calcForm.values.monthPay) && (
                                        <StyledCalculator.Condition>
                                            <StyledCalculator.ConditionValue>
                                                {calcForm.values.monthPay} BYN
                                            </StyledCalculator.ConditionValue>
                                            <StyledText grey>Месячный платеж</StyledText>
                                        </StyledCalculator.Condition>
                                    )}
                                    {rate && calculator.show_rate && (
                                        <StyledCalculator.Condition>
                                            <StyledCalculator.ConditionValue>
                                                {rate}%
                                            </StyledCalculator.ConditionValue>
                                            <StyledText grey>Ставка</StyledText>
                                        </StyledCalculator.Condition>
                                    )}
                                </>
                            )}
                            {calculator.type === 'leasing' && (
                                <>
                                    {isFinite(calcForm.values.monthPay) && (
                                        <StyledCalculator.Condition>
                                            <StyledCalculator.ConditionValue>
                                                {calcForm.values.monthPay} BYN
                                            </StyledCalculator.ConditionValue>
                                            <StyledText grey>Месячный платеж</StyledText>
                                        </StyledCalculator.Condition>
                                    )}
                                    <StyledCalculator.Condition>
                                        <StyledCalculator.ConditionValue>
                                            {calcForm.values.dealSum} BYN
                                        </StyledCalculator.ConditionValue>
                                        <StyledText grey>Сумма договора</StyledText>
                                    </StyledCalculator.Condition>
                                </>
                            )}
                            {calculator.type === 'refinancing' && (
                                <>
                                    {isFinite(calcForm.values.monthPay) && (
                                        <StyledCalculator.Condition>
                                            <StyledCalculator.ConditionValue>
                                                {calcForm.values.monthPay} BYN
                                            </StyledCalculator.ConditionValue>
                                            <StyledText grey>Месячный платеж</StyledText>
                                        </StyledCalculator.Condition>
                                    )}
                                    {rate && calculator.show_rate && (
                                        <StyledCalculator.Condition>
                                            <StyledCalculator.ConditionValue>
                                                {rate}%
                                            </StyledCalculator.ConditionValue>
                                            <StyledText grey>Ставка</StyledText>
                                        </StyledCalculator.Condition>
                                    )}
                                    <StyledCalculator.Condition>
                                        <StyledCalculator.ConditionValue>
                                            {calcForm.values.saving} BYN
                                        </StyledCalculator.ConditionValue>
                                        <StyledText grey>Экономия в месяц</StyledText>
                                    </StyledCalculator.Condition>
                                </>
                            )}
                            {calculator.ads_text && (
                                <StyledCalculator.Condition promo>
                                    {calculator.ads_text}
                                    <SvgIcon name="flag" width="24" height="24" />
                                </StyledCalculator.Condition>
                            )}
                        </StyledCalculator.Conditions>
                    )}
                </StyledCalculator.InfoTop>
                {form.values.sum >= calculator.points[0].amount_from &&
                    form.values.sum < calculator.points[calculator.points.length - 1].amount_to && (
                        <StyledCalculator.InfoBottom>
                            <StyledButton width="full" onClick={() => setModalIsOpen(true)}>
                                Отправить&nbsp;заявку
                            </StyledButton>
                            <StyledCalculator.InfoText>
                                Расчеты носят информационно-справочный характер. Полная стоимость
                                рассчитывается индивидуально.
                            </StyledCalculator.InfoText>
                        </StyledCalculator.InfoBottom>
                    )}
            </StyledCalculator.Info>
            </StyledCalculator>
                <CallbackModal
                    isOpen={modalIsOpen}
                    onClose={setModalIsOpen}
                    title="Оставьте заявку на получение денег"
                    text="Укажите свои данные, чтобы мы смогли с вам связаться."
                    onSubmitMethod={(values) => {
                        let title = `Калькулятор`;
                        api.customRecord({
                            ...values,
                            credit_type: `Онлайн-заявка "${title}"`,
                        });
                    }}
                />
        </>
    )
}
