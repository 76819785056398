import { useRef, useEffect } from 'react'
import { StyledModal } from '@/components/styles'
import { Form, Input, Modal } from '@/components'
import api from '@/lib/api'
import Router from 'next/router'
import { useAuth } from '@/contexts/AuthContext'
import cookies from '@/lib/cookies'

export default function ({ isOpen, onClose, form, repeatCode, codeError }) {
    const { setUser } = useAuth()
    const inputRef = useRef()

    function onSubmit() {
        form.submit(async () => {
            const response = await api.auth.login({
                verification_code: form.values.verification_code,
                phone: form.values.phone,
            })
            try {
                setUser(response.data)
                cookies.set('atl_token', response.token, {
                    expires: new Date(Date.now() + 3600 * 1000 * 24 * 365),
                })
                form.setValues({ phone: '', verification_code: '' })
                form.setErrors({})
                onClose(false)
                Router.push('/appeal')
            } catch (e) {
                console.log(e)
                console.log(e.response)
            }
        })
    }

    function onOpen() {
        inputRef.current && inputRef.current.focus()
    }

    useEffect(() => {
        form.values.verification_code.length === 4 && onSubmit()
    }, [form.values])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Введите пароль из СМС"
            text={
                <>
                    Мы отправили СМС-сообщение с паролем на номер{' '}
                    <strong>{form.values.phone}</strong>.
                </>
            }
            onAfterOpen={onOpen}
            medium
        >
            <Form onSubmit={onSubmit}>
                <Input
                    label="Пароль"
                    placeholder="••••"
                    mb="large"
                    maxLength="4"
                    white
                    inputRef={inputRef}
                    {...form.input('verification_code')}
                />
            </Form>
            <StyledModal.Action as="button" onClick={repeatCode}>
                Отправить еще раз
            </StyledModal.Action>
            {codeError.visible && <StyledModal.Error>{codeError.text}</StyledModal.Error>}
        </Modal>
    )
}
