import { StyledCard, StyledTitle, StyledButton } from '@/components/styles'

export default function ({ name, desc, conditions, onClick }) {
    return (
        <StyledCard type="service">
            <StyledTitle small mb="x-small">
                {name}
            </StyledTitle>
            <StyledCard.Text mb grey>
                {desc}
            </StyledCard.Text>
            <StyledCard.Conditions mb>
                {conditions.map((item, idx) => (
                    <StyledCard.Condition key={idx} greyCircle>
                        {item.title}
                    </StyledCard.Condition>
                ))}
            </StyledCard.Conditions>
            <StyledButton width="full" bg="grey" onClick={onClick}>
                Оформить
            </StyledButton>
        </StyledCard>
    )
}
