import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Container = styled.div`
    min-width: 280px;
    width: 100%;
    max-width: 1280px;
    padding: 0 40px;
    ${(p) =>
        p.sizeL &&
        css`
            max-width: 1440px;
        `}
    ${(p) =>
        p.sizeS &&
        css`
            max-width: 874px;
        `}
  margin-left: auto;
    margin-right: auto;

    ${maxWidth(vars.breakpoints.tablet)`
    padding: 0 30px;
  `}

    ${maxWidth(vars.breakpoints.mTablet)`
    padding: 0 20px;
  `}
`

export default Container
