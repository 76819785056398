import { useMemo, useRef, useState } from 'react'
import {
    StyledMain,
    StyledContainer,
    StyledSlider,
    StyledTitle,
    StyledH1,
    StyledButton,
} from '@/components/styles'
import { SliderArrows, CallbackModal } from '@/components'
import Slider from 'react-slick'
import Link from 'next/link'
import api from '@/lib/api'

export default function ({ blockRef, blocks }) {
    const sliderRef = useRef()
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const settings = useMemo(
        () => ({
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 7300,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                    },
                },
            ],
        }),
        [],
    )

    return (
        <StyledMain.Section dark dottedBackground ref={blockRef}>
            <StyledH1 hidden>
                Центр финансовых консультаций
            </StyledH1>
            <StyledSlider noListSettings main>
                <Slider {...settings} ref={sliderRef}>
                    {blocks.map((block) => (
                        <StyledContainer key={block.id}>
                            <StyledMain main>
                                <StyledMain.Img dark main>
                                    <picture>
                                        <source
                                            media="(max-width: 768px)"
                                            srcset={block.image.banner_300}
                                        />
                                        <img src={block.image.banner_530} alt="" />
                                    </picture>
                                </StyledMain.Img>
                                <StyledMain.Content large main>
                                    <StyledMain.Top>
                                        <StyledTitle large mb="medium" white>
                                            {block.title}
                                        </StyledTitle>
                                        <StyledMain.Text white>{block.description}</StyledMain.Text>
                                    </StyledMain.Top>
                                    <StyledMain.Bottom>
                                        {block.type === 'type_index_banner_with_button' ? (
                                            <StyledMain.Buttons>
                                                <StyledButton
                                                    width="full"
                                                    onClick={() => setModalIsOpen(true)}
                                                >
                                                    Оформить
                                                </StyledButton>
                                                {block.button_link && (
                                                    <StyledButton width="full" bg="transparent">
                                                        <Link href={block.button_link}>
                                                            <a />
                                                        </Link>
                                                        Подробнее
                                                    </StyledButton>
                                                )}
                                            </StyledMain.Buttons>
                                        ) : (
                                            <StyledMain.Conditions>
                                                <StyledMain.Condition full>
                                                    {block.fact_1}
                                                </StyledMain.Condition>
                                                <StyledMain.Condition large>
                                                    {block.fact_2}
                                                </StyledMain.Condition>
                                                <StyledMain.Condition large>
                                                    {block.fact_3}
                                                </StyledMain.Condition>
                                            </StyledMain.Conditions>
                                        )}
                                    </StyledMain.Bottom>
                                </StyledMain.Content>
                            </StyledMain>
                        </StyledContainer>
                    ))}
                </Slider>
            </StyledSlider>
            <StyledContainer>
                <StyledMain.SliderArrows>
                    <SliderArrows sliderRef={sliderRef} chart />
                </StyledMain.SliderArrows>
            </StyledContainer>
            <CallbackModal
                isOpen={modalIsOpen}
                onClose={setModalIsOpen}
                title="Оставьте заявку на получение денег"
                text="Укажите свои данные, чтобы мы смогли с вам связаться."
                onSubmitMethod={(values) =>
                    api.customRecord({
                        ...values,
                        credit_type: `Заявка в баннере на главной странице`,
                    })
                }
            />
        </StyledMain.Section>
    )
}
