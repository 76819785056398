import { useState, useEffect } from 'react'
import { StyledHeader, StyledContainer, StyledContacts, StyledNav } from '@/components/styles'
import { Tabs, SvgIcon, Search } from '@/components'
import Link from 'next/link'
import { useRouter } from 'next/router'

const tabs = [
    { id: 'map', title: 'На карте' },
    { id: 'list', title: 'Списком' },
]

export default function ({ isVisible }) {
    const [view, setView] = useState('list')

    const router = useRouter()
    const query = router.query

    const handleChange = (value) => {
        const { search, ...filters } = query
        let newQuery = { ...filters, view: value }

        router.push(
            { pathname: router.pathname, query: newQuery },
            { pathname: location.pathname, query: newQuery },
            { shallow: false },
        )
    }

    useEffect(() => {
        query.view
            ? setView(query.view)
            : router.push(
                  { pathname: router.pathname, query: { view: 'list' } },
                  { pathname: location.pathname, query: { view: 'list' } },
                  { shallow: true },
              )
    }, [query.view])

    return (
        <StyledHeader isVisible contacts>
            <StyledContainer>
                <StyledContacts.Actions isVisible={isVisible || view === 'map'}>
                    <StyledContacts.Action
                        onClick={() => handleChange(view === 'map' ? 'list' : 'map')}
                    >
                        {view === 'map' ? 'Показать списком' : 'Показать на карте'}
                    </StyledContacts.Action>
                    <StyledContacts.Action>
                        <Link href="/mobile-office">
                            <a>Мобильный офис</a>
                        </Link>
                    </StyledContacts.Action>
                </StyledContacts.Actions>
                <StyledHeader.Inner contacts>
                    <StyledHeader.Logo>
                        <Link href="/">
                            <a>
                                <SvgIcon name="logo" />
                            </a>
                        </Link>
                    </StyledHeader.Logo>
                    <Search />
                    <StyledNav contacts>
                        <StyledNav.Item>
                            <StyledNav.ItemTitle link contacts>
                                <Link href="/mobile-office">
                                    <a>Мобильный офис</a>
                                </Link>
                            </StyledNav.ItemTitle>
                        </StyledNav.Item>
                    </StyledNav>
                    <StyledHeader.Types contacts>
                        <Tabs
                            tabs={tabs}
                            tab={view}
                            setTab={handleChange}
                            small
                            contacts
                            mb="none"
                        />
                    </StyledHeader.Types>
                </StyledHeader.Inner>
            </StyledContainer>
        </StyledHeader>
    )
}
