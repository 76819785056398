import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Collapse = styled.div`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 8px;
    transition: all .3s;
    ${p => p.isOpen ? css`
        padding: 0 24px 22px;
    ` : css`
        padding: 0 24px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        padding: ${p => p.isOpen ? "0 16px 16px" : "0 16px"};
        border-radius: 4px;
    `}
`

Collapse.Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${p => p.isOpen ? "22px 0 20px" : "22px 0"};
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    cursor: pointer;
    &:after {
        content: "";
        flex-shrink: 0;
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: ${p => p.isOpen ? 0 : "5px"};
        margin-left: 10px;
        border-right: 2px solid ${p => p.isOpen ? vars.theme.red : vars.theme.darkGrey};
        border-bottom: 2px solid ${p => p.isOpen ? vars.theme.red : vars.theme.darkGrey};
        transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
        transition: all .3s;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: ${p => p.isOpen ? "16px 0 8px" : "16px 0"};
        font-size: 16px;
        line-height: 24px;
    `}
`

Collapse.Content = styled.div`
    overflow: hidden;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    height: ${p => p.isOpen ? "auto" : "0"};
    transition: ${p => p.isOpen ? "opacity 0.25s ease 0.1s, visibility 0s ease 0.1s" : "opacity 0s ease 0.1s, visibility 0s ease 0.1s"}, height .3s;   
`

export default Collapse
