import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import StyledText from './StyledText'
import StyledTitle from './StyledTitle'

const Appeal = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - ${p => p.fullHeight ? "132px" : "156px"});
    ${maxWidth(vars.breakpoints.tablet)`
        min-height: calc(100vh - ${p => p.fullHeight ? "30px" : "54px"});
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.fullHeight && css`
            position: fixed;
            top: 24px;
            left: 20px;
            right: 20px;
            bottom: 0;
            height: auto;
            min-height: auto;
        `}
    `}
`

Appeal.Add = styled.div`
    font-weight: 400;
    color: ${vars.theme.red};
    cursor: pointer;
    svg {
        margin-right: 10px;
    }
    a {
        display: flex;
        align-items: center; 
    }
    transition: .3s;
    &:hover {
        opacity: .8;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.isMobileVisible ? css`
            position: fixed;
            bottom: 20px;
            left: 50%;
            width: 196px;
            transform: translateX(-50%);
            background: ${vars.theme.red};
            border-radius: 32px;
            color: #fff;
            font-weight: 500;
            z-index: 1;
            a {
                display: block;
                padding: 16px 27px;
            }
            svg {
                display: none;
            }
        ` : css`
            display: none;
        `}
        
    `}
`

Appeal.Cards = styled.div`
    ${p => p.mb && "margin-bottom: 48px;"}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.mb && "margin-bottom: 32px;"}
    `}
`
Appeal.Card = styled.div`
    position: relative;
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 8px;
    ${p => p.new ? css`
        padding: 32px;
    ` : css`
        display: flex;
        padding: 24px;
    `}
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    
    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
    `}
`

Appeal.CardIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-right: 24px;
    background: ${vars.theme.lightGrey};
    border-radius: 32px;
    ${maxWidth(vars.breakpoints.mTablet)`
        display: none;
    `}
`

Appeal.CardTheme = styled(StyledText)`
    margin-bottom: 4px;
`

Appeal.CardContent = styled.div``

Appeal.CardDesc = styled.div`
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 16px;
    `}
`

Appeal.Status = styled.div`
    grid-area: status;
    width: max-content;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    background: ${p => p.color ? rgba(p.color, .1) : "#fff"};
    color: ${p => p.color};
    font-weight: 400;
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 12px;
        line-height: 16px;
    `}
`

Appeal.Buttons = styled.div`
    display: grid;
    grid-template-columns: 220px 300px;
    gap: 16px;
    margin-top: 32px;
    ${maxWidth(vars.breakpoints.lMobile)`
        grid-template-columns: 1fr;
        margin-top: 16px;
    `}
`

Appeal.Top = styled.div`
    display: flex;
    ${p => p.border ? css`
        padding-bottom: 32px;
        border-bottom: 1px solid ${vars.theme.outlineGrey};
    ` : css`
        margin-bottom: 32px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.border ? css`
            padding-bottom: 20px;
        ` : css`
            margin-bottom: 20px;
        `}
    `}
`

Appeal.Back = styled.div`
    display: block;
    margin-right: 16px;
    cursor: pointer;
    transition: all .3s;
    &:hover {
        transform: translateX(-4px);
    }
    ${maxWidth(vars.breakpoints.lMobile)`
        
    `}
`

Appeal.Desc = styled.div`
    flex: 1;
    display: grid;
    grid-template-areas: 'title number' 'status number';
    justify-content: space-between;
    gap: 8px;
    ${StyledTitle} {
        grid-area: title
    }
    ${maxWidth(vars.breakpoints.tablet)`
        grid-template-areas: 'title' 'number' 'status';
    `}
`

Appeal.Number = styled.div`
    grid-area: number;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.lMobile)`
        
    `}
`

Appeal.ListWrapper = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
`

Appeal.List = styled.div`
    font-size: 0px;
    line-height: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0px;
    overflow-y: auto;
    width: 100%;
    overscroll-behavior: contain contain;
`

Appeal.ListInner = styled.div`
    padding: 24px;
    font-size: 14px;
    line-height: 17px;
`

Appeal.Form = styled.div`
    ${p => p.mb && "margin-bottom: 32px;"}
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 4px;
    textarea {
        border: none;
    }
`

Appeal.FormBottom = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    button {
        margin-right: 24px;
    }
    ${maxWidth(vars.breakpoints.mTablet)`
        button {
            width: 140px;
        }
    `}
`

Appeal.FormFiles = styled.div`
    padding: 0 16px;
`

export default Appeal
