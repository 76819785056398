import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'
import {StyledText} from '@/components/styles'

const ProductDesc = styled.div`
    padding-top: 24px;
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 0;
    `}
`

ProductDesc.Documents = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
    ${maxWidth(vars.breakpoints.tablet)`
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: 1fr;
        gap: 20px;
    `}
`

ProductDesc.Document = styled.div`
    position: relative;
    display: grid;
    grid-template-areas: 'icon title' 'icon size';
    grid-template-columns: 48px 1fr;
    grid-template-rows: max-content;
    gap: 4px 16px;
    a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`

ProductDesc.DocumentIcon = styled.div`
    grid-area: icon;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: ${vars.theme.lightGrey};
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.mTablet)`
    `}
`

ProductDesc.DocumentTitle = styled(StyledText)`
    grid-area: title;
    ${maxWidth(vars.breakpoints.mTablet)`
    `}
`

ProductDesc.DocumentSize = styled.div`
    grid-area: size;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.mTablet)`
    `}
`

export default ProductDesc
