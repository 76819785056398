import { StyledButton } from '@/components/styles'
import { Modal } from '@/components'

export default function ({ isOpen, onClose }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Ожидание подтверждения"
            text="Ваша заявка на регистрацию была успешно сформирована. Наш сотрудник проверит ваши данные в ближайшее время. После подтверждения вам придет SMS-сообщение."
            medium
        >
            <StyledButton width="full" onClick={() => onClose(false)}>
                Продолжить
            </StyledButton>
        </Modal>
    )
}
