import { StyledInput } from '@/components/styles'

export default function ({ children, label, error, mb, range, withError }) {
    return (
        <StyledInput.Wrapper mb={mb}>
            {label && <StyledInput.Label range={range}>{label}</StyledInput.Label>}
            {children}
            {withError && error && <StyledInput.Error>{error}</StyledInput.Error>}
        </StyledInput.Wrapper>
    )
}
