import { useState, useEffect } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledPromo,
    StyledTitle,
    StyledButton,
} from '@/components/styles'
import { Form, PhoneInput } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'

const initialValues = { phone: '' }

export default function ({}) {
    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)

    function onSubmit() {
        form.submit(async () => {
            await api.creditAttorney({ ...form.values, url: window.location.href })
            form.setErrors({})
            setSuccess(true)
        })
    }

    useEffect(() => {
        success && setSuccess(false)
    }, [form.values.phone])

    return (
        <StyledSection pt="small" pb="small">
            <StyledContainer>
                <StyledPromo.Lawyer>
                    <StyledPromo dark>
                        <StyledPromo.Image lawyer>
                            <img src="/images/lawyer.png" alt="" />
                        </StyledPromo.Image>
                        <StyledPromo.Content large>
                            <StyledTitle mb="small" white>
                                «Кредитный адвокат» станет самым надежным помощником
                            </StyledTitle>
                            <StyledPromo.Text white>
                                Укажите номер телефона, чтобы наш менеджер смог подробнее рассказать
                                вам про преимущества данной услуги.
                            </StyledPromo.Text>
                            <Form onSubmit={onSubmit}>
                                <StyledPromo.Form>
                                    <PhoneInput
                                        noRightBorderRadius
                                        mb="none"
                                        placeholder="+375"
                                        {...form.input('phone')}
                                    />
                                    <StyledButton
                                        noLeftBorderRadius
                                        width="full"
                                        isLoading={form.isLoading}
                                        disabled={form.isLoading}
                                        success={success}
                                        noMobileLoading
                                    >
                                        {success ? 'Отправлено' : 'Отправить'}
                                    </StyledButton>
                                </StyledPromo.Form>
                            </Form>
                        </StyledPromo.Content>
                    </StyledPromo>
                </StyledPromo.Lawyer>
            </StyledContainer>
        </StyledSection>
    )
}
