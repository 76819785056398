import styled, { css } from 'styled-components'
import { maxWidth, minWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const SEO = styled.div`
    p {
      margin-bottom: .9rem;  
    }
`
SEO.Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    position: relative;
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: 1fr;
        max-height: 100vh;
        overflow: hidden;
        &::before {
            background: linear-gradient(180deg,#00d4ff00,#fff);
            bottom: 0;
            content: "";
            height: 50px;
            position: absolute;
            width: 100%;
            z-index: 10;
        }
    `}
    &.is-open {
        max-height: none;
        overflow: initial;
        &::before {
            content: none;
        }
    }
`
SEO.Subtitle = styled.h3`
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    font-weight: 300;
`
SEO.List = styled.ul`
    list-style-type: none!important;
    padding-left: 0!important;
`
SEO.ListItem = styled.li`
    position: relative;
    padding-left: 26px;
    margin-bottom: .7rem;
    &:after {
        content: "";
        position: absolute;
        left: 5px;
        top: .5rem;
        display: block;
        width: 8px;
        height: 6px;
        margin-bottom: 2px;
        border-left: 2px solid rgb(104, 134, 159);
        border-bottom: 2px solid rgb(104, 134, 159);
        transform: rotate(-45deg);
    }
`
SEO.Time = styled.div`
    background-color: rgb(251 244 232);
    border-radius: 20px;
    gap: 10px;
    padding-left: 8px;
    padding-right: 8px;
    height: 26px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: fit-content;
    align-items: center;
    font-size: .8rem;
    &:before {
        content: "";
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEwIiBoZWlnaHQ9IjUxMCIgdmlld0JveD0iMCAwIDUxMCA1MTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMjU1QzAuNSAxMTUuMDI2IDExNS4wMjYgMC41IDI1NSAwLjVDMzk0Ljk3NCAwLjUgNTA5LjUgMTE1LjAyNiA1MDkuNSAyNTVDNTA5LjUgMzk0Ljk3NCAzOTQuOTc0IDUwOS41IDI1NSA1MDkuNUMxMTUuMDI2IDUwOS41IDAuNSAzOTQuOTc0IDAuNSAyNTVaTTUwLjUgMjU1QzUwLjUgMzY3LjQ3NiAxNDIuNTI0IDQ1OS41IDI1NSA0NTkuNUMzNjcuNDc2IDQ1OS41IDQ1OS41IDM2Ny40NzYgNDU5LjUgMjU1QzQ1OS41IDE0Mi41MjQgMzY3LjQ3NiA1MC41IDI1NSA1MC41QzE0Mi41MjQgNTAuNSA1MC41IDE0Mi41MjQgNTAuNSAyNTVaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiIHN0cm9rZT0iYmxhY2siLz4KPHBhdGggZD0iTTI2Ny4yNSAyNjAuMVYyNjAuMzgzTDI2Ny40OTMgMjYwLjUyOUwzODEuODA5IDMyOS4xMTlMMzYxLjkzNiAzNjEuNDEyTDIzMCAyODAuMjIxVjEyOEgyNjcuMjVWMjYwLjFaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiIHN0cm9rZT0iYmxhY2siLz4KPC9zdmc+');
        width: 1.2rem;
        aspect-ratio: 1/1;
        background-size: contain;
    }
`
SEO.More = styled.div`
    margin-top: 8px;
    text-align: center;
    width: 100%;
    span {
        background: #fff;
        border: 1px solid rgb(241, 80, 69);
        border-radius: 20px;
        color: rgb(241, 80, 69);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        padding: 0 30px 0 18px;
        position: relative;
        transition: .2s linear;
        vertical-align: top;
        &:after {
            border-color: #fff;
            border-bottom-color: rgb(255, 255, 255);
            border-left-color: rgb(255, 255, 255);
            border-bottom: 1px solid rgb(241, 80, 69);
            border-left: 1px solid rgb(241, 80, 69);
            content: "";
            display: block;
            height: 8px;
            margin-top: -6px;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: rotate(-45deg);
            transition: border-color .2s linear;
            width: 8px;
        }
        &.is-open::after {
            top: 66%;
            transform: rotate(135deg);
        }
        ${minWidth(vars.breakpoints.mTablet)`
            display: none;
        `}
    }
`

export default SEO