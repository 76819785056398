import { maxWidth } from '@/assets/styles/mixins.css.js'
import styled, { css } from 'styled-components'
import vars from '@/assets/styles/vars'

const Section = styled.section`
  ${(props) =>
      props.overflowXHidden &&
      css`
          overflow-x: hidden;
      `}
      
  ${(props) =>
      props.relative &&
      css`
          position: relative;
          z-index: 1;
      `}
      
  ${(props) =>
      props.mobileNone &&
      css`
        ${maxWidth(vars.breakpoints.mTablet - 1)`
            display: none;
        `}
      `}
    
  ${({ pt }) => {
      switch (pt) {
          case 'large':
              return css`
                  padding-top: 250px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-top: 200px;
                  `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-top: 48px;
                  `}
              `
          case 'medium':
              return css`
                  padding-top: 80px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-top: 60px;
                  `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-top: 32px;
                  `}
              `
          case 'small':
              return css`
                  padding-top: 50px;
                  
                  ${maxWidth(vars.breakpoints.tablet)`
                      padding-top: 30px;
                    `}
          
                    ${maxWidth(vars.breakpoints.mTablet)`
                    padding-top: 24px;
                  `}
              `
          case 'x-small':
              return css`
                  padding-top: 24px;
          
                    ${maxWidth(vars.breakpoints.mTablet)`
                    padding-top: 16px;
                  `}
              `
          case 'none':
              return css`
                  padding-top: 0;
              `
          default:
              return css`
                  padding-top: 100px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-top: 60px;
                `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-top: 48px;
                `}
              `
      }
  }}

  ${({ pb }) => {
      switch (pb) {
          case 'large':
              return css`
                  padding-bottom: 250px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-bottom: 200px;
                  `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-bottom: 48px;
                  `}
              `
          case 'medium':
              return css`
                  padding-bottom: 80px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-bottom: 60px;
                  `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-bottom: 32px;
                  `}
              `
          case 'small':
              return css`
                  padding-bottom: 50px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-bottom: 30px;
                  `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-bottom: 24px;
                  `}
              `
          case 'x-small':
              return css`
                  padding-bottom: 24px;
          
                    ${maxWidth(vars.breakpoints.mTablet)`
                    padding-bottom: 16px;
                  `}
              `
          case 'none':
              return css`
                  padding-bottom: 0;

                  &:last-child {
                      padding-bottom: 0;
                  }
              `
          default:
              return css`
                  padding-bottom: 100px;

                  ${maxWidth(vars.breakpoints.tablet)`
                    padding-bottom: 60px;
                  `}

                  ${maxWidth(vars.breakpoints.mTablet)`
                    padding-bottom: 48px;
                  `}
              `
      }
  }}
  
  ${(props) =>
    props.greyBackground &&
    css`
          background-color: ${vars.theme.lightGrey};
      `}

  ${(props) =>
      props.dottedBackground &&
      css`
          position: relative;
          background-color: ${vars.theme.lightGrey};
          background-image: radial-gradient(#fff 10%, rgba(255,255,255,0) 20%);
          background-size: 25px 25px;
          
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(120deg, ${vars.theme.lightGrey} 0%, rgba(255,255,255,0) 90%);
            border-radius: 8px;
        }
      `}
`

Section.Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 24px;
        flex-wrap: wrap;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.tabletColumn && "display: block;"}
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        button {
            display: none;
        }
    `}
`

export default Section
