import styled  from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {StyledText} from '@/components/styles'

const Useful = styled.div`
    
`

Useful.Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 24px 0;
    background: ${vars.theme.lightGrey};
    border: 1px solid ${vars.theme.outlineGrey};
    border-radius: 8px;
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 24px;
    `}
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 16px;
        width: 280px;
        max-width: 80vw;
    `}
`

Useful.Img = styled.div`
    max-width: 390px;
    margin: 0 auto;
    padding: 5px 5px 0;
    background: #fff;
    border-radius: 10px 10px 0 0;
    img {
        max-height: 207px;
        border-radius: 10px 10px 0 0;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        order: -1;
        max-width: 300px;
        margin-bottom: 24px;
        padding: 5px;
        border-radius: 10px;
        img {
            border-radius: 10px;
        }
  `}
`

Useful.Text = styled(StyledText)`
    flex: 1;
    max-width: 390px;
    margin-bottom: 32px;
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 24px;
    `}
`

Useful.Link = styled.div`
    display: flex;
    align-items: center;
    a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    svg {
        position: absolute;
        top: 28px;
        right: 28px;
    }
    span {
        display: none;
        color: ${vars.theme.darkGrey};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        svg {
            position: static;
            margin-right: 10px;
        }
        span {
            display: block;
        }
    `}
`

export default Useful
