import styled, {css} from 'styled-components'
import vars from '@/assets/styles/vars'
import {maxWidth} from '@/assets/styles/mixins.css.js'

const Checkbox = styled.label`
    position: relative;
    display: flex;
    padding-left: 36px;
    cursor: pointer;
    &:before {
        position: absolute;
        left: 0;
        top: -1px;
        display: inline-block;
        content: '';
        width: 24px;
        height: 24px;
        background: #fff;
        border: 1px solid ${p => p.error ? vars.theme.red : vars.theme.outlineGrey};
    }
    &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 9px;
        width: 7px;
        height: 12px;
        border-bottom: 2px solid ${vars.theme.red};
        border-right: 2px solid ${vars.theme.red};
        transform: rotate(40deg);
        opacity: ${p => p.checked ? 1 : 0};
        transition: .2s;
    }
`

Checkbox.Input = styled.input`
    position: absolute;
    left: -9999px;
`

Checkbox.Title = styled.div`
    ${p => p.grey && `color: ${vars.theme.darkGrey};`}
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.grey && css`
            font-size: 13px;
            line-height: 20px;
        `}
    `}
`

export default Checkbox
