import React, { useContext, useState, useEffect } from 'react'
import { useCity } from '@/contexts/CityContext'
import api from '@/lib/api'

const InfoContext = React.createContext()

export function InfoProvider({ children, articles, massMedia, categories: initialCategories }) {
    const { city } = useCity()
    const [categories, setCategories] = useState(initialCategories)
    const [social, setSocial] = useState([])

    const [testIsVisible, setTestIsVisible] = useState(false)
    const [mayBeVisible, setMayBeVisible] = useState(true)

    const fetchCategories = async () => {
        const data = await api.categories({ city: city.slug })
        setCategories(data)
    }

    const fetchSocial = async () => {
        const { data } = await api.social()
        setSocial(data)
    }

    useEffect(() => {
        fetchCategories()
    }, [city])

    useEffect(() => {
        fetchSocial()
    }, [])

    return (
        <InfoContext.Provider
            value={{
                articles: articles.data,
                massMedia: massMedia.data,
                categories: categories,
                articlesPages: articles.meta.last_page,
                massMediaPages: massMedia.meta.last_page,
                social: social,
                testIsVisible: testIsVisible,
                mayBeVisible: mayBeVisible,
                setTestIsVisible: setTestIsVisible,
                setMayBeVisible: setMayBeVisible,
            }}
        >
            {children}
        </InfoContext.Provider>
    )
}

export function useInfo() {
    return useContext(InfoContext)
}
