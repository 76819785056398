import { useMemo, useRef, useState } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock } from '@/components'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Lightbox } from '@/components'

export default function ({ title, images = [] }) {
    const sliderRef = useRef()
    const [photoIndex, setPhotoIndex] = useState(null)

    const settings = useMemo(
        () => ({
            slidesToShow: images.length < 6 ? images.length : 6,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            dots: true,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: images.length < 5 ? images.length : 5,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: images.length < 4 ? images.length : 4,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                    },
                },
            ],
        }),
        [images],
    )

    return (
        <StyledSection pt="small">
            <StyledContainer>
                <SliderBlock
                    title={title}
                    length={images.length}
                    count="6"
                    sliderRef={sliderRef}
                    whiteContent
                    images
                >
                    <Slider {...settings} ref={sliderRef}>
                        {images.map((item, idx) => (
                            <Sert key={idx} onClick={() => setPhotoIndex(idx)}>
                                <img src={item.small} alt="" />
                            </Sert>
                        ))}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
            <Lightbox
                images={images.map((item) => item.medium)}
                photoIndex={photoIndex}
                onChange={setPhotoIndex}
            />
        </StyledSection>
    )
}

const Sert = styled.div`
    position: relative;
    display: block !important;
    width: fit-content !important;
    margin: 0 auto;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.4);
        transition: 0.3s;
    }
    &:hover {
        &:after {
            background: transparent;
        }
    }
`
