import { useState, useEffect } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledAppeal,
    StyledTitle,
    StyledText,
    StyledButton,
} from '@/components/styles'
import { SvgIcon } from '@/components'
import Link from 'next/link'
import moment from 'moment'
import { statuses } from '@/lib/config'
import { useSocket } from '@/contexts/SocketContext'
import { useAuth } from '../../contexts/AuthContext'

export default function ({ list: initialList, history }) {
    const { data, setData } = useSocket()

    const [list, setList] = useState(initialList)

    useEffect(() => {
        if (data && data.dialog) {
            const { dialog: newDialog } = data

            setList([
                { ...list.find((item) => item.id == newDialog.id), status: newDialog.status },
                ...list.filter((item) => item.id != newDialog.id),
            ])
            setData(null)
        }
    }, [data])

    return (
        <StyledSection pt="small">
            <StyledContainer>
                <StyledSection.Top tabletColumn>
                    <StyledTitle mb="none">Текущие обращения</StyledTitle>
                    <StyledAppeal.Add isMobileVisible={list.length}>
                        <Link href="/appeal/new">
                            <a>
                                <SvgIcon name="add" width={32} height={32} />
                                <StyledText>Новое обращение</StyledText>
                            </a>
                        </Link>
                    </StyledAppeal.Add>
                </StyledSection.Top>
                <StyledAppeal.Cards mb={history.length}>
                    {list.length ? (
                        list.map((item) => <Appeal key={item.id} {...item} />)
                    ) : (
                        <StyledAppeal.Card new>
                            <StyledTitle small mb="small">
                                Оставьте {history.length > 0 ? 'новое' : 'свое первое'} обращение
                            </StyledTitle>
                            <StyledText grey style={{ maxWidth: '480px' }}>
                                Если у вас возникли какие-либо вопросы перейдите в раздел «Вопросы и
                                ответы» или создайте новое обращение. Наши специалисты в кратчайшие
                                сроки обработают вашу заявку и ответят на ваши вопрос.
                            </StyledText>
                            <StyledAppeal.Buttons>
                                <StyledButton width="full">
                                    <Link href="/appeal/new">
                                        <a />
                                    </Link>
                                    Создать обращение
                                </StyledButton>
                                <StyledButton width="full" bg="grey">
                                    <Link href="/questions">
                                        <a />
                                    </Link>
                                    Перейти в «Вопросы и ответы»
                                </StyledButton>
                            </StyledAppeal.Buttons>
                        </StyledAppeal.Card>
                    )}
                </StyledAppeal.Cards>
                {history.length > 0 && (
                    <>
                        <StyledTitle>История обращений</StyledTitle>
                        <StyledAppeal.Cards>
                            {history.map((item) => (
                                <Appeal key={item.id} {...item} />
                            ))}
                        </StyledAppeal.Cards>
                    </>
                )}
            </StyledContainer>
        </StyledSection>
    )
}

const Appeal = ({ title, id, created_at, status: initStatus }) => {
    const status = statuses.find((status) => status.key === initStatus)

    return (
        <StyledAppeal.Card>
            <StyledAppeal.CardIcon>
                <SvgIcon
                    name={initStatus == 'resolved' ? 'doc-check' : 'doc'}
                    width={16}
                    height={20}
                />
            </StyledAppeal.CardIcon>
            <StyledAppeal.CardContent>
                <StyledAppeal.CardTheme>{title}</StyledAppeal.CardTheme>
                <StyledAppeal.CardDesc>
                    №{id} создан {moment(created_at).format('DD.MM.YYYY')}
                </StyledAppeal.CardDesc>
                <StyledAppeal.Status color={status?.color}>{status?.title}</StyledAppeal.Status>
            </StyledAppeal.CardContent>
            <Link href="/appeal/[id]" as={`/appeal/${id}`}>
                <a />
            </Link>
        </StyledAppeal.Card>
    )
}
