import styled, {css} from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Footer = styled.footer`
    border-top: 1px solid ${p => p.darkBack ? vars.theme.darkGrey : vars.theme.outlineGrey};
    background: ${p => p.darkBack ? "#09253D" : vars.theme.lightGrey};
    ${p => p.darkBack && "color: #fff;"}
`

Footer.Inner = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    ${maxWidth(vars.breakpoints.mMobile)`
        flex-direction: column-reverse;
        align-items: inherit;
    `}
`

Footer.Top = styled.div`
    padding: 8px 0;
    background: ${p => p.darkBack ? "#112F43" : "#fff"};
    ${p => !p.darkBack && `border-bottom: 1px solid  ${vars.theme.outlineGrey}`};
    ${maxWidth(vars.breakpoints.lMobile)`
        ${p => !p.darkBack && `background: ${vars.theme.lightGrey};`}
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        padding: 0;
    `}
`

Footer.Bottom = styled.div`
    padding: 28px 0 ${p => p.large && "130px"};
    border-top: 1px solid ${p => p.dark ? vars.theme.darkGrey : vars.theme.outlineGrey};
    font-size: 14px;
    line-height: 20px;
    color: ${p => p.dark ? vars.theme.grey : vars.theme.darkGrey};
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 20px 0 ${p => p.large && "122px"};
        font-size: 12px;
        line-height: 18px;
        ${Footer.Inner} {
            display: block;
        }
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 20px 0 ${p => p.large && "25px"};
    `}
`

Footer.Nav = styled.div`
    padding: 32px 0 8px;
    ${maxWidth(vars.breakpoints.lMobile)`
        padding: 8px 0;
        display: none;
    `}
`

Footer.NavInner = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 24px;
    ${maxWidth(vars.breakpoints.tablet)`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${maxWidth(vars.breakpoints.lMobile)`
        grid-template-columns: 1fr;
        gap: 0;
    `}
`

Footer.NavItem = styled.div`
    margin-bottom: 24px;
    ${maxWidth(vars.breakpoints.lMobile)`
        margin-bottom: 0;
        border-bottom: 1px solid ${p => p.dark ? vars.theme.darkGrey : vars.theme.outlineGrey};
    `}
`

Footer.NavColumn = styled.div`
    ${maxWidth(vars.breakpoints.lMobile)`
        &:last-child {
            ${Footer.NavItem} {
                &:last-child {
                    border: none;
                }
            }
        }
    `}
`


Footer.NavTitle = styled.div`
    ${p => p.mb && "margin-bottom: 12px;"}
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${p => p.white ? "#fff" : "#363940"};
    &:hover {
        opacity: 0.8;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.mb && "margin-bottom: 8px;"}
        font-size: 12px;
        line-height: 20px;
    `}
    ${maxWidth(vars.breakpoints.lMobile)`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        a {
            flex: 1;
            display: block;
            padding: 12px 0;
        }
        ${p => p.arrow && css`
            &:after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                margin-bottom: ${p.isOpen ? 0 : "5px"};
                margin-left: 30px;
                border-right: 2px solid ${p.isOpen ? vars.theme.red : vars.theme.darkGrey};
                border-bottom: 2px solid ${p.isOpen ? vars.theme.red : vars.theme.darkGrey};
                transform: ${p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
                transition: all .3s;
            }
        `}
        
    `}
`

Footer.NavSubmenu = styled.div`
    ${maxWidth(vars.breakpoints.lMobile)`
        overflow: hidden;
        margin-bottom: ${p => p.isOpen ? "12px" : 0};
        opacity: ${p => p.isOpen ? 1 : 0};
        visibility: ${p => p.isOpen ? "visible" : "hidden"};
        height: ${p => p.isOpen ? "auto" : "0"};
        transition: ${p => p.isOpen ? "opacity 0.25s ease 0.1s, visibility 0s ease 0.1s" : "opacity 0s ease 0.1s, visibility 0s ease 0.1s"}, height .3s;
    `}
`

Footer.NavSubmenuItem = styled.div`
    &:not(:last-child) {
        margin-bottom: 12px;
    }
    font-size: 14px;
    line-height: 20px;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        font-size: 12px;
    `}
`

Footer.Contacts = styled.div`
    position: relative;
    padding: 10px 26px 10px 0;
    ${maxWidth(vars.breakpoints.lMobile)`
        padding: 20px 26px 20px 0;
    `}
`

Footer.ContactsTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: ${p => p.white ? "#fff" : "#363940"};
    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: ${p => p.isOpen ? "calc(50% - 7px)" : "calc(50% - 3px)"};
        display: block;
        width: 8.5px;
        height: 8.5px;
        border-right: 1px solid ${p => p.white ? "#fff" : p.isOpen ? vars.theme.red : vars.theme.darkGrey};
        border-bottom: 1px solid ${p => p.white ? "#fff" : p.isOpen ? vars.theme.red : vars.theme.darkGrey};
        transform: ${p => p.isOpen ? "rotate(45deg)" : "rotate(-135deg)"};
        transition: .3s;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        font-size: 16px;
        line-height: 24px;
    `}
`

Footer.ContactsDropdown = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    width: 232px;
    padding: 16px;
    background: ${p => p.dark ? "#112F43" : "#FFFFFF"};
    border: 1px solid ${p => p.dark ? vars.theme.darkGrey : vars.theme.outlineGrey};
    box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 4px;
    color: ${p => p.dark ? "#fff" : "#363940"};
    z-index: 1;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    pointer-events: ${p => p.isOpen ? "all" : "none"};
    transition: ${p => p.isOpen ? "visibility 0s, opacity 0.35s ease" : "visibility 0s 0.35s, opacity 0.35s ease"};
    a {
        display: block;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    ${maxWidth(vars.breakpoints.tablet)`
        top: calc(100% - 10px);
    `}
`

Footer.Social = styled.div`
    display: flex;
  flex-wrap: wrap;
    ${maxWidth(vars.breakpoints.mMobile)`
        margin: 0 -20px;
        padding: 20px;
        border-top: 1px solid ${p => p.dark ? vars.theme.darkGrey : vars.theme.outlineGrey};
    `}
`

Footer.SocialItem = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${p => p.dark ? "#09253D" : "#fff"};
    box-shadow: 0px 4px 22px rgba(61, 75, 86, 0.08);
    border-radius: 50%;
    transition: all 0.3s;
    &:not(:last-child) {
        margin-right: 16px;
    }
    &:hover {
        transform: scale(1.1);
    }
`

Footer.Copyright = styled.div`
    flex: 1;
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 14px;
    `}
`

Footer.Phone = styled.a`
    white-space: nowrap;
`
Footer.Arrow = styled.div`
    position: fixed;
    width: 73px;
    height: 73px;
    background: rgb(241, 80, 69);
    box-shadow: 0px 2px 5.7px rgba(0, 0, 0, 0.15);
    right: 24px;
    bottom: 112px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
` 

Footer.Dev = styled.a`
    display: flex;
    align-items: center;
    transition: all 0.3s;
    span {
        margin-right: 8px;
    }
    &:hover {
        opacity: 0.8;
    }
`

export default Footer
