import {
    StyledSection,
    StyledContainer,
    StyledAdvantages,
    StyledTitle,
    StyledText,
} from '@/components/styles'
import { SvgIcon } from '@/components'

export default function ({ pt, pb, blocks = [], title, subtitle, whiteContent }) {
    return (
        <StyledSection pt={pt} pb={pb}>
            <StyledContainer>
                <StyledAdvantages.Title white={whiteContent}>{title}</StyledAdvantages.Title>
                <StyledAdvantages>
                    <StyledTitle small white={whiteContent}>
                        {subtitle}
                    </StyledTitle>
                    <StyledAdvantages.Inner>
                        {blocks?.length &&
                            blocks.map((item, idx) => (
                                <StyledAdvantages.Item key={idx}>
                                    <StyledAdvantages.ItemIcon opacity={whiteContent} grey>
                                        {item.icon && (
                                            <img
                                                src={
                                                    item.icon.icon ??
                                                    `/icons/${item.icon.title}.svg`
                                                }
                                                alt=""
                                                width="32"
                                                height="32"
                                            />
                                        )}
                                    </StyledAdvantages.ItemIcon>
                                    <StyledAdvantages.ItemContent>
                                        <StyledAdvantages.ItemTitle white={whiteContent}>
                                            {item.title}
                                        </StyledAdvantages.ItemTitle>
                                        <StyledText grey={!whiteContent} lightGrey={whiteContent}>
                                            {item.description}
                                        </StyledText>
                                    </StyledAdvantages.ItemContent>
                                </StyledAdvantages.Item>
                            ))}
                    </StyledAdvantages.Inner>
                </StyledAdvantages>
            </StyledContainer>
        </StyledSection>
    )
}
