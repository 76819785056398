import styled  from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const CityChange = styled.div`
`

CityChange.Dropdown = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    width: 299px;
    padding: 24px;
    background: ${p => p.dark ? "#112F43" : "#fff"};
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 6px;
    z-index: 6;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    pointer-events: ${p => p.isOpen ? "all" : "none"};
    transition: ${p => p.isOpen ? "visibility 0s, opacity 0.35s ease" : "visibility 0s 0.35s, opacity 0.35s ease"};
    ${maxWidth(vars.breakpoints.tablet - 1)`
        padding: 16px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        width: 250px;
    `}
`

CityChange.Title = styled.div`
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    span {
        color: ${p => p.white ? "#fff" : vars.theme.red};
    }
    ${maxWidth(vars.breakpoints.tablet - 1)`
        margin-bottom: 16px;
    `}
`

CityChange.Buttons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`

CityChange.Cities = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 8px;
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: repeat(2, 1fr);
    `}
`

CityChange.City = styled.div`
    cursor: pointer;
    transition: .3s;
    &:hover {
        opacity: .8;
    }
`

export default CityChange
