import { useState } from 'react'
import { StyledSection, StyledContainer, StyledVacancies, StyledTitle } from '@/components/styles'
import { Vacancy } from '@/components'
import OutsideClickHandler from 'react-outside-click-handler'

export default function ({ blockRef, vacancies }) {
    const [activeRegion, setActiveRegion] = useState(vacancies[0])
    const [isOpen, setIsOpen] = useState(false)

    return (
        <StyledSection pt="medium" pb="medium" greyBackground ref={blockRef}>
            <StyledContainer>
                <StyledTitle>Текущие вакансии</StyledTitle>
                <StyledVacancies>
                    <StyledVacancies.Regions>
                        {vacancies.map((item) => (
                            <StyledVacancies.Region
                                key={item.id}
                                active={item.id === activeRegion.id}
                                onClick={() => setActiveRegion(item)}
                            >
                                <StyledVacancies.RegionTitle>
                                    {item.title}
                                </StyledVacancies.RegionTitle>
                                <StyledVacancies.RegionCount>
                                    {item.vacancies_count}
                                </StyledVacancies.RegionCount>
                            </StyledVacancies.Region>
                        ))}
                    </StyledVacancies.Regions>
                    <StyledVacancies.Regions mobile>
                        <OutsideClickHandler onOutsideClick={() => isOpen && setIsOpen(!isOpen)}>
                            <StyledVacancies.ChoosenRegion
                                isOpen={isOpen}
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                {activeRegion.title}
                            </StyledVacancies.ChoosenRegion>
                            <StyledVacancies.RegionsDropdown isOpen={isOpen}>
                                {vacancies.map((item) => (
                                    <StyledVacancies.Region
                                        key={item.id}
                                        onClick={() => {
                                            setActiveRegion(item)
                                            setIsOpen(false)
                                        }}
                                    >
                                        <StyledVacancies.RegionTitle>
                                            {item.title}
                                        </StyledVacancies.RegionTitle>
                                        <StyledVacancies.RegionCount>
                                            {item.vacancies_count}
                                        </StyledVacancies.RegionCount>
                                    </StyledVacancies.Region>
                                ))}
                            </StyledVacancies.RegionsDropdown>
                        </OutsideClickHandler>
                    </StyledVacancies.Regions>
                    <StyledVacancies.Inner>
                        {activeRegion.vacancies.map((item) => (
                            <Vacancy key={item.id} vacancy={item} />
                        ))}
                    </StyledVacancies.Inner>
                </StyledVacancies>
            </StyledContainer>
        </StyledSection>
    )
}
