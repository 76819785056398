import {
    StyledSection,
    StyledContainer,
    StyledTitle,
    StyledButton,
    StyledAppeal,
} from '@/components/styles'
import { Form, Input, Radio, FileInput, SvgIcon } from '@/components'
import { Files } from '@/components/form/FileInput'
import useForm from '@/lib/hooks/form'
import Link from 'next/link'
import api from '@/lib/api'
import { useRouter } from 'next/router'

export default function () {
    const router = useRouter()
    const form = useForm({ type: '', title: '', text: '', files: '' })

    const onSubmit = () => {
        if (!form.errors?.files?.filter((item) => item).length) {
            form.submit(
                async () => {
                    const response = await api.dialogs.create(form.values)
                    router.push('/appeal')
                },
                (e) => {
                    e.errors &&
                        form.setErrors({
                            ...e.errors,
                            files: form.values.files.map(
                                (item, idx) => e.errors[`files.${idx}`] ?? '',
                            ),
                        })
                },
            )
        }
    }

    return (
        <StyledSection pt="small">
            <StyledContainer>
                <StyledAppeal.Top>
                    <StyledAppeal.Back>
                        <Link href="/appeal">
                            <a>
                                <SvgIcon name="back" width={32} height={32} />
                            </a>
                        </Link>
                    </StyledAppeal.Back>
                    <StyledTitle mb="none" small>
                        Новое обращение
                    </StyledTitle>
                </StyledAppeal.Top>
                <Form onSubmit={onSubmit}>
                    <Radio
                        title="Тип обращения:"
                        options={[
                            { key: 'legal_aid', value: 'Юридическая помощь' },
                            { key: 'credit_assistant', value: 'Кредитный менеджер' },
                        ]}
                        {...form.radio('type')}
                    />
                    <Input
                        label="Тема обращения"
                        placeholder="Например, “Получил претензию от банка”"
                        mb="large"
                        white
                        {...form.input('title')}
                    />
                    <StyledAppeal.Form mb>
                        <Input
                            label="Текст сообщения"
                            placeholder="Введите сообщение..."
                            white
                            default
                            as="textarea"
                            {...form.input('text')}
                        />
                        <StyledAppeal.FormFiles>
                            <Files {...form.input('files')} />
                        </StyledAppeal.FormFiles>
                        <StyledAppeal.FormBottom>
                            <FileInput {...form.input('files')} mb="none" />
                        </StyledAppeal.FormBottom>
                    </StyledAppeal.Form>
                    <StyledButton
                        disabled={
                            form.isLoading ||
                            Object.keys({ type: '', title: '', text: '' }).find(
                                (field) => !form.values[field],
                            ) ||
                            form.errors?.files?.filter((item) => item).length
                        }
                        isLoading={form.isLoading}
                        onSubmit={onSubmit}
                    >
                        Создать обращение
                    </StyledButton>
                </Form>
            </StyledContainer>
        </StyledSection>
    )
}
