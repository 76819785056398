import { StyledVacancies, StyledModal } from '@/components/styles'
import { Modal } from '@/components'

export default function ({ isOpen, onClose, vacancy }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title={vacancy.title}>
            <StyledModal.Block>
                <StyledVacancies.ItemRegion>
                    {vacancy.city.title}, {vacancy.city.area.title}
                </StyledVacancies.ItemRegion>
            </StyledModal.Block>
            <StyledModal.Block>
                <StyledModal.Title>Подробная информация</StyledModal.Title>
                <StyledModal.Table>
                    <StyledModal.TableRow>
                        <StyledModal.InfoTitle>Требуемый опыт работы:</StyledModal.InfoTitle>
                        <StyledModal.InfoText>
                            {vacancy.work_experience ?? 'Не указан'}
                        </StyledModal.InfoText>
                    </StyledModal.TableRow>
                    <StyledModal.TableRow>
                        <StyledModal.InfoTitle>Занятость:</StyledModal.InfoTitle>
                        <StyledModal.InfoText>
                            {vacancy.employment ?? 'Не указана'}
                        </StyledModal.InfoText>
                    </StyledModal.TableRow>
                    <StyledModal.TableRow>
                        <StyledModal.InfoTitle>Адрес:</StyledModal.InfoTitle>
                        <StyledModal.InfoText>
                            {vacancy.address ?? 'Не указан'}
                        </StyledModal.InfoText>
                    </StyledModal.TableRow>
                </StyledModal.Table>
            </StyledModal.Block>
            {vacancy.description && (
                <StyledModal.Block>
                    <StyledVacancies.Desc>
                        <div dangerouslySetInnerHTML={{ __html: vacancy.description }} />
                    </StyledVacancies.Desc>
                </StyledModal.Block>
            )}
        </Modal>
    )
}
