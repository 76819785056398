import styled, { css } from 'styled-components'
import { maxWidth, rgba } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const creepingLine = styled.div`
    position: relative;
    top: 0;
    left: 0;
    margin: 20px auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`
creepingLine.Item = styled.div`
    width: 75px;
    height: 55px;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 3px 4px #b9b9b9f2,
        0px 0px 3px #b9b9b9f2;
    border-radius: 10px;
    background-color: #fff;

    flex-shrink: 0;
    & > img {
        height: 70%;
        width: 70%;

        object-fit: contain;
        object-position: center;
    }
`
creepingLine.Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 15px;
    &.is-hidden {
        display: none;
    }
`

export default creepingLine