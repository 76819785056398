import {
    StyledSection,
    StyledContainer,
    StyledPromo,
    StyledTitle,
    StyledQuote,
    StyledButton,
} from '@/components/styles'

export default function ({
    title,
    text,
    quote,
    img,
    reverse,
    border,
    largeContent,
    grey,
    buttonText,
    onBtnClick,
}) {
    return (
        <StyledSection pb="small" pt="small">
            <StyledContainer>
                <StyledPromo reverse={reverse} grey={grey} border={border}>
                    <StyledPromo.Image noShadow={grey}>
                        <img src={img} alt="" />
                    </StyledPromo.Image>
                    <StyledPromo.Content large={largeContent}>
                        <StyledTitle mb="medium">{title}</StyledTitle>
                        <StyledPromo.Text grey>{text}</StyledPromo.Text>
                        {quote && (
                            <StyledQuote mt grey>
                                {quote}
                            </StyledQuote>
                        )}
                        {onBtnClick && !quote && (
                            <StyledButton width="large" onClick={onBtnClick}>
                                {buttonText || 'Заявка онлайн'}
                            </StyledButton>
                        )}
                    </StyledPromo.Content>
                </StyledPromo>
            </StyledContainer>
        </StyledSection>
    )
}
