import { useState, useMemo, useEffect, useRef } from 'react'
import { StyledButton, StyledForm } from '@/components/styles'
import { Form, Input, PhoneInput, Modal, Socials} from '@/components'
import useForm from '@/lib/hooks/form'

//header callback, services, calculator tabs block, main slider, (promo blocks, small slider blocks with button) - eblocks

const initialFormValues = { name: '', phone: '' }

export default function ({ isOpen, onClose, title, text, onSubmitMethod }) {
    const nameRef = useRef()

    const form = useForm(initialFormValues)

    const [success, setSuccess] = useState(false)

    function onSubmit() {
        form.submit(async () => {
            const userId = Math.random().toString(36).substring(2)
            await onSubmitMethod({ ...form.values, url: window.location.href, user_id: userId })
            const goalParams = { user_id: userId }
            // function goalCallback () {
            // console.log("запрос в Метрику успешно отправлен: USER_ID = " + userId)
            // }
            //
            // ym(34977235, "reachGoal", "user_id", goalParams, goalCallback)
            if(typeof ym !== 'undefined') {
               ym(34977235, 'userParams', goalParams); 
            }
            _tmr.push({ type: 'reachGoal', id: 34977235, goal: 'GOAL_NAME'});
            form.setValues(initialFormValues)
            form.setErrors({})
            onClose(false)
            setSuccess(true)
        })
    }

    function onOpen() {
        nameRef.current && nameRef.current.focus()
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} title={title} text={text} onAfterOpen={onOpen}>
                <Form onSubmit={onSubmit}>
                    <StyledForm.Inner>
                        <Input
                            label="Имя"
                            placeholder="Введите имя"
                            inputRef={nameRef}
                            {...form.input('name')}
                        />
                        <PhoneInput
                            label="Телефон"
                            placeholder="Введите телефон"
                            {...form.input('phone')}
                        />
                    </StyledForm.Inner>
                    <StyledButton width="full" disabled={form.isLoading} isLoading={form.isLoading}>
                        Отправить
                    </StyledButton>
                </Form>
            </Modal>
            <Modal
                isOpen={success}
                onClose={setSuccess}
                small
                title="Спасибо! Мы скоро вам перезвоним"
                text="<p>Ваши данные были успешно отправлены. В скором времени с вами свяжется наш менеджер.</p><p>А пока Вы ожидаете, подпишитесь на <a href='https://t.me/byfin' target='_blank'>телеграм канал</a> с полезным контентом о финансах.<p>"
            >
                <Socials />
            </Modal>
        </>
    )
}
