import styled  from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'
import {StyledText} from '@/components/styles'

const Documents = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    ${maxWidth(vars.breakpoints.tablet)`
        grid-template-columns: repeat(3, 1fr);
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        grid-template-columns: repeat(2, 1fr);
    `}
    ${maxWidth(vars.breakpoints.mobile)`
        grid-template-columns: 1fr;
        gap: 0;
    `}
`

Documents.Item = styled.div`
    ${maxWidth(vars.breakpoints.mobile)`
        padding: 24px 0;
        border-bottom: 1px solid ${vars.theme.outlineGrey};
        &:first-child {
            border-top: 1px solid ${vars.theme.outlineGrey};
        }
    `}
`

Documents.Img = styled.div`
    display: flex;
    justify-content: center;
    height: 285px;
    margin-bottom: 24px;
    img {
        height: inherit;
        cursor: pointer;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        height: 260px;
        margin-bottom: 16px;
    `}
    ${maxWidth(vars.breakpoints.mobile)`
        height: 200px;
    `}
`

Documents.Title = styled(StyledText)`
    margin-bottom: 8px;
`

Documents.Download = styled.div`
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: ${vars.theme.red};
    cursor: pointer;
`

export default Documents
