import { StyledCheckbox, StyledInput } from '@/components/styles'

export default function ({ id, label, checked, setChecked, error, mb, grey }) {
    return (
        <StyledInput.Wrapper mb={mb}>
            <StyledCheckbox checked={checked} error={error}>
                <StyledCheckbox.Input
                    type="checkbox"
                    onChange={() => (id ? setChecked(id) : setChecked())}
                    checked={checked}
                />
                <StyledCheckbox.Title grey={grey}>{label}</StyledCheckbox.Title>
            </StyledCheckbox>
        </StyledInput.Wrapper>
    )
}
