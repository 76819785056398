import { useMemo, useRef } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock, ProductCard } from '@/components'
import Slider from 'react-slick'
import createConditions from '@/lib/createConditions'

export default function ({ title = 'Направления', pt, products = [] }) {
    const sliderRef = useRef()

    const settings = useMemo(
        () => ({
            slidesToShow: products.length < 3 ? products.length : 3,
            slidesToScroll: 1,
            variableWidth: false,
            arrows: false,
            dots: true,
            swipe: false,
            lazyLoad: true,
            responsive: [
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: products.length < 2 ? products.length : 2,
                        swipe: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        variableWidth: true,
                        slidesToShow: products.length < 2 ? products.length : 2,
                        swipe: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        variableWidth: true,
                        slidesToShow: 1,
                        swipe: true,
                        lazyLoad: false,
                    },
                },
            ],
        }),
        [products],
    )

    return (
        <StyledSection pb="small" pt={pt}>
            <StyledContainer>
                <SliderBlock
                    title={title}
                    length={products.length}
                    count="3"
                    dots
                    sliderRef={sliderRef}
                >
                    <Slider {...settings} ref={sliderRef}>
                        {products.map((item) => (
                            <ProductCard
                                key={item.id}
                                title={item.title}
                                img={item.preview.preview}
                                conditions={createConditions(item)}
                                slug={item?.category?.slug ?? item.slug}
                                city={item.city}
                            />
                        ))}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
        </StyledSection>
    )
}
