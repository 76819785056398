import styled, { css } from 'styled-components'
//import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Socials = styled.div`
    margin-top: 30px;
`
Socials.List = styled.ul`
    list-style: none;
    display: flex;
    gap: .8em;
    justify-content: center;
    padding: 0;
    color: rgb(104, 134, 159);
    font-size: 28px;
`

Socials.Item = styled.li`
`
Socials.Link =  styled.a`
    text-decoration: none;
    color: inherit;
    display: flex;
    &:hover {
        opacity: 0.7;
        text-decoration: none;
        color: inherit;
    }
`

export default Socials