import { useEffect, useState } from 'react'
import { StyledOffice, StyledButton, StyledText } from '@/components/styles'
import { Map, SvgIcon, OfficeModal, MessageModal, OfficeReviewModal } from '@/components'
import Rating from 'react-rating'
import api from '@/lib/api'

export default function ({ office }) {
    const [mapIsOpen, setMapIsOpen] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [messageModalIsOpen, setMessageModalIsOpen] = useState(false)
    const [officeModalIsOpen, setOfficeModalIsOpen] = useState(false)
    const [employees, setEmployees] = useState(null)

    const fetchData = async () => {
        const { data } = await api.office(office.id)()

        setEmployees(data.department_employees)
    }

    useEffect(() => {
        modalIsOpen && fetchData()
    }, [modalIsOpen])

    return (
        <StyledOffice>
            <StyledOffice.Inner>
                <StyledOffice.Column>
                    <StyledOffice.Title main>{office.title}</StyledOffice.Title>
                    <StyledText>{office.address}</StyledText>
                    {office.rating && (
                        <StyledOffice.Rating>
                            <StyledOffice.RatingTitle>{office.rating}</StyledOffice.RatingTitle>
                            <Rating
                                initialRating={+office.rating}
                                readonly
                                emptySymbol={<SvgIcon name="star" width="20" height="20" />}
                                fullSymbol={<SvgIcon name="star-gold" width="20" height="20" />}
                            />
                        </StyledOffice.Rating>
                    )}
                </StyledOffice.Column>
                <StyledOffice.Column>
                    <StyledOffice.Title>Телефон</StyledOffice.Title>
                    {office.phone.split(', ').map((phone, idx) => (
                        <StyledText key={idx} noFormat>
                            <a href={`tel:${phone}`}>{phone}</a>
                        </StyledText>
                    ))}
                </StyledOffice.Column>
                <StyledOffice.Column>
                    <StyledOffice.Title>График работы</StyledOffice.Title>
                    <StyledText dangerouslySetInnerHTML={{ __html: office.schedule }} noFormat />
                </StyledOffice.Column>
                <StyledOffice.Column>
                    <StyledButton bg="grey" width="full" onClick={() => setModalIsOpen(true)}>
                        Подробнее
                    </StyledButton>
                </StyledOffice.Column>
            </StyledOffice.Inner>
            <StyledOffice.Actions mb={mapIsOpen}>
                <StyledOffice.Action onClick={() => setMapIsOpen(!mapIsOpen)}>
                    <SvgIcon name="location2" width="24" height="24" />
                    {mapIsOpen ? 'Скрыть карту' : 'Показать на карте'}
                </StyledOffice.Action>
                <StyledOffice.Action onClick={() => setOfficeModalIsOpen(true)}>
                    <SvgIcon name="review" width="24" height="24" />
                    Оставить отзыв
                </StyledOffice.Action>
            </StyledOffice.Actions>
            <StyledOffice.Map isOpen={mapIsOpen}>
                {mapIsOpen && <Map placemarkList={[office]} />}
            </StyledOffice.Map>
            <OfficeModal
                office={office}
                employees={employees}
                isOpen={modalIsOpen}
                onClose={setModalIsOpen}
                onMessageModalOpen={() => {
                    setModalIsOpen(false)
                    setMessageModalIsOpen(true)
                }}
                onOpenReviewModal={() => {
                    setModalIsOpen(false)
                    setOfficeModalIsOpen(true)
                }}
            />
            <MessageModal
                office={office}
                isOpen={messageModalIsOpen}
                onClose={setMessageModalIsOpen}
            />
            <OfficeReviewModal
                office={office}
                isOpen={officeModalIsOpen}
                onClose={setOfficeModalIsOpen}
            />
        </StyledOffice>
    )
}
