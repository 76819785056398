import styled from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const StyledToggle = styled.button`
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
  height: 20px;
  margin-left: -4px;
  margin-right: 12px;

  ${maxWidth(vars.breakpoints.tablet - 1)`
    display: flex;
  `}
`

StyledToggle.Inner = styled.span`
  position: absolute;
  top: 50%;
  left: 4px;
  display: block;
  width: 18px;
  height: 2px;
  margin-top: -1px;
  border-radius: 1px;
  transform: ${props => (props.isOpen ? 'rotate(225deg)' : '')};
  transition-timing-function: ${props =>
    props.isOpen
        ? 'cubic-bezier(0.215, 0.61, 0.355, 1)'
        : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${props => (props.isOpen ? '0.12s' : '')};
  background-color: ${vars.theme.darkGrey};

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    background-color: ${vars.theme.darkGrey};
  }

  &::before {
    top: ${props => (props.isOpen ? '0' : '-6px')};
    transition: ${props =>
    props.isOpen
        ? 'top 0.1s ease-out, opacity 0.1s ease-out 0.12s'
        : 'top 0.1s ease-in 0.25s, opacity 0.1s ease-in'};
    opacity: ${props => (props.isOpen ? 0 : '')};

    ${maxWidth(vars.breakpoints.mTablet)`
        top: ${props => (props.isOpen ? '0' : '-6px')};
    `}
  }

  &::after {
    bottom: ${props => (props.isOpen ? 0 : '-6px')};
    transition: ${props =>
    props.isOpen
        ? 'bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s'
        : 'bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
    transform: ${props => (props.isOpen ? 'rotate(-90deg)' : '')};

    ${maxWidth(vars.breakpoints.mTablet)`
        bottom: ${props => (props.isOpen ? 0 : '-6px')};
    `}
  }
`

export default StyledToggle
