import { useMemo, useRef } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock, HistoryCard } from '@/components'
import Slider from 'react-slick'

export default function ({ title, blocks = [] }) {
    const sliderRef = useRef()

    const settings = useMemo(
        () => ({
            slidesToShow: blocks.length < 3 ? blocks.length : 3,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
            ],
        }),
        [blocks],
    )

    return (
        <StyledSection pt="small" pb="small">
            <StyledContainer>
                <SliderBlock
                    title={title}
                    length={blocks.length}
                    count="4"
                    sliderRef={sliderRef}
                    whiteContent
                >
                    <Slider {...settings} ref={sliderRef}>
                        {blocks.map((item) => (
                            <HistoryCard
                                key={item.id}
                                year={item.title}
                                img={item.image.small}
                                text={item.description}
                            />
                        ))}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
        </StyledSection>
    )
}
