import styled, {css} from 'styled-components'
import {rgba, maxWidth} from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import {StyledText} from '@/components/styles'

const Promo = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${p => p.reverse && "flex-direction: row-reverse;"}
    ${p => p.border && `border: 1px solid ${vars.theme.outlineGrey};`}
    border-radius: 8px;
    ${p => p.grey && `background: ${vars.theme.lightGrey};`}
    background-image: radial-gradient(${p => p.dark ? rgba(vars.theme.outlineGrey, .6) : p.border ? vars.theme.lightGrey : "#fff"} 10%, rgba(255,255,255,0) 20%);
    background-size: 25px 25px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${p => p.dark 
            ? "linear-gradient(102.88deg, rgba(255,255,255,0) 0%, #68869F 70%)" 
            : p.reverse 
            ? `linear-gradient(90deg, ${p.grey ? vars.theme.lightGrey : "#fff"} 20%, rgba(255,255,255,0) 100%)`
            : `linear-gradient(110deg, rgba(255,255,255,0) 20%, ${p.grey ? vars.theme.lightGrey : "#fff"} 60%)`
        };
        border-radius: 8px;
    }
    
    ${maxWidth(vars.breakpoints.mTablet)`
        display: block;
        &:before {
            background: ${p => p.dark
                ? "linear-gradient(10deg, #68869F 60%, rgba(255,255,255,0) 80%)"
                    : `linear-gradient(10deg, ${p.grey ? vars.theme.lightGrey : "#fff"} 60%, rgba(255,255,255,0) 80%)`
            };
        }
    `}
`

Promo.Lawyer = styled.div`
    background: linear-gradient(102.88deg, #89A6BE 15.26%, #68869F 68.03%);
    border-radius: 8px;
`

Promo.Content = styled.div`
    position: relative;
    max-width: ${p => p.large ? "600px" : "517px"};
    padding: ${p => p.large ? "32px" : "32px 85px 32px 32px"};
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 24px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        max-width: 100%;
        padding: 16px;
    `}
`

Promo.Text = styled(StyledText)`
    margin-bottom: 32px;
    ${maxWidth(vars.breakpoints.tablet)`
        margin-bottom: 24px;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        font-size: 14px;
        line-height: 20px;
    `}
`

Promo.Image = styled.div`
    position: relative;
    flex-shrink: 0;
    ${p => p.lawyer && "margin: -16px 0 0 76px;"}
    img {
        position: relative;
        height: 360px;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.lawyer && "margin: -6px 0 0 8px;"}
        img {
            height: 310px;
        }
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        display: flex;
        justify-content: center;
        ${p => p.lawyer && "margin: 0;"}
        img {
            height: 200px;
            ${p => p.lawyer && "margin-top: -8px;"}
        }
        ${p => !p.noShadow && css`
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: ${p => p.lawyer ? "150px" : "80px"};
                background-image: ${p => p.lawyer ? "linear-gradient(0deg, #68869F 0%, rgba(255,255,255,0) 90%)" : "linear-gradient(rgba(255,255,255,0) 0%, #fff 75%)"};
            }
        `}
    `}
`

Promo.Form = styled.div`
    display: grid;
    grid-template-columns: 1fr 160px;
    ${maxWidth(vars.breakpoints.mMobile)`
        grid-template-columns: 1fr 130px;
    `}
    ${maxWidth(vars.breakpoints.sMobile - 1)`
        grid-template-columns: 1fr 90px;
    `}
`

export default Promo
