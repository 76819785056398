import { StyledCollapse, StyledText } from '@/components/styles'

export default function ({ title, text, isOpen, setIsOpen }) {
    return (
        <StyledCollapse isOpen={isOpen}>
            <StyledCollapse.Head isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                {title}
            </StyledCollapse.Head>
            <StyledCollapse.Content isOpen={isOpen}>
                <StyledText grey>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </StyledText>
            </StyledCollapse.Content>
        </StyledCollapse>
    )
}
