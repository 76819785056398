import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Slider = styled.div`
    .slick-track {
        display: flex;
        ${p => p.main && "align-items: flex-start;"}
        margin: 0;
    }
    ${p => !p.noListSettings && css`
        .slick-list {
            margin: -20px -10px;
            padding: 20px 0 !important;
        }
    `}
    & > div {
        &:not(:first-child) .slick-list {
            padding-top: 0 !important;
        }
    }
    
    ${p => p.gradient && css`
        .slick-list {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 120px;
                height: 100%;
                background: linear-gradient(270deg, #FFFFFF 15.08%, rgba(255, 255, 255, 0) 100%);
                pointer-events: none;
                z-index: 1;
            }
        }
    `}
    
    .slick-slide {
        // float: none;
        ${p => !p.clients && "height: auto !important;"}
        & > div {
            height: 100%;
            ${p => !p.main && "padding: 0 10px;"}
            & > div {
                ${p => p.main && "display: block !important;"}
            }
        }
    }
    
    ${p => !p.main ? css`
        .slick-dots {
            position: static;
            display: flex !important;
            margin-top: 32px;
            li {
                flex: 1;
                margin: 0;
                padding: 0;
                button {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 2px;
                    background: ${p.whiteDots ? vars.theme.darkGrey : vars.theme.outlineGrey};
                    transition: .3s;
                    &:before {
                        display: none;
                    }
                }
                &.slick-active {
                    button {
                        background: ${p.whiteDots ? "#fff" : vars.theme.darkGrey};
                    }
                }
            }
        }
    ` : css`
        .slick-dots {
            top: 290px;
            bottom: auto;
            display: inline-block;
            width: initial;
            margin-left: 10px;
            text-align: left;
            ${maxWidth(vars.breakpoints.mTablet)`
                display: none!important;
            `}
            
            li button {
                &:before {
                    color: #fff;
                    opacity: .5;
                    font-size: 9px;
                }
            }
            
            li.slick-active button {
                &:before {
                    color: #fff;
                    opacity: 1;
                }
            }
        }
    `}
    
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => !p.clients && !p.main && "margin-right: -10px;"}
        .slick-list:before {
            display: none;
        }
        ${p => !p.clients && !p.main && css`
            .slick-slide {
                width: ${p.sertificates ? "174px" : p.media ? "212px" : "300px"} !important;
                max-width: calc(80vw + 20px);
            }
        `}
        ${p => !p.main && css`
            .slick-dots {
                margin-top: 24px;
            }
        `}
        ${p => p.clients && css`
            .slick-list {
                margin: -20px -10px 0;
            }
        `}
    `}
`

Slider.Arrows = styled.div`
    display: flex;
    align-items: center;
    ${maxWidth(vars.breakpoints.mTablet)`
        ${p => p.showArrows === true ? css`
            width: 100%;
            gap: 1rem;
            grid-template-columns: 52px auto 52px;
            display: grid;
        ` : css`
            display: none;
        `}
    `}
`

Slider.Arrow = styled.button`
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    margin-${p => p.prev ? "right" : "left"}: 4px;
    background: ${p => p.opacity ? "rgba(255,255,255,.1)" : "#FFFFFF"};
    ${p => !p.opacity && `border: 1px solid ${vars.theme.outlineGrey};`}
    border-radius: 50%;
    cursor: pointer;
    transition: .25s;
    
    ${p => p.media && css`
        position: absolute;
        margin: 0;
        left: auto;
        right: -26px;
        top: calc((100% - 32px) / 2 ${p.prev ? "-" : "+"} 30px);
        z-index: 1;
    `}
    
    svg {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
    }
    
    &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-right: 2px solid ${p => p.opacity ? "#fff" : vars.theme.darkGrey};
        border-bottom: 2px solid ${p => p.opacity ? "#fff" : vars.theme.darkGrey};
        transform: rotate(${p => p.prev ? "135deg" : "-45deg"});
        margin-${p => p.prev ? "left" : "right"}: 3px;
        transition: .25s;
    }
    
    &:hover {
        background: ${p => p.opacity ? "rgba(255,255,255,.1)" : "#FFFFFF"};
        box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 22px rgba(61, 75, 86, 0.08);
        &:before {
            border-color: ${vars.theme.red};
        }
    }
    
    &:active {
        background: ${p => p.opacity ? "rgba(255,255,255,.1)" : "#FFFFFF"};
        &:before {
            border-color: ${vars.theme.darkGrey};
        }
    }
    
    &:focus {
        background: ${p => p.opacity ? "rgba(255,255,255,.1)" : "#FFFFFF"};
    }
    
    ${maxWidth(vars.breakpoints.tablet - 1)`
        
  `}
`

export default Slider
