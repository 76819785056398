import { useState } from 'react'
import styled, { css } from 'styled-components'
import { StyledButton } from '@/components/styles'
import { YMaps, Map, ZoomControl, FullscreenControl, Placemark } from 'react-yandex-maps'
import { rotate } from '@/components/styles/Keyframes'
import vars from '@/assets/styles/vars'

function YAMap({ placemarkList = [], zoom = 12 }) {
    const [isLoading, setIsLoading] = useState(true)

    return (
        <Wrap colored>
            {isLoading && <Loader />}
            <YMaps>
                <Map
                    defaultState={{
                        center:
                            placemarkList.length > 0 && placemarkList.length < 2
                                ? [
                                      placemarkList[0].location.latitude,
                                      placemarkList[0].location.longitude,
                                  ]
                                : [53.911863, 27.571182],
                        zoom: zoom,
                        controls: [],
                        behaviors: ['multiTouch', 'drag'],
                    }}
                    width="100%"
                    heigth="100%"
                    onLoad={() => setIsLoading(false)}
                >
                    <ZoomControl options={{ float: 'right' }} />
                    <FullscreenControl />
                    {placemarkList &&
                        placemarkList.map((place, idx) => (
                            <Placemark
                                key={idx}
                                geometry={[place.location.latitude, place.location.longitude]}
                                modules={['geoObject.addon.balloon']}
                                properties={{
                                    balloonContent: place.title || place.city,
                                    balloonContentFooter: place.address,
                                }}
                                defaultOptions={{
                                    iconImageSize: [40, 48],
                                    iconImageOffset: [-20, -27],
                                }}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: '/images/pin.png',
                                    hideIconOnBalloonOpen: false,
                                    balloonOffset: [8, -30],
                                }}
                            />
                        ))}
                </Map>
            </YMaps>
        </Wrap>
    )
}

const MyBalloon = ({ title, text, onBtnClick }) => {
    return (
        <StyledBalloon>
            <StyledBalloon.Title>{title}</StyledBalloon.Title>
            <StyledBalloon.Text>{text}</StyledBalloon.Text>
            {onBtnClick && (
                <StyledButton width="full" bg="grey">
                    Подробнее
                </StyledButton>
            )}
        </StyledBalloon>
    )
}

export default YAMap

const Wrap = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .mapTitle {
        position: absolute;
        z-index: 1000;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 5px;
        display: none;
        padding: 5px 20px;
        border-radius: 5px;
        background: rgb(255, 255, 255);
        border-width: 1px;
        border-style: solid;
        border-color: rgb(204, 204, 204);
        border-image: initial;
    }

    & > div {
        width: 100% !important;
        height: 100% !important;
    }

    ${(p) =>
        !p.colored &&
        css`
            [class*='ymaps-2'][class*='-ground-pane'] {
                filter: url("data:image/svg+xml;utf8,<svg xmlns=\\'http://www.w3.org/2000/svg\\'><filter id=\\'grayscale\\'><feColorMatrix type=\\'matrix\\' values=\\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\\'/></filter></svg>#grayscale");
                -webkit-filter: grayscale(100%);
            }
        `}
`

const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: '';
        width: 50px;
        height: 50px;
        border: 6px solid ${vars.theme.red};
        border-left-color: transparent;
        border-radius: 50%;
        transition: opacity 0.5s ease;
        pointer-events: none;
        animation: ${rotate} 1s linear infinite;
    }
`

const StyledBalloon = styled.div``

StyledBalloon.Title = styled.div``

StyledBalloon.Text = styled.div``
