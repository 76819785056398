import styled, { css } from 'styled-components'
import { rgba, maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'
import { StyledText } from '@/components/styles'

const Client = styled.div`
    
`

Client.Photo = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    transition: .3s;
    
    img {
        object-fit: cover;
    }
`

Client.Type = styled.div`
    display: inline-block;
    padding: 4px 10px;
    background: linear-gradient(102.88deg, #89A6BE 15.26%, #68869F 68.03%);
    border-radius: 32px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
`

Client.Card = styled.div`
    display: flex;
    padding: 20px;
    transition: .3s;
    ${p => p.active ? css`
        padding-bottom: 40px;
        background: #FFFFFF;
        box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
        border-radius: 8px 8px 0px 0px;
    ` : css`
        border: 1px solid ${vars.theme.outlineGrey};
        border-radius: 8px;
        ${Client.Photo} {
            filter: grayscale(100%);
        }
        ${Client.Type} {
            background: ${vars.theme.grey};
        }
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
        background: #FFFFFF;
        box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
        border-radius: 8px 8px 0px 0px;
        border: none;
        border-bottom: 1px solid ${vars.theme.outlineGrey};
    `}
`

Client.Desc = styled.div``

Client.Name = styled.div`
    margin-bottom: 4px;
    font-weight: 500;
`

Client.Info = styled.div`
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
    color: ${vars.theme.darkGrey};
`

Client.Case = styled.div`
    padding: 32px 17%;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgba(61, 75, 86, 0.1);
    border-radius: 8px;
    p {
        margin-bottom: 8px;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 24px 12%;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        padding: 16px;
        border-radius: 0 0 8px 8px;
    `}
`

Client.CaseResult = styled.div`
    margin-top: 24px;
    padding: 20px 24px;
    background: ${vars.theme.lightGrey};
    border-radius: 8px;
`

Client.Note = styled(StyledText)`
    max-width: 794px;
    margin: 40px auto 0;
    ${maxWidth(vars.breakpoints.mTablet)`
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid ${vars.theme.outlineGrey};
        font-size: 14px;
        line-height: 20px;
    `}
`

export default Client
