import { useState, useEffect } from 'react'
import {
    StyledContainer,
    StyledNav,
    StyledText,
    StyledButton,
    StyledTitle,
} from '@/components/styles'
import { NewsCard, Tabs, RouteLink, SvgIcon } from '@/components'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { useInfo } from '@/contexts/InfoContext'
import { useAuth } from '@/contexts/AuthContext'

const nav = [
    { id: 9, title: 'Пресс-центр', link: '/press-center', news: true },
    {
        id: 10,
        title: 'О компании',
        link: '/about-company',
        submenu: [
            {
                id: 11,
                title: 'О компании',
                text: 'Достижения компании и история нашего развития.',
                link: '/about-company',
            },
            {
                id: 12,
                title: 'Карьера',
                text: 'Вакансии и перспективы работы в нашей компании.',
                link: '/career',
            },
            {
                id: 13,
                title: 'Документы',
                text: 'Мы работаем официально и готовы это доказать.',
                link: '/documents',
            }
        ],
    },
    {
        id: 15,
        title: 'Контакты',
        link: '/contacts?view=list',
        submenu: [
            {
                id: 16,
                title: 'Контакты отделений',
                text: 'Информация о всех наших отделениях в одном месте.',
                link: '/contacts?view=list',
            },
            {
                id: 17,
                title: 'Мобильный офис',
                text: 'Расписание посещения мобильного офиса всех городов РБ.',
                link: '/mobile-office',
            },
        ],
    },
]

export default function ({ dark, setAuthModalIsOpen }) {
    const router = useRouter()

    const [menuItem, setMenuItem] = useState(null)
    const [submenuItem, setSubmenuItem] = useState(null)
    const [popular, setPopular] = useState(null)
    const [newsTab, setNewsTab] = useState(1)

    const { articles, massMedia, categories } = useInfo()
    const { user } = useAuth()

    return (
        <StyledNav>
            {categories.header.map((navItem) => (
                <ItemWithDropdown
                    title={navItem.name}
                    dark={dark}
                    key={navItem.id}
                    slug={navItem.has_page && navItem.slug}
                    onEnter={() => {
                        setPopular(navItem.popular_page ?? null)
                    }}
                    onLeave={() => {
                        menuItem && setMenuItem(null)
                        submenuItem && setSubmenuItem(null)
                        popular && setPopular(null)
                    }}
                >
                    {navItem.children?.length > 0 && (
                        <StyledNav.DropdownInner>
                            <StyledNav.Menu>
                                <StyledNav.Column lightBorder={dark}>
                                    <StyledNav.ColumnInner>
                                        {navItem.children.map(
                                            (item) =>
                                                item.has_page && (
                                                    <StyledNav.MenuItem
                                                        key={item.id}
                                                        arrow={item.children?.length > 0}
                                                        active={menuItem && item.id === menuItem.id}
                                                        onMouseEnter={() => {
                                                            setMenuItem(
                                                                item.children?.length > 0
                                                                    ? item
                                                                    : null,
                                                            )
                                                            submenuItem && setSubmenuItem(null)
                                                            setPopular(item.popular_page ?? null)
                                                        }}
                                                    >
                                                        <RouteLink slug={item.slug}>
                                                            <StyledNav.MenuLink>
                                                                {item.name}
                                                            </StyledNav.MenuLink>
                                                        </RouteLink>
                                                    </StyledNav.MenuItem>
                                                ),
                                        )}
                                    </StyledNav.ColumnInner>
                                    {navItem.name === 'Лизинг' && (
                                        <StyledButton bg="grey" redText>
                                            <RouteLink slug="lizing-dlya-yur-lic">
                                                <a />
                                            </RouteLink>
                                            Рассчитать лизинг
                                        </StyledButton>
                                    )}
                                </StyledNav.Column>
                                <StyledNav.Column lightBorder={dark}>
                                    {menuItem && (
                                        <StyledNav.DropdownTitle>
                                            {menuItem.name}
                                        </StyledNav.DropdownTitle>
                                    )}
                                    {menuItem &&
                                        menuItem.children.map(
                                            (item) =>
                                                item.has_page && (
                                                    <StyledNav.MenuItem
                                                        key={item.id}
                                                        submenu
                                                        arrow={item.children?.length > 0}
                                                        onMouseEnter={
                                                            item.children?.length > 0
                                                                ? () => setSubmenuItem(item)
                                                                : () => setSubmenuItem(null)
                                                        }
                                                    >
                                                        <RouteLink slug={item.slug}>
                                                            <StyledNav.MenuLink>
                                                                {item.name}
                                                            </StyledNav.MenuLink>
                                                        </RouteLink>
                                                    </StyledNav.MenuItem>
                                                ),
                                        )}
                                </StyledNav.Column>
                                {submenuItem && (
                                    <StyledNav.Column>
                                        <StyledNav.DropdownTitle>
                                            {submenuItem.name}
                                        </StyledNav.DropdownTitle>
                                        {submenuItem.children.map((item) => (
                                            <MenuItemWithList
                                                key={item.id}
                                                slug={item.has_page && item.slug}
                                                name={item.name}
                                                list={item.children}
                                            />
                                        ))}
                                    </StyledNav.Column>
                                )}
                            </StyledNav.Menu>
                            {!submenuItem && (
                                <StyledNav.Popular>
                                    {popular && (
                                        <>
                                            <StyledNav.DropdownTitle mbLarge>
                                                Самое популярное
                                            </StyledNav.DropdownTitle>
                                            <StyledNav.PopularImg>
                                                <img src={popular.preview.x1} alt="" />
                                            </StyledNav.PopularImg>
                                            <StyledNav.PopularTitle>
                                                <RouteLink slug={popular.slug}>
                                                    <a>{popular.title}</a>
                                                </RouteLink>
                                            </StyledNav.PopularTitle>
                                            <StyledNav.PopularConditions>
                                                <StyledNav.PopularCondition>
                                                    <StyledNav.PopularConditionTitle>
                                                        {popular.credit_sum || popular.monthly_payment}
                                                    </StyledNav.PopularConditionTitle>
                                                    <StyledText>{popular.credit_sum ? 'Максимальная сумма' : 'Месячный платеж'}</StyledText>
                                                </StyledNav.PopularCondition>
                                                <StyledNav.PopularCondition>
                                                    <StyledNav.PopularConditionTitle>
                                                        {popular.credit_percent ||
                                                            popular.lease_term}
                                                    </StyledNav.PopularConditionTitle>
                                                    <StyledText>{popular.credit_sum ? 'Процентная ставка' : 'Срок лизинга'}</StyledText>
                                                </StyledNav.PopularCondition>
                                            </StyledNav.PopularConditions>
                                        </>
                                    )}
                                </StyledNav.Popular>
                            )}
                        </StyledNav.DropdownInner>
                    )}
                </ItemWithDropdown>
            ))}

            {nav.map((navItem) => {
                const { submenu = [] } = navItem

                return submenu.length > 0 || navItem.news ? (
                    <ItemWithDropdown
                        title={navItem.title}
                        dark={dark}
                        href={navItem.link}
                        key={navItem.id}
                    >
                        <StyledNav.DropdownInner padding>
                            {navItem.news && (
                                <>
                                    <StyledNav.NewsLeft>
                                        <StyledTitle small mb="small">
                                            Последние полезные статьи
                                        </StyledTitle>

                                        <StyledButton bg="grey" redText>
                                            <Link href="/press-center">
                                                <a />
                                            </Link>
                                            Смотреть новости
                                        </StyledButton>
                                    </StyledNav.NewsLeft>
                                    <StyledNav.NewsRight>
                                        <Tabs
                                            tabs={[
                                                { id: 1, title: 'Новости' },
                                                { id: 2, title: 'СМИ о нас' },
                                            ]}
                                            tab={newsTab}
                                            setTab={setNewsTab}
                                            small
                                        />
                                        <StyledNav.NewsInner>
                                            {newsTab === 1
                                                ? (articles.length < 3
                                                      ? articles
                                                      : articles.slice(0, 3)
                                                  ).map((item) => (
                                                      <NewsCard
                                                          key={item.id}
                                                          img={item.preview}
                                                          title={item.title}
                                                          views={item.views}
                                                          date={item.publication_date}
                                                          slug={item.slug}
                                                          small
                                                      />
                                                  ))
                                                : (massMedia.length < 3
                                                      ? massMedia
                                                      : massMedia.slice(0, 3)
                                                  ).map((item) => (
                                                      <NewsCard
                                                          key={item.id}
                                                          img={item.preview}
                                                          title={item.title}
                                                          link={item.link}
                                                          url={item.url}
                                                          small
                                                          smallImg
                                                      />
                                                  ))}
                                        </StyledNav.NewsInner>
                                    </StyledNav.NewsRight>
                                </>
                            )}
                            {navItem.submenu &&
                                navItem.submenu.map((item) => (
                                    <StyledNav.SubmenuItem key={item.id}>
                                        <StyledNav.SubmenuTitle>
                                            {item.title}
                                        </StyledNav.SubmenuTitle>
                                        <StyledNav.SubmenuText>{item.text}</StyledNav.SubmenuText>
                                        <Link href={item.link}>
                                            <a />
                                        </Link>
                                    </StyledNav.SubmenuItem>
                                ))}
                        </StyledNav.DropdownInner>
                    </ItemWithDropdown>
                ) : (
                    <StyledNav.Item key={navItem.id}>
                        <StyledNav.ItemTitle
                            active={router.pathname === navItem.link}
                            link={navItem.link}
                        >
                            <Link href={navItem.link}>
                                <a>{navItem.title}</a>
                            </Link>
                        </StyledNav.ItemTitle>
                    </StyledNav.Item>
                )
            })}
            <StyledNav.Item
                onClick={() => (user ? Router.push('/appeal') : setAuthModalIsOpen(true))}
            >
                <StyledNav.ItemTitle>
                    Личный кабинет
                    <SvgIcon name="exit" width="17" height="18" />
                </StyledNav.ItemTitle>
            </StyledNav.Item>
        </StyledNav>
    )
}

function ItemWithDropdown({ title = '', children, slug, href, dark, onEnter, onLeave }) {
    const [isOpen, setIsOpen] = useState(false)
    const [state, setState] = useState({
        openTimeoutId: null,
        closeTimeoutId: null,
    })
    const router = useRouter()

    const onLinkEnter = () => {
        if (state.closeTimeoutId) {
            // enter the same link again, clear close timeout
            clearTimeout(state.closeTimeoutId)
            setState({ openTimeoutId: null, closeTimeoutId: null })
            onEnter && onEnter()
            return
        } else {
            const openTimeoutId = setTimeout(() => {
                // open menu
                setState({ ...state, openTimeoutId: null })
                setIsOpen(true)
                onEnter && onEnter()
            }, 200)

            setState({ ...state, openTimeoutId })
        }
    }

    function onLinkLeave() {
        if (state.openTimeoutId) {
            // leave before open
            clearTimeout(state.openTimeoutId)
            setState({ openTimeoutId: null, closeTimeoutId: null })
            onLeave && onLeave()
            return
        } else {
            const closeTimeoutId = setTimeout(() => {
                // close menu
                setIsOpen(false)
                setState({ openTimeoutId: null, closeTimeoutId: null })
                onLeave && onLeave()
            }, 200)

            setState({ ...state, closeTimeoutId })
        }
    }

    useEffect(() => {
        clearTimeout(state.openTimeoutId)
        clearTimeout(state.closeTimeoutId)
        setState({ openTimeoutId: null, closeTimeoutId: null })
        // close dropdown when change page
        if (isOpen) setIsOpen(false)
    }, [router.query])

    return (
        <StyledNav.Item onMouseLeave={onLinkLeave} onMouseEnter={onLinkEnter}>
            <StyledNav.ItemTitle active={isOpen} link={slug || href}>
                {slug ? (
                    <RouteLink slug={slug}>
                        <a>{title}</a>
                    </RouteLink>
                ) : href ? (
                    <Link href={href}>
                        <a>{title}</a>
                    </Link>
                ) : (
                    title
                )}
            </StyledNav.ItemTitle>
            {children && (
                <StyledNav.Dropdown isOpen={isOpen} dark={dark}>
                    <StyledContainer>{children}</StyledContainer>
                </StyledNav.Dropdown>
            )}
        </StyledNav.Item>
    )
}

const MenuItemWithList = ({ slug, name, list }) => {
    const [isOpen, setIsOpen] = useState(false)

    return slug ? (
        <StyledNav.MenuItem
            submenu
            onMouseEnter={() => list?.length > 0 && setIsOpen(true)}
            onMouseLeave={() => list?.length > 0 && setIsOpen(false)}
        >
            <RouteLink slug={slug}>
                <StyledNav.MenuLink>
                    {name} {list?.length > 0 && <span>{list?.length}</span>}
                </StyledNav.MenuLink>
            </RouteLink>
            <StyledNav.List isOpen={isOpen}>
                {list.map(
                    (item) =>
                        item.has_page && (
                            <StyledNav.MenuItem submenu key={item.id}>
                                <RouteLink slug={item.slug}>
                                    <StyledNav.MenuLink>{item.name}</StyledNav.MenuLink>
                                </RouteLink>
                            </StyledNav.MenuItem>
                        ),
                )}
            </StyledNav.List>
        </StyledNav.MenuItem>
    ) : null
}
