import { StyledText, StyledSection, StyledContainer } from '@/components/styles'
import { useEffect, useRef,  useState } from 'react'
import styled, {css} from 'styled-components'

export default function (text) {
    const [isShow, setIsShow] = useState(false)

    const StyledSEOText = styled(StyledText)`
        position: relative;
        max-height: 230px;
        overflow: hidden;
        transition: max-height 2s ease-in-out;
        &:not(.is-show):after {
            content: '';
            width: 100%;
            background: linear-gradient(180deg, transparent 0%, rgb(255, 255, 255) 95%);
            overflow: hidden;
            display: block;
            position: absolute;
            bottom: 0;
            height: 50%;
        }
        &.is-show {
            max-height: 3000px;
            transition: max-height 2s ease-in-out;
        }
    `

    const StyledMore = styled.div`
        text-align: center;
        color: rgb(241, 80, 69);
        span {
            font-weight: 600;
            font-size: 1.2em;
        }
        p {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-style: dotted;
        }
    `;

    const bText = isShow ? 'Свернуть <span>&#8593;</span>' : 'Читать далее <span>&#8595;</span>';

    return (
        <StyledSection pt='none'>
            <StyledContainer>
                <StyledSEOText dangerouslySetInnerHTML={{ __html: text.text}} className={`${isShow ? 'is-show' : ''}`} />
                <StyledMore>
                    <p onClick={() => setIsShow(!isShow)} dangerouslySetInnerHTML={{ __html: bText }} />
                </StyledMore>
            </StyledContainer>
        </StyledSection>
    )
}