import { useRef, useState, useEffect } from 'react'
import { StyledSection, StyledContainer, StyledClient, StyledQuote } from '@/components/styles'
import { SliderBlock, ClientCard } from '@/components'
import Slider from 'react-slick'

export default function ({ title, blocks }) {
    const [state, setState] = useState({ nav1: null, nav2: null })
    const sliderRef = useRef()
    const textSliderRef = useRef()

    const [currentSlide, setCurrentSlide] = useState(0)

    const settings = {
        slidesToShow: blocks.length < 3 ? blocks.length : 3,
        slidesToScroll: 1,
        swipe: true,
        centerMode: true,
        centerPadding: '0px',
        arrows: false,
        dots: false,
        focusOnSelect: true,
        lazyLoad: true,
        asNavFor: state.nav2,
        beforeChange: (current, next) => {
            setCurrentSlide(next)
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                },
            },
        ],
    }

    const textSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        arrows: false,
        dots: false,
        asNavFor: state.nav1,
        adaptiveHeight: true,
    }

    useEffect(() => {
        setState({
            nav1: sliderRef.current,
            nav2: textSliderRef.current,
        })
    }, [])

    return (
        <StyledSection greyBackground>
            <StyledContainer>
                <SliderBlock
                    title={title}
                    length={blocks.length}
                    count="3"
                    showArrows={true}
                    sliderRef={sliderRef}
                    secondSliderRef={textSliderRef}
                    currentSlide={currentSlide + 1}
                    clients
                >
                    <Slider {...settings} ref={(slider) => (sliderRef.current = slider)}>
                        {blocks.map((item, idx) => (
                            <ClientCard
                                key={item.id}
                                active={idx === currentSlide}
                                photo={item.image?.small}
                                name={item.name}
                                desc={item.short_description}
                                type={item.credit_type}
                            />
                        ))}
                    </Slider>
                    <Slider {...textSettings} ref={(slider) => (textSliderRef.current = slider)}>
                        {blocks.map((item) => (
                            <StyledClient.Case key={item.id}>
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                {item.fact && (
                                    <StyledClient.CaseResult>
                                        <StyledQuote>{item.fact}</StyledQuote>
                                    </StyledClient.CaseResult>
                                )}
                            </StyledClient.Case>
                        ))}
                    </Slider>
                </SliderBlock>
                <StyledClient.Note grey>
                    * Мы не раскрываем персональные данные своих клиентов. Все примеры предоставлены
                    с согласия клиентов на условии изменения их имён и фамилий.
                </StyledClient.Note>
            </StyledContainer>
        </StyledSection>
    )
}
