import styled from 'styled-components'
import vars from '@/assets/styles/vars'
import { maxWidth } from '@/assets/styles/mixins.css'

const StyledReviews = styled.div`
    color: ${p => p.white? "white" : "#5E6265" };
    display: flex;
    gap: 20px;
    margin-top: 40px;
`
StyledReviews.Item = styled.div`
    flex-basis: 25%;
    padding: 20px 10px;
    border: ${p => p.white? "1px solid white" : "1px solid #E7E6EC"};
    border-radius: 10px;
    ${maxWidth(vars.breakpoints.tablet)`
       flex-basis: 50%;
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        flex-basis: 100%;
    `}
`
StyledReviews.Title = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    h2 {
        font-size: 17px;
        font-weight: 500;
        color: ${p => p.white? "#FFF" : "#05050D" };
    }
    &::after {
        content: '';
        width: 85px;
        height: 1px;
        background: ${p => p.white?"#FFF" : "#E7E6EC"};
    }
`
StyledReviews.Photo = styled.div`
    border-radius: 50%;
    overflow: hidden;
    width: 70px;
    height: 70px;
    img {
        width: 100%;
    }
`

StyledReviews.Body = styled.div`
    font-size: 14px;
    a {
        text-decoration: underline;
        color: #008FD5;
        &:hover {
        }
    }
`
StyledReviews.Link = styled.p`
    display: flex;
    text-align: rignt;
    justify-content: end;
    margin-top: 1rem;
`
StyledReviews.Rating = styled.div`
    display: flex;
    align-items: center;
    gap: .2rem;
    margin-bottom: 1rem;
    justify-content: center;
`
StyledReviews.Wrap = styled.div`
    margin: ${p => p.white? '3rem 0 0 0' : '2rem 0'};
`

export default StyledReviews