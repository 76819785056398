import styled  from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Service = styled.div`
    position: relative;
    display: flex;
    ${maxWidth(vars.breakpoints.tablet - 1)`
        display: block;
    `}
`

Service.Left = styled.div`
    flex: 1;
    margin-right: 92px;
    ${maxWidth(vars.breakpoints.sDesktop)`
        margin-right: 48px;
    `}
    ${maxWidth(vars.breakpoints.tablet - 1)`
        margin: 0 0 32px;
    `}
`

Service.Download = styled.div`
    display: flex;
    align-items: center;
    margin-top: 32px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: ${vars.theme.red};
    cursor: pointer;
    svg {
        margin-right: 16px;
    }
    ${maxWidth(vars.breakpoints.tablet - 1)`
        
  `}
`

Service.Inner = styled.div`
    flex: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    ${maxWidth(vars.breakpoints.lMobile)`
        grid-template-columns: 1fr;
    `}
`

export default Service
