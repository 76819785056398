import { StyledUseful, StyledTitle } from '@/components/styles'
import { SvgIcon } from '@/components'

export default function ({ img, title, text, link }) {
    return (
        <StyledUseful.Card>
            <StyledTitle small mb="small">
                {title}
            </StyledTitle>
            <StyledUseful.Text grey>{text}</StyledUseful.Text>
            <StyledUseful.Link>
                <a href={link} target="_blank" />
                <SvgIcon name="link" width="24" height="24" />
                <span>Перейти на сайт</span>
            </StyledUseful.Link>
            <StyledUseful.Img>
                <img src={img} alt="" />
            </StyledUseful.Img>
        </StyledUseful.Card>
    )
}
