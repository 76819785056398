import { StyledInput } from '@/components/styles'
import { InputWrapper } from '@/components'

export default function ({
    children,
    label,
    type,
    error,
    value,
    placeholder,
    measure,
    as,
    mb,
    size,
    onChange,
    onClick,
    onBlur,
    inputRef,
    ...props
}) {
    return (
        <InputWrapper label={label} error={error} mb={mb}>
            <StyledInput
                type={type ? type : 'text'}
                placeholder={placeholder}
                value={value}
                size={size}
                onChange={(e) => onChange(e.target.value)}
                onClick={onClick}
                onBlur={onBlur}
                error={error}
                as={as}
                ref={inputRef}
                small={!label}
                {...props}
            />
            {measure && <StyledInput.Measure>{measure}</StyledInput.Measure>}
            {children}
        </InputWrapper>
    )
}
