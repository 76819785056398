import { useState, useEffect } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledNews,
    StyledTitle,
    StyledButton,
} from '@/components/styles'
import { Tabs, NewsCard } from '@/components'
import { useRouter } from 'next/router'
import api from '../../lib/api'

export default function ({ articles: initialArticles, pages, tab, setTab }) {
    const router = useRouter()
    const query = router.query

    const [isLoading, setIsLoading] = useState(false)
    const [articles, setArticles] = useState([])
    const [page, setPage] = useState(1)

    const handleChange = (field, value) => {
        const { page, ...params } = query
        let newQuery = { ...params, [field]: value }

        field === 'tab' && setTab(value)

        router.push(
            { pathname: router.pathname, query: newQuery },
            { pathname: location.pathname, query: newQuery },
            { shallow: true },
        )
    }

    const fetchArticles = async () => {
        setIsLoading(true)
        const { data } =
            tab === 'media'
                ? await api.articles.massMedia({ page: page + 1 })
                : await api.articles.list({ page: page + 1 })
        setPage(page + 1)

        setArticles([...articles, ...data])
        setIsLoading(false)
    }

    useEffect(() => {
        query.tab
            ? setTab(query.tab)
            : router.push(
                  { pathname: router.pathname, query: { tab: 'news' } },
                  { pathname: location.pathname, query: { tab: 'news' } },
                  { shallow: true },
              )
    }, [])

    useEffect(() => {
        setArticles(initialArticles)
    }, [initialArticles])

    useEffect(() => {
        setPage(1)
    }, [tab])

    return (
        <StyledSection pt="x-small" pb="medium">
            <StyledContainer>
                <Tabs
                    tabs={[
                        { id: 'news', title: 'Новости' },
                        { id: 'media', title: 'СМИ о нас' },
                    ]}
                    tab={tab}
                    setTab={(id) => handleChange('tab', id)}
                    small
                    mb="large"
                />
                <StyledTitle large as="h1">{tab === 'news' ? 'Новости' : 'СМИ о нас'}</StyledTitle>
                <StyledNews mb={page < pages}>
                    {tab === 'news' &&
                        articles.map((item) => (
                            <NewsCard
                                key={item.id}
                                img={item.preview}
                                title={item.title}
                                views={item.views}
                                date={item.publication_date}
                                slug={item.slug}
                            />
                        ))}
                    {tab === 'media' &&
                        articles.map((item) => (
                            <NewsCard
                                key={item.id}
                                img={item.preview}
                                title={item.title}
                                link={item.link}
                                url={item.url}
                            />
                        ))}
                </StyledNews>
                {page < pages && (
                    <StyledButton bg="grey" center onClick={fetchArticles} isLoading={isLoading}>
                        Показать больше
                    </StyledButton>
                )}
            </StyledContainer>
        </StyledSection>
    )
}
