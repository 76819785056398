import { useState, useRef } from 'react'
import { StyledButton, StyledForm } from '@/components/styles'
import { Form, Input, PhoneInput, Modal } from '@/components'
import useForm from '../../lib/hooks/form'
import api from '../../lib/api'

const initialValues = { name: '', phone: '', message: '' }

export default function ({ office, isOpen, onClose }) {
    const nameRef = useRef()

    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)

    function onSubmit() {
        form.submit(async () => {
            await api.directorRequest({
                department_id: office.id,
                ...form.values,
            })
            form.setValues(initialValues)
            form.setErrors({})
            onClose(false)
            setSuccess(true)
        })
    }

    function onOpen() {
        nameRef.current && nameRef.current.focus()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Напишите сообщение директору отделения."
                text="Укажите свои данные, чтобы мы смогли с вами связаться и ответить на все вопросы."
                onAfterOpen={onOpen}
            >
                <Form onSubmit={onSubmit}>
                    <StyledForm.Inner>
                        <Input
                            label="Имя"
                            placeholder="Введите имя"
                            inputRef={nameRef}
                            mb="medium"
                            {...form.input('name')}
                        />
                        <PhoneInput
                            label="Телефон"
                            placeholder="Введите телефон"
                            mb="medium"
                            {...form.input('phone')}
                        />
                    </StyledForm.Inner>
                    <Input label="Сообщение" as="textarea" {...form.input('message')} />
                    <StyledButton width="full" disabled={form.isLoading} isLoading={form.isLoading}>
                        Отправить
                    </StyledButton>
                </Form>
            </Modal>
            <Modal
                small
                isOpen={success}
                onClose={setSuccess}
                title="Ваши данные были успешно отправлены"
                text="В ближайшее время с вами свяжется директор отделения и ответит на все ваши вопросы."
            />
        </>
    )
}
