import { useState, useEffect, useRef } from 'react'
import { StyledCityChange, StyledInput } from '@/components/styles'
import { Modal, Tabs, InputWrapper } from '@/components'
import api from '@/lib/api'
import { DebounceInput } from 'react-debounce-input'
import useForm from '@/lib/hooks/form'
import { useCity } from '@/contexts/CityContext'

export default function ({ isOpen, onClose }) {
    const { setCityCookie, areas: initialAreas, cities: initialCities } = useCity()
    const inputRef = useRef()

    const [activeArea, setActiveArea] = useState('all')
    const [{ areas, cities }, setAreas] = useState({ areas: initialAreas, cities: initialCities })

    const form = useForm({ search: '' })

    const fetchAreas = async (search) => {
        const data = await api.areas({ search })
        setAreas({ areas: data.areas, cities: data.cities })
    }

    useEffect(() => {
        areas && !areas.find((area) => area.id === activeArea) && setActiveArea('all')
    }, [areas])

    function onOpen() {
        inputRef.current && inputRef.current.focus()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} onAfterOpen={onOpen} title="Выберите город">
            <InputWrapper label="Название города" mb="medium">
                <DebounceInput
                    element={StyledInput}
                    minLength={2}
                    debounceTimeout={400}
                    value={form.values.search}
                    onChange={(event) => fetchAreas(event.target.value)}
                    inputRef={inputRef}
                />
            </InputWrapper>
            <Tabs
                tabs={[{ id: 'all', title: 'Все области' }, ...areas]}
                tab={activeArea}
                setTab={setActiveArea}
                grey
            />
            <StyledCityChange.Cities>
                {!!areas.length
                    ? (activeArea !== 'all' &&
                      areas.find((area) => area.id === activeArea)?.cities?.length > 0
                          ? areas.find((area) => area.id === activeArea)?.cities
                          : cities
                      ).map((item) => (
                          <StyledCityChange.City
                              key={item.id}
                              onClick={() => {
                                  setCityCookie(item)
                                  onClose(false)
                              }}
                          >
                              {item.title}
                          </StyledCityChange.City>
                      ))
                    : 'Города не найдены'}
            </StyledCityChange.Cities>
        </Modal>
    )
}
