import { StyledForm } from '@/components/styles'

export default function ({ onSubmit, ...props }) {
    return (
        <StyledForm
            {...props}
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
            }}
        />
    )
}
