import styled, { css } from 'styled-components'
import { maxWidth, minWidth, rgba } from '@/assets/styles/mixins.css.js'
import vars from '@/assets/styles/vars'

const Header = styled.header`
    position: sticky;
    top: 0;
    z-index: 6;
    transition: all .5s;
    ${p => p.dark ? css`
        background: #112F43;
        color: #fff;
    ` : css`
        background: #fff;
    `}
    box-shadow: 0px 4px 32px rgba(138, 140, 149, 0.1);
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.contacts && "margin-bottom: 36px;"}
        top: 0;
        transition: all .3s;
        ${p => p.desktop && "display: none;"}
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        box-shadow: 0px 4px 24px rgba(13, 14, 40, 0.08);
    `}
`

Header.Inner = styled.div`
    display: flex;
    align-items: center;
    ${p => p.contacts && css`
        position: relative;
        background: #fff;
    `}
    z-index: 6;
`

Header.Top = styled.div`
    border-bottom: 1px solid ${p => p.dark ? "rgba(255,255,255,.15)" : vars.theme.outlineGrey};
    ${maxWidth(vars.breakpoints.tablet)`
        margin: 0 -30px;
        padding: 0 30px;
        ${p => !p.dark && `background: ${vars.theme.lightGrey};`}
    `}
    ${maxWidth(vars.breakpoints.mTablet)`
        margin: 0 -20px;
        padding: 0 20px;
    `}
`

Header.Types = styled.div`
    display: flex;
    align-items: center;
    ${maxWidth(vars.breakpoints.tablet)`
        ${p => p.contacts && "display: none;"}
    `}
`

Header.Type = styled.div`
    position: relative;
    &:not(:last-child) {
        margin-right: 24px;
    }
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${p => p.active ? p.white ? "#fff" : vars.theme.darkGrey : p.white ? "rgba(255,255,255,.35)" : vars.theme.grey};
    letter-spacing: 0.04em;
    text-transform: uppercase;
    transition: .3s;
    ${p => p.active && css`
        padding: 16px 0;
        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${p => p.white ? "#fff" : vars.theme.darkGrey};
        }
    `}
    a {
        display: block;
        padding: 16px 0;
    }
    &:hover {
        color: ${vars.theme.darkGrey};
    }
    ${maxWidth(vars.breakpoints.tablet)`
        font-size: 11px;
        ${p => p.active && css`
            padding: 12px 0;
        `}
        a {
            padding: 12px 0;
        }
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        ${p => p.active ? css`
            display: flex;
            padding-right: 16px;
            &:after {
                content: '';
                position: absolute;
                top: ${p => p.isOpen ? "calc(50% - 4px)" : "calc(50% - 8px)"};
                right: 0;
                left: auto;
                width: 8px;
                height: 8px;
                border-right: 1.5px solid ${vars.theme.grey};
                border-bottom: 1.5px solid ${vars.theme.grey};
                background: transparent;
                transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
                transition: all .3s;
            }
        ` : css`
            display: none;
        `}
    `}
    ${maxWidth(330)`
        font-size: 9px;
    `}
`

Header.Actions = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    span {
        display: inline-flex;
        align-items: center;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        display: none;
    `}
`

Header.Action = styled.div`
    display: ${p => p.mobile ? "none" : "flex"};
    align-items: center;
    &:not(:last-child) {
        margin-right: 32px;
    }
    padding: 15px 0;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.35px;
    cursor: pointer;
    svg {
        margin-right: 8px;
        fill: ${vars.theme.grey};
    }
    & > div > span {
        display: flex;
        align-items: center;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        display: ${p => p.mobile ? "flex" : "none"};
        margin-left: auto;
        &:not(:last-child) {
            margin-right: 16px;
        }
        font-size: 12px;
        line-height: 16px;
    `}
`

Header.Logo = styled.div`
    flex-shrink: 0;
    margin-right: 32px;
    font-size: 0;
    svg {
        width: 260px;
        height: 60px;
        fill: ${p => p.white ? "#fff" : "#1B1918"};
        transition: .3s;
    }
    ${p => p.mobile && css`
        display: none;
    `}
    ${maxWidth(vars.breakpoints.sDesktop)`
        margin-right: 20px;
        svg {
          width: 160px;
          height: 44px;
        }
    `}
    ${maxWidth(vars.breakpoints.tablet)`
        svg {
          width: 140px;
          height: 32px;
        }
        ${p => p.desktop && css`
            display: none;
        `}
        ${p => p.mobile && css`
            display: flex;
            svg {
                width: 32px;
            }
        `}
    `}
    ${maxWidth(vars.breakpoints.sMobile - 1)`
        svg {
          width: 120px;
        }
        ${p => p.mobile && css`
            svg {
                width: 32px;
            }
        `}
    `}
`

Header.ContactToggler = styled.div`
    display: none;
    font-size: 0;
    ${maxWidth(vars.breakpoints.mMobile)`
        position: fixed;
        bottom: ${p => p.top ? "154px" : "16px"};
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 58px;
        background: ${p => p.isOpen ? "#fff" : vars.theme.red};
        box-shadow: 0px 4px 22px rgba(61, 75, 86, 0.08);
        border: 6px solid ${p => p.isOpen ? "rgba(255, 255, 255, 0.25)" : "rgba(241, 80, 69, 0.25)"};
        background-clip: content-box;
        border-radius: 50%;
        z-index: ${p => p.isOpen ? 6 : 4};
        transition: all .3s;
    `}
    ${maxWidth(390)`
        bottom: ${p => p.top ? "170px" : "16px"};
    `}
`

Header.ContactIcon = styled.div`
    font-size: 0;
    ${maxWidth(vars.breakpoints.mMobile)`
        position: fixed;
        bottom: 16px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 58px;
        background: ${vars.theme.red};
        border-radius: 50%;
        opacity: 0;
        transition: bottom 0.4s ease-in, opacity 0.3s linear;
    `}
`

Header.ContactWrapper = styled.div`
    display: none;
    font-size: 0;
    ${maxWidth(vars.breakpoints.mMobile)`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      overflow: hidden;
      pointer-events: none;
      z-index: 4;
      visibility: hidden;
      opacity: 0;
      cursor: pointer;
      background: ${rgba("#1A1C21", 0.5)};
      transition: visibility 0s 0.2s, opacity 0.2s ease;
      -ms-scroll-chaining: none;
      overscroll-behavior: contain;
      -webkit-overflow-scrolling: touch;
      user-select: none;
      
      ${props =>
        props.isVisible &&
        css`
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            z-index: 6;
            transition: visibility 0s, opacity 0.35s ease;
    
            ${Header.ContactIcon} {
              &:nth-child(1) {
                bottom: ${p => p.top ? "224px" : "86px"};
              }
              &:nth-child(2) {
                bottom: ${p => p.top ? "294px" : "156px"};
              }
              opacity: 1;
              transition: bottom 0.3s ease-out, opacity 0.3s linear;
            }
      `}
    `}
    ${maxWidth(390)`
      ${props =>
        props.isVisible &&
        css`
            ${Header.ContactIcon} {
              &:nth-child(1) {
                bottom: ${p => p.top ? "240px" : "86px"};
              }
              &:nth-child(2) {
                bottom: ${p => p.top ? "310px" : "156px"};
              }
            }
      `}
    `}
`

Header.Phone = styled.div`
    margin-left: 32px;
    font-size: 14px;
    line-height: 16px;
    
    span {
        cursor: pointer;
        &:hover {
            opacity: .8;
        }
    }
    ${maxWidth(vars.breakpoints.tablet)`
        margin-left: auto;
        text-align: right;
        flex-basis: 0;
    `}
`

Header.PhoneDropdown = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    width: 232px;
    padding: 16px;
    background: ${p => p.dark ? "#112F43" : "#FFFFFF"};
    border: 1px solid ${p => p.dark ? vars.theme.darkGrey : vars.theme.outlineGrey};
    box-shadow: 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 4px;
    color: ${p => p.dark ? "#fff" : "#363940"};
    z-index: 2022;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    pointer-events: ${p => p.isOpen ? "all" : "none"};
    transition: ${p => p.isOpen ? "visibility 0s, opacity 0.35s ease" : "visibility 0s 0.35s, opacity 0.35s ease"};
    a {
        display: block;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    ${maxWidth(vars.breakpoints.tablet)`
        top: calc(100% - 10px);
    `}
`
Header.PhoneItem= styled.a`
    white-space: nowrap;
    &:hover {
        color: rgb(241, 80, 69);
        text-decoration: underline;
    }
` 

Header.Lk = styled.div`
    flex-shrink: 0;
    display: none;
    font-size: 0;
    
    svg {
        fill: #B5BFCD;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        display: block;
    `}
`

Header.Messenger = styled.div`
    ${maxWidth(vars.breakpoints.tablet)`
        margin-right: 1rem;
        flex-basis: 100%;
        flex-shrink: 2;
        text-align: right;
    `}
    ${minWidth(vars.breakpoints.tablet)`
        margin-left: 1rem;
    `}
`

export default Header
