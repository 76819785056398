import { useState } from 'react'
import { StyledSection, StyledContainer, StyledTitle, StyledButton } from '@/components/styles'
import { Map, ContactsCity } from '@/components'
import { useRouter } from 'next/router'

export default function ({ cities }) {
    const router = useRouter()
    const view = router.query.view

    const [openedCities, setOpenedCities] = useState([cities[0].id])

    const resetSearch = () => {
        const { search, ...params } = router.query
        let newQuery = { ...params }

        router.push(
            { pathname: router.pathname, query: newQuery },
            { pathname: location.pathname, query: newQuery },
            { shallow: false },
        )
    }

    return (
        <>
            {view === 'map' && (
                <Map
                    placemarkList={cities.reduce((acc, city) => [...acc, ...city.departments], [])}
                    zoom="7"
                />
            )}
            {view === 'list' && (
                <StyledSection pt="medium" pb="medium">
                    <StyledContainer>
                        <StyledSection.Top>
                            <StyledTitle large mb="none" as="h1">
                                Отделения «Центр Финансовых консультаций»
                            </StyledTitle>
                            {router.query.search?.length > 0 && (
                                <StyledButton bg="grey" redText onClick={resetSearch}>
                                    Все города
                                </StyledButton>
                            )}
                        </StyledSection.Top>
                        {cities?.length > 0 ? (
                            cities.map((city) => (
                                <ContactsCity
                                    key={city.id}
                                    title={city.title}
                                    offices={city.departments}
                                    isOpen={openedCities.includes(city.id)}
                                    onClick={() =>
                                        setOpenedCities(
                                            openedCities.includes(city.id)
                                                ? openedCities.filter((item) => item !== city.id)
                                                : [...openedCities, city.id],
                                        )
                                    }
                                />
                            ))
                        ) : (
                            <StyledTitle small>Городов не найдено.</StyledTitle>
                        )}
                    </StyledContainer>
                </StyledSection>
            )}
        </>
    )
}
