import { StyledSEO, StyledTitle, StyledText } from '@/components/styles'
import { useEffect, useRef, forwardRef } from 'react'

export default function () {
    const textBlock = useRef(null)
    const moreButton = useRef(null)

    useEffect(() => {
        moreButton.current.addEventListener('click', () => {
            let status = moreButton.current.dataset.status
            let yPos
            if(status === 'closed') {
                yPos = parseInt(textBlock.current.offsetTop + textBlock.current.offsetHeight - 180)
                moreButton.current.innerHTML = 'Скрыть текст'
                moreButton.current.dataset.status = 'opened'
            } else {
                moreButton.current.innerHTML = 'Читать далее'
                moreButton.current.dataset.status = 'closed'
                yPos = parseInt(textBlock.current.offsetTop - 180)
            }
            textBlock.current.classList.toggle('is-open');
            moreButton.current.classList.toggle('is-open');
            scroll(0, Math.abs(yPos))
        });
    }, [textBlock, moreButton]);

    return (
        <StyledSEO>
            <StyledTitle>Кредитный брокер «Центр финансовых консультаций»</StyledTitle>
            <StyledSEO.Time>
                <span>Время чтения ~4 минуты</span>
            </StyledSEO.Time>
            <StyledSEO.Grid ref={textBlock}>
                <StyledText>
                    <p><strong>Кредитный брокер</strong> – это компетентный финансовый консультант, который является посредником между кредитным учреждением и потенциальным кредитополучателем.</p>
                    <StyledSEO.Subtitle>Что делает кредитный брокер?</StyledSEO.Subtitle>
                    <StyledSEO.List>
                        <StyledSEO.ListItem>подробно расшифровывает и анализирует кредитную историю;</StyledSEO.ListItem>
                        <StyledSEO.ListItem>тщательное изучает пакет документов клиента;</StyledSEO.ListItem>
                        <StyledSEO.ListItem>выявляет и устраняет потенциальные риски и «подводные камни»;</StyledSEO.ListItem>
                        <StyledSEO.ListItem>подбирает надёжного кредитодателя с максимальной вероятностью одобрения и лучшими условиями;</StyledSEO.ListItem>
                        <StyledSEO.ListItem>консультирует по выбору способа и графика погашения кредита с минимальной переплатой.</StyledSEO.ListItem>
                    </StyledSEO.List>
                    <StyledSEO.Subtitle>Кто обращается к кредитным брокерам?</StyledSEO.Subtitle>
                    <p>Консультационные услуги по кредитованию помогут:</p>
                    <StyledSEO.List>
                        <StyledSEO.ListItem><strong>Клиентам, желающим ускорить и обезопасить процесс получения кредита</strong>. Наш менеджер проведёт полный анализ рынка: от процентных ставок до надёжности банковских учреждений. Он подберёт условия кредитования под конкретный случай. Мы работаем без штампов и заготовок. Помните, что даже самые опытные консультанты не могут гарантировать 100% одобрение кредита из-за ряда факторов, влияющих на решение банка.</StyledSEO.ListItem>
                        <StyledSEO.ListItem><strong>Банкам</strong>. Брокеры часто сотрудничают с кредитными организациями на взаимовыгодных условиях.</StyledSEO.ListItem>
                        <StyledSEO.ListItem><strong>Клиентам, которым не удаётся получить кредит самостоятельно.</strong> Получить кредит с плохой кредитной историей или низким кредитным рейтингом сложно. Брокер изучит личное дело и предложит альтернативные варианты, например привлечение поручителей или предоставление залога. Это увеличит шансы на одобрение. Однако следует учитывать, что абсолютной гарантии одобрения в таких случаях нет.</StyledSEO.ListItem>
                    </StyledSEO.List>
                    <StyledSEO.Subtitle>Какая стоимость услуг кредитного брокера?</StyledSEO.Subtitle>
                    <p>Услуги кредитного брокера, как правило, предоставляются на платной основе. Обычно брокеры взимают комиссию в процентах от суммы кредита. В <em>«Центре финансовых консультаций»</em> <strong>размер комиссии составляет около 10%</strong>, в зависимости от суммы кредита. Чем сложней ситуация и меньше сумма, тем выше процент и наоборот. Чем выше сумма и лучше кредитная история, тем специалисту проще подобрать более выгодное предложение и сделать ниже ставку.</p>
                    <p>К сожалению, на рынке встречаются недобросовестные специалисты, которые берут предоплату за свои услуги. Так часто работают мошенники: клиент не получает обещанного кредита, а предоплата не возвращается.</p>
                    <p>«Центр финансовых консультаций» оказывает услуги <strong>без предварительной оплаты</strong>. Плата за услуги финансового консультанта взимается только после фактического получения клиентом одобренной суммы кредита в банке. Эта особенность гарантирует прозрачность и честность сотрудничества.</p>
                    <StyledSEO.Subtitle>Чем Вам поможет «Центр финансовых консультаций»?</StyledSEO.Subtitle>
                    <p>Кредитный брокер «Центр финансовых консультаций» – один из самых уважаемых экспертов в области кредитования на рынке Республики Беларусь. Мы отличаемся <strong>глубоким знанием условий кредитования от всех банков Беларуси, консультируем по вопросам кредитования физических и юридических лиц</strong>. В списке услуг разнообразные банковские продукты: от <strong>потребительских кредитов наличными</strong> до <strong>кредитов под залог недвижимости</strong>, а также <strong>бизнес-кредиты</strong>, <strong>рефинансирование</strong> и <strong>ипотечное кредитование</strong>.</p>
                    <p><em>Вот один из реальных примеров помощи в получении кредита.</em></p>
                    <p>Молодой человек жил в маленьком городе. Он был удовлетворён своей работой и жизнью. Однако, началась эпидемия коронавируса. Из-за введения карантинных мер его работодатель перевёл всех сотрудников на удалённый режим работы. Это вызвало у молодого человека стресс, так как у него был кредит, который нужно было погашать. В итоге, он не смог вовремя оплатить кредитные платежи. Это негативно сказалось на его кредитной истории и понизило кредитный рейтинг.</p>
                </StyledText>
                <StyledText>
                    <p>Спустя год молодому человеку вновь потребовался кредит. Однако он столкнулся с отказами от банков. В поисках решения он обратился к кредитному брокеру «Центр финансовых консультаций». После изучения его финансовой ситуации специалисты брокера подали заявки в подходящие банки и смогли добиться одобрения кредита на эксклюзивных условиях. Благодаря этому, молодой человек не только решил свои финансовые проблемы, но и стал клиентом надёжного и известного банка.</p>
                    <p><em>Вот ещё один случай.</em> Потенциальный заёмщик, изучая информацию о кредитах в интернете, среди ряда компаний выделил «Центр финансовых консультаций». Высокие оценки в отзывах и рекомендации знакомых убедили его в надёжности этой организации. Отличная возможность изучить выгодные кредитные предложения, процентные ставки и условия погашения задолженности.</p>
                    <p>Предоставив все необходимые документы, он продолжил заниматься своими делами, а специалисты брокера в это время подбирали лучшие варианты кредита. В итоге, клиенту были предложены несколько хороших предложений с различными процентными ставками и условиями кредитования. Он смог выбрать лучший вариант, не тратя время на бесцельные поиски. Результат работы – клиент получил предложение с наиболее привлекательной процентной ставкой – всего 8.7% годовых самостоятельно найти и получить такой кредит крайне сложно.</p>
                    <StyledSEO.Subtitle>Преимущества «Центра финансовых консультаций»:</StyledSEO.Subtitle>
                    <StyledSEO.List>
                        <StyledSEO.ListItem><strong>Бесплатная консультация</strong>. Во время бесплатной консультации специалисты попросят Вас рассказать подробнее о желаемом кредите - сумму, срок и процентную ставку. Сотрудники проверят Вашу кредитную историю и на основе полученных данных Вам будут предложены несколько вариантов кредита под требования. В случае, если кредит получить не получится, или подобранные варианты не подойдут, наши специалисты предложат альтернативные способы финансирования.</StyledSEO.ListItem>
                        <StyledSEO.ListItem>Бесплатная консультация также включает обзор финансовых учреждений, которые наилучшим образом соответствуют Вашим пожеланиям. Специалисты дадут Вам <strong>ценные рекомендации по выбору банка</strong>. Вы получите советы о том, в каких банковских учреждениях предпочтительнее оформлять кредит, а какие лучше избегать.</StyledSEO.ListItem>
                        <StyledSEO.ListItem>Оплата за услуги брокера производится <strong>только после одобрения кредита банком</strong>.</StyledSEO.ListItem>
                        <StyledSEO.ListItem>С нами Вы значительно <strong>экономите время</strong>, так как от Вас потребуется предоставить только необходимые документы.</StyledSEO.ListItem>
                        <StyledSEO.ListItem>Для удобства предусмотрена <strong>возможность личного обращения в одно из наших отделений или подачи заявки через интернет</strong>.</StyledSEO.ListItem>
                        <StyledSEO.ListItem>Стоимость услуг определяется индивидуально для каждой ситуации и обсуждается заранее. <strong>Оплата вознаграждения</strong> кредитного брокера предварительно согласовывается сторонами и <strong>происходит в форме процента от суммы одобренного кредита, обычно составляет около 10%</strong>. Для сравнения комиссия кредитного брокера на рынке доходит до 50% от суммы кредита. Таким образом, наша ставка является выгодной опцией за успешно оформленный кредит.</StyledSEO.ListItem>
                        <StyledSEO.ListItem>Кредитный брокер «Центр финансовых консультаций» отличается честностью и строгим соответствием законодательству. Мы не предлагаем «чёрные» методы работы с документами или обход банковских процедур. Помните, любые попытки обмана неизбежно обнаруживаются в ходе банковской проверки. Если наши специалисты видят, что одобрение кредита невозможно, они честно сообщат об этом клиенту. <strong>«Центр финансовых консультаций» не делает нереальных обещаний и чётко следует профессиональной этике.</strong></StyledSEO.ListItem>
                        <StyledSEO.ListItem>Сотрудники «Центра финансовых консультаций» обладают значительным <strong>опытом работы с банковской сферой и глубоко знакомы с её внутренними процессами</strong>. Их компетентность и опыт способствуют высокому рейтингу компании и обеспечивают <strong>высокий процент одобрения кредитов нашим клиентам (более 87%)</strong>.</StyledSEO.ListItem>
                    </StyledSEO.List>
                    <p><em>«Центр финансовых консультаций»</em> – проверенное решение для тех, кто ищет выгодные услуги кредитного брокера.</p>
                </StyledText>
            </StyledSEO.Grid>
            <StyledSEO.More><span ref={moreButton} data-status="closed">Читать далее</span></StyledSEO.More>
        </StyledSEO>
    )
}