'use client';
import { useEffect, useRef, useState, createRef } from 'react'
import QuestionCard from '@/components/QuestionCard'
import { StyledCreditTest, StyledTitle, StyledText, StyledButton, StyledSection, StyledContainer} from '@/components/styles'
import numberToString from '@/lib/numberToString'

const testData = [
    {'question': 'Что такое кредит?', 'answers': [
        'сумма, выданная банком заёмщику в соответствии с условиями и размерами, оговоренными в договоре кредитования',
        'личные денежные средства, выделенные банком третьей стороне согласно условиям и объёму, указанным в договоре займа',
        'заимствованные средства, переданные банком другому лицу'
    ]},
    {'question': 'Какими методами можно гарантировать выполнение условий по кредиту?', 'answers': [
        'гарантирование выполнения условий кредита возможно через гарантии от физических лиц, пеня за нарушение условий, предоставление в залог собственности заёмщика или кого-то ещё (включая жилые здания или другие виды недвижимости, такие как квартиры и гаражи), страховку от неоплаты кредита, а также другие методы, которые разрешены законами Республики Беларусь или указаны в договоре;',
        'через личное поручительство и залог как недвижимости, так и движимого имущества',
        'посредством залога имущества, будь то недвижимость или движимое имущество'
    ]},
    {'question': 'Какие способы оформления договора поручительства существуют?', 'answers': [
        'через банк или с нотариальным удостоверением',
        'исключительно через банк',
        'по месту проживания'
    ]},
    {'question': 'Что означает понятие «кредитоспособность кредитополучателя»?', 'answers': [
        'возможность физического лица полностью и своевременно выполнять условия кредитного соглашения в соответствии с договором и законодательством',
        'способность организации или частного лица, включая индивидуальных предпринимателей, целенаправленно (в полном объёме и в срок) использовать предоставленный кредит',
        'возможность организации или частного лица, включая индивидуальных предпринимателей, полностью выполнять условия кредитного соглашения в удобный для себя срок'
    ]},
    {'question': 'Необходимо ли лично приходить в банк для подачи заявления на потребительский кредит?', 'answers': [
        'нет, можно подать заявку дистанционно через официальный сайт банка, мобильное приложение или интернет-банкинг',
        'да, требуется личное присутствие в банке',
        'можно подать заявку через почтовые отделения (отделения связи)'
    ]},
    {'question': 'Какие варианты доступны для погашения кредита наличными?', 'answers': [
        'в кассе банка',
        'в кассе банка или через банковские инфокиоски с функцией приёма наличных (Cash-in)',
        'в кассе банка, через инфокиоски или с помощью мобильного банкинга'
    ]},
    {'question': 'Можно ли осуществить досрочное погашение кредита?', 'answers': [
        'кредит может быть погашен в соответствии со сроками, указанными в кредитном договоре',
        'досрочное погашение возможно, но только с разрешения кредитора',
        'досрочное погашение кредита разрешено согласно условиям, оговоренным в кредитном договоре'
    ]},
    {'question': 'Какие кредиты считаются краткосрочными?', 'answers': [
        'кредиты, которые должны быть погашены в течение 6 месяцев согласно кредитному договору, включая кредиты по возобновляемым линиям и овердрафты (исключая кредиты, где хотя бы один транш должен быть возвращен через год и более)',
        'кредиты с условием полного погашения в течение 1 года (включительно), а также возобновляемые кредитные линии и овердрафты, за исключением тех, где минимальный срок погашения одного транша превышает один год',
        'кредиты, подлежащие погашению в течение 3 лет согласно первоначальному кредитному договору'
    ]},
    {'question': 'Где можно оформить кредит на покупку недвижимости на условиях, установленных банком?', 'answers': [
        'в любом отделении банка;',
        'в любом банковском учреждении',
        'в специализированной ипотечной службе банка (ипотечный офис в Минске)'
    ]},
    {'question': 'На что обращать внимание при оформлении кредита?', 'answers': [
        'на условия кредитования, включая сроки и порядок выдачи, процентные ставки, условия их выплаты, а также возможность досрочного погашения и ответственность за нарушение условий договора',
        'на общую сумму кредита',
        'на то, будет ли кредит выдан наличными'
    ]},
    {'question': 'Что представляет собой кредитная история?', 'answers': [
        'информация о личной финансовой активности и принятых обязательствах по кредитам',
        'данные, хранящиеся в Национальном банке, перечень которых описан в законе от 10 ноября 2008 года №441-З, отражающие финансовое поведение и исполнение обязательств по кредитам',
        'информация, хранящаяся в Национальном банке, установленная законом от 10 ноября 2008 года №441-З, характеризующая финансовую деятельность и соблюдение кредитных обязательств'
    ]},
]

const resutTexts = [
    'Вам не хватает знаний. Но это не беда. Приходите в "Центр финансовых консультаций" и мы всё подробно и понятно объясним, поможем подобрать выгодный кредит. Звоните нам по телефону <a href="tel:+375298883222">+375(29)888-3-222</a> или приходите к нам по адресу: г.Минск, 3-й пер-к Можайского, 11, пом. 107.',
    'Вы знаете немало. Но всё же есть вопросы, в которых стоит подтянуть свои знания. Чтобы лучше разбираться в кредитах приходите в "Центр финансовых консультаций" и мы ответим на все Ваши вопросы, подберём самое выгодное предложение по кредиту. Звоните нам по телефону <a href="tel:+375298883222">+375(29)888-3-222</a> или приходите к нам по адресу: Минск, пр-кт Независимости, д. 58, к. 4, пом. 435.',
    'Вы молодец! У Вас отличная финансовая грамотность! Если Вам нужен кредит, приходите к нам в "Центр финансовых консультаций". Звоните нам по телефону <a href="tel:+375298883222">+375(29)888-3-222</a> или приходите к нам по адресу: г.Минск, 3-й пер-к Можайского, 11, пом. 107.'
    ]

const testResult = [0, 0, 0, 0, 0, 1, 2, 1, 2, 0, 2]

export default function () {
    const [show, setShow] = useState(false)
    const [step, setStep] = useState(0)
    const [trueAnswers, setTrueValue] = useState(0)
    const [text, setText] = useState(0)
    const body = useRef();
    const result = useRef();

    const handleClick = (e) => {
        e.stopPropagation();
        if(e.target.value == testResult[e.target.id]) {
            setTrueValue(trueAnswers + 1)
        }
        setStep(step + 1)
        let evt = new Event('change')
        e.target.parentElement.parentElement.dispatchEvent(evt)

        setTimeout(() => body.current.scrollIntoView(false), 100);
        if(step === 10) {
            setTimeout(() => result.current.scrollIntoView(false), 110);
        }
    }

    useEffect(() => {
        if(trueAnswers >= 5 && trueAnswers < 9 ) {
            setText(1);
        } else if (trueAnswers >= 9) {
            setText(2);
        }
    }, [trueAnswers])

    return (
        <StyledSection pt="x-small">
            <StyledContainer sizeS>
                <StyledCreditTest>
                    <StyledTitle>Тест «Как одолжить деньги»</StyledTitle>
                    {!show && <StyledButton onClick={() => setShow(!show)}>Пройти тест</StyledButton>}
                    <StyledCreditTest.Body ref={body} className={!show ? 'is-hidden' : null}>
                    {testData.map((item, i) => (
                        <StyledCreditTest.Item className={step < i ? 'is-hidden' : null} key={i}>
                            <QuestionCard
                                question={item}
                                key={`block-${i}`}
                                num = {i}
                                trueAnswer={testResult[i]}
                                func={handleClick}
                            />
                        </StyledCreditTest.Item>
                    ))}
                    </StyledCreditTest.Body>
                    {(step >= 11) && <StyledCreditTest.Result>
                        <StyledText ref={result}>
                            <p>У вас <strong>{trueAnswers}</strong> {numberToString(trueAnswers, ['правильный ответ', 'правильных ответа', 'правильных ответов'])} из 11 или <strong>{(trueAnswers/11*100).toFixed(2)}%</strong></p>
                            <p dangerouslySetInnerHTML={{ __html: resutTexts[text]}}/>
                        </StyledText>
                    </StyledCreditTest.Result>}
                </StyledCreditTest>
            </StyledContainer>
        </StyledSection>
    )
}