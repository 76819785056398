import { useState, useRef, useEffect } from 'react'
import { StyledButton, StyledForm, StyledText, StyledModal } from '@/components/styles'
import { Form, Input, PhoneInput, Modal, CodeModal, ConfirmationModal } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'

const initialValues = { name: '', phone: '', registration: true }

export default function ({ isOpen, onClose, signIn }) {
    const inputRef = useRef()

    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)
    const [codeError, setCodeError] = useState({ visible: false, text: '' })

    useEffect(() => {
        if (codeError.text) {
            const timeout = setTimeout(() => setCodeError({ visible: false, text: '' }), 60 * 1000)
            // clearTimeout(timeout)
        }
    }, [codeError.text])

    function onSubmit() {
        if (!codeError.text) {
            form.submit(
                async () => {
                    try {
                        await api.auth.register(form.values)
                    } catch (e) {
                        if (e.errors.phone || e.errors.name) {
                            form.setErrors({ phone: e.errors.phone, name: e.errors.name })
                        } else {
                            await api.auth.sms(form.values)
                            form.setErrors({})
                            onClose(false)
                            setSuccess(true)
                        }
                    }
                },
                (e) => {
                    if (e.errors?.phone == 'Смс код можно запрашивать раз в минуту.') {
                        onClose(false)
                        setSuccess(true)
                        setCodeError({
                            visible: success,
                            text: 'Смс код можно запрашивать раз в минуту.',
                        })
                    } else {
                        form.setErrors(e.errors)
                    }
                },
            )
        } else if (codeError.text && !success) {
            onClose(false)
            setSuccess(true)
        } else {
            setCodeError({ visible: success, text: 'Смс код можно запрашивать раз в минуту.' })
        }
    }

    function onOpen() {
        inputRef.current && inputRef.current.focus()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Регистрация аккаунта"
                text="Укажите некоторые данные для создания вашего аккаунта в Центре финансовых консультаций."
                onAfterOpen={onOpen}
                medium
                bottomComponent={
                    <>
                        <StyledText grey style={{ marginRight: '4px' }}>
                            Уже есть аккаунт?
                        </StyledText>
                        <StyledModal.Action onClick={signIn}>Войти</StyledModal.Action>
                    </>
                }
            >
                <Form onSubmit={onSubmit}>
                    <Input
                        label="ФИО"
                        placeholder="Фамилия Имя Отчество"
                        mb="medium"
                        white
                        inputRef={inputRef}
                        {...form.input('name')}
                    />
                    <PhoneInput
                        label="Телефон"
                        placeholder="Введите телефон"
                        mb="large"
                        withError
                        white
                        {...form.input('phone')}
                    />
                    <StyledButton width="full" disabled={form.isLoading} isLoading={form.isLoading}>
                        Регистрация
                    </StyledButton>
                </Form>
            </Modal>
            <CodeModal
                isOpen={success}
                onClose={setSuccess}
                phone={form.values.phone}
                name={form.values.name}
                repeatCode={onSubmit}
                codeError={codeError}
                goToPrevStep={(errors) => {
                    setSuccess(false)
                    onClose(true)
                    form.setErrors(errors)
                }}
            />
        </>
    )
}
