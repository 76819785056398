import { useState, useEffect } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledRequest,
    StyledTitle,
    StyledForm,
    StyledButton,
    StyledInput,
} from '@/components/styles'
import { Form, Input, PhoneInput, Checkbox, SvgIcon, Radio, Modal, RangeInput, Socials } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'
import createRequestConditions from '@/lib/createRequestConditions'

const income = [
    { id: 'employment', title: 'Работа по найму или работа без официального трудоустройства' },
    { id: 'employer', title: 'Доход с предпринимательской деятельности' },
    { id: 'pension', title: 'Пенсия' },
    { id: 'none', title: 'Нет дохода' },
]

const residence = [
    { key: 'citizen', value: 'Да, являюсь' },
    { key: 'residence', value: 'Не являюсь, но есть вид на жительство' },
    { key: 'none', value: 'Не являюсь' },
]

const credits = [
    { key: 1, value: 'Есть кредиты' },
    { key: 0, value: 'Нет кредитов' },
]

const creditsCount = [
    { key: 1, value: '1 кредит' },
    { key: 2, value: '2-3 кредита' },
    { key: 4, value: '4 и более кредитов' },
]

const delay = [
    { key: 'loan', value: 'Да, есть' },
    { key: 'loan_finished', value: 'Были, но я их погасил(-а)' },
    { key: 'none', value: 'Нет' },
]

const questions = [
    {
        type: 'real_estate',
        question: 'Какой вид недвижимости вас интересует?',
        values: [
            { key: 1, value: 'Новостройка' },
            { key: 2, value: 'Вторичное жилье' },
            { key: 3, value: 'Рефинансирование кредита на жилье' },
        ],
    },
    {
        type: 'car_loan',
        question: 'Какой вид кредита на транспорт вас интересует?',
        values: [
            { key: 1, value: 'Автомобиль с пробегом ' },
            { key: 2, value: 'Новый автомобиль' },
            { key: 3, value: 'Рефинансирование автокредита' },
            { key: 4, value: 'Коммерческий автомобиль' },
            { key: 5, value: 'Мототранспорт' },
        ],
    },
    {
        type: 'special_equipment',
        question: 'Какой вид спецтехники вас интересует?',
        values: [
            { key: 1, value: 'На трактор' },
            { key: 2, value: 'На минитрактор' },
            { key: 3, value: 'На комбайн' },
        ],
    },
    {
        type: 'refinancing',
        question: 'Выберите тип рефинансирования',
        values: [
            { key: 1, value: 'Частичное рефинансирование' },
            { key: 2, value: 'Полное рефинансирование' },
        ],
    },
]

const initialValues = {
    name: '',
    phone: '',
    email: '',
    income: [],
    credits: 1,
    nationality: '',
    delay: '',
    count: 0,
    agreement: 1,
}

export default function ({ title, values, form, blockRef, calculator, termType }) {
    const [view, setView] = useState('initial')
    const [step, setStep] = useState(1)
    const [question, setQuestion] = useState(null)
    const [success, setSuccess] = useState(false)

    const requestForm = useForm(initialValues)

    useEffect(() => {
        step === 2 && setStep(1)
    }, [title])

    useEffect(() => {
        calculator &&
            (calculator.record_type
                ? setQuestion(questions.find((item) => item.type == calculator.record_type))
                : calculator.type === 'refinancing' &&
                  setQuestion(questions.find((item) => item.type === 'refinancing')))
        setView('initial')
    }, [calculator])

    function onSubmit() {
        if (!requestForm.values.agreement) {
            requestForm.setErrors({ agreement: true })
        } else {
            requestForm.submit(async () => {
                const userId = Math.random().toString(36).substring(2)

                await api.record({
                    ...requestForm.values,
                    calculator_id: calculator.id,
                    credit_type: `Заявка на ${title}`,
                    credit_count: requestForm.values.credits === 1 ? requestForm.values.count : 0,
                    sum: values.sum,
                    pay_month_sum: values.monthSum,
                    prepaid: values.initialFee,
                    term_month: termType === 'month' ? values.term : null,
                    term_year: termType === 'year' ? values.term : null,
                    question: question?.question,
                    answer: requestForm.values.answer
                        ? question.values.find((item) => item.key === requestForm.values.answer)
                              .value
                        : null,
                    url: window.location.href,
                    user_id: userId,
                })

                const goalParams = { user_id: userId }
                // function goalCallback () {
                // console.log("запрос в Метрику успешно отправлен: USER_ID = " + userId)
                // }

                // ym(34977235, "reachGoal", "user_id", goalParams, goalCallback)
                // ym(34977235, 'reachGoal', 'user_id', goalParams)
                if(typeof ym !== 'undefined') {
                    ym(34977235, 'userParams', goalParams);
                }

                requestForm.setValues(initialValues)
                requestForm.setErrors({})
                setSuccess(true)
            })
        }
    }

    return (
        <StyledSection greyBackground pt="medium" pb="medium" ref={blockRef}>
            <StyledContainer sizeS>
                <Form onSubmit={onSubmit}>
                    <StyledTitle mb="medium">
                        Заявка на {title.slice(0, 1).toLowerCase() + title.slice(1)}
                    </StyledTitle>
                    {step === 1 &&
                        (view === 'initial' ? (
                            <StyledRequest.Top>
                                <StyledRequest.Conditions>
                                    {createRequestConditions(values).map(
                                        (item) =>
                                            isFinite(item.title) && (
                                                <StyledRequest.Condition key={item.id}>
                                                    <StyledRequest.ConditionValue>
                                                        {item.title} {item.measure}
                                                    </StyledRequest.ConditionValue>
                                                    <StyledRequest.ConditionTitle>
                                                        {item.text}
                                                    </StyledRequest.ConditionTitle>
                                                </StyledRequest.Condition>
                                            ),
                                    )}
                                </StyledRequest.Conditions>
                                <StyledRequest.TopButton>
                                    <StyledButton
                                        width="full"
                                        bg="grey"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setView('change')
                                        }}
                                    >
                                        <span>Изменить</span>
                                        <SvgIcon name="edit" width="20" height="20" />
                                    </StyledButton>
                                </StyledRequest.TopButton>
                            </StyledRequest.Top>
                        ) : (
                            <StyledForm.Inner>
                                <RangeInput
                                    label="Сумма"
                                    measure="BYN"
                                    min={calculator.points[0].amount_from}
                                    max={calculator.points[calculator.points.length - 1].amount_to}
                                    step={calculator.step}
                                    white
                                    {...form.input('sum')}
                                />
                                <RangeInput
                                    label="Срок"
                                    measure={termType === 'month' ? 'мес' : ['год', 'года', 'лет']}
                                    min={
                                        calculator.deadline_from?.month ??
                                        calculator.deadline_from.year
                                    }
                                    max={
                                        calculator.deadline_to?.month ?? calculator.deadline_to.year
                                    }
                                    white
                                    {...form.input('term')}
                                />
                            </StyledForm.Inner>
                        ))}
                    <StyledRequest.Step>Шаг {step} из 2</StyledRequest.Step>
                    <StyledRequest.Progress>
                        <StyledRequest.ProgressInner width={50 * step - 30} />
                    </StyledRequest.Progress>
                    <StyledRequest.ProgressText>
                        Увеличьте вероятность выдачи кредита <span>на {step === 1 ? 40 : 53}%</span>
                        , заполнив заявку полностью.
                    </StyledRequest.ProgressText>
                    {step === 1 && (
                        <>
                            <StyledRequest.Title>Введите контактные данные</StyledRequest.Title>
                            <Input
                                label="Фамилия, Имя, Отчество"
                                white
                                {...requestForm.input('name')}
                            />
                            <StyledForm.Inner>
                                <PhoneInput
                                    label="Телефон"
                                    white
                                    placeholder="Введите телефон"
                                    {...requestForm.input('phone')}
                                />
                                <Input
                                    label="E-mail (необязательно)"
                                    type="email"
                                    white
                                    placeholder="Введите E-mail"
                                    {...requestForm.input('email')}
                                />
                            </StyledForm.Inner>
                            {question && (
                                <Radio
                                    title={question.question}
                                    options={question.values}
                                    {...requestForm.radio('answer')}
                                />
                            )}
                            <StyledRequest.Separator />
                            <Checkbox
                                label="Я соглашаюсь с условиями и даю свое согласие на обработку и использование моих персональных данных, и разрешаю сделать запрос в бюро кредитных историй!"
                                grey
                                {...requestForm.checkbox('agreement')}
                            />
                            <StyledRequest.Bottom>
                                <StyledRequest.Guarantee>
                                    <SvgIcon name="guarantee" width="36" height="40" />
                                    Гарантируем сохранность ваших данных
                                </StyledRequest.Guarantee>
                                <StyledRequest.Buttons>
                                    <StyledButton width="full">Отправить заявку</StyledButton>
                                    <StyledButton
                                        width="full"
                                        bg="white"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (
                                                requestForm.validateFields([
                                                    'name',
                                                    'phone',
                                                    'email',
                                                    'agreement',
                                                ])
                                            ) {
                                                setStep(2)
                                            }
                                        }}
                                    >
                                        Продолжить
                                    </StyledButton>
                                </StyledRequest.Buttons>
                            </StyledRequest.Bottom>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <StyledRequest.Title>
                                Охарактеризуйте свой финансовый портрет
                            </StyledRequest.Title>
                            <StyledRequest.Separator />
                            <StyledInput.Title>Как вы получаете доход?</StyledInput.Title>
                            {income.map((item, idx) => (
                                <Checkbox
                                    key={item.id}
                                    id={item.id}
                                    label={item.title}
                                    mb={idx === income.length - 1 ? 'large' : 'small'}
                                    {...requestForm.checkboxArray('income', item.id)}
                                />
                            ))}
                            <Radio
                                title="Вы являетесь гражданином РБ?"
                                options={residence}
                                {...requestForm.radio('nationality')}
                            />
                            <StyledRequest.Title>
                                Опишите вашу кредитную ситуацию
                            </StyledRequest.Title>
                            <StyledRequest.Separator />
                            <Radio
                                type="switch"
                                title="Есть ли у вас текущие кредиты?"
                                options={credits}
                                {...requestForm.radio('credits')}
                            />
                            {!!requestForm.values.credits && (
                                <>
                                    <Radio
                                        type="tab"
                                        white
                                        title="Сколько у вас кредитов?"
                                        options={creditsCount}
                                        {...requestForm.radio('count')}
                                    />
                                    <Radio
                                        title="Есть ли у вас просрочки по кредитам?"
                                        options={delay}
                                        {...requestForm.radio('delay')}
                                    />
                                </>
                            )}
                            <StyledRequest.Bottom reverse>
                                <StyledRequest.Back onClick={() => setStep(1)}>
                                    <SvgIcon name="arrow-back" width="24" height="24" />
                                    Предыдущий шаг
                                </StyledRequest.Back>
                                <StyledRequest.SubmitButton>
                                    <StyledButton width="full">Отправить заявку</StyledButton>
                                </StyledRequest.SubmitButton>
                            </StyledRequest.Bottom>
                        </>
                    )}
                </Form>
            </StyledContainer>
            <Modal
                isOpen={success}
                onClose={setSuccess}
                small
                title="Спасибо! Мы скоро вам перезвоним"
                text="<p>Ваши данные были успешно отправлены. В скором времени с вами свяжется наш менеджер.</p><p>А пока Вы ожидаете, подпишитесь на <a href='https://t.me/byfin' target='_blank'>телеграм канал</a> с полезным контентом о финансах.<p>"
            >
                <Socials />
            </Modal>
        </StyledSection>
    )
}
