import { useState, useRef } from 'react'
import { StyledButton, StyledForm, StyledText, StyledModal } from '@/components/styles'
import { Form, Input, Modal } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'
import { useAuth } from '@/contexts/AuthContext'

export default function ({ isOpen, onClose }) {
    const { user, setUser } = useAuth()
    const initialValues = { name: user.name }

    const inputRef = useRef()

    const form = useForm(initialValues)

    function onSubmit() {
        form.submit(async () => {
            await api.updateProfile({
                user_data: form.values.name,
                id: user.id,
            })
            setUser({ ...user, name: form.values.name })
            form.setErrors({})
            onClose(false)
        })
    }

    function onOpen() {
        inputRef.current && inputRef.current.focus()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Данные профиля" onAfterOpen={onOpen} medium>
            <Form onSubmit={onSubmit}>
                <Input
                    label="ФИО"
                    placeholder="Фамилия Имя Отчество"
                    mb="large"
                    white
                    inputRef={inputRef}
                    {...form.input('name')}
                    onChange={(value) => value.length < 50 && form.setValues({ name: value })}
                />
                <StyledButton width="full" disabled={form.isLoading} isLoading={form.isLoading}>
                    Сохранить и продолжить
                </StyledButton>
            </Form>
        </Modal>
    )
}
