import { useMemo, useRef } from 'react'
import { StyledSection, StyledContainer } from '@/components/styles'
import { SliderBlock, UsefulCard } from '@/components'
import Slider from 'react-slick'

export default function ({ blocks }) {
    const sliderRef = useRef()

    const settings = useMemo(
        () => ({
            slidesToShow: blocks.length < 2 ? blocks.length : 2,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        variableWidth: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                    },
                },
            ],
        }),
        [blocks],
    )

    return (
        <StyledSection pt="small">
            <StyledContainer>
                <SliderBlock
                    title="Вам будет полезно"
                    length={blocks.length}
                    count="2"
                    sliderRef={sliderRef}
                >
                    <Slider {...settings} ref={sliderRef}>
                        {blocks.map((item) => (
                            <UsefulCard
                                key={item.id}
                                img={item.image.banner_207}
                                title={item.title}
                                text={item.description}
                                link={item.link}
                            />
                        ))}
                    </Slider>
                </SliderBlock>
            </StyledContainer>
        </StyledSection>
    )
}
