export const clientsSmall = [
    {
        id: 1,
        description: 'Клиенты с плохой кредитной историей и множеством отказов',
        icon: { title: 'list' },
    },
    {
        id: 2,
        description: 'Клиенты, чей официальный доход не позволяет взять кредит',
        icon: { title: 'list' },
    },
    {
        id: 3,
        description: 'Клиенты без поручителей и возможностью внести залог',
        icon: { title: 'list' },
    },
    {
        id: 4,
        description: 'Клиенты с одним или несколькими текущими кредитами',
        icon: { title: 'list' },
    },
]

export const statuses = [
    {
        key: 'send_finance_lawyer',
        title: 'Вопрос отправлен кредитному адвокату',
        color: '#EE8A15',
    },
    {
        key: 'finance_lawyer_in_work',
        title: 'Вопрос в обработке у кредитного адвоката',
        color: '#EE8A15',
    },
    { key: 'wait_client_response', title: 'Ожидаем Вашего ответа', color: '#27AE60' },
    {
        key: 'send_legal_dept',
        title: 'Вопрос отправлен кредитному менеджеру',
        color: '#EE8A15',
    },
    {
        key: 'legal_dept_in_work',
        title: 'Вопрос в обработке у кредитного менеджера',
        color: '#EE8A15',
    },
    { key: 'resolved', title: 'Вопрос решен', color: '#B5BFCD' },
]

export const profileMeta = {
    title: 'Личный кабинет клиента ЦК АТЛ',
    description:
        'Личный кабинет ЦК АТЛ: бесплатная консультация финансового адвоката, работа с личным менеджером, поддержка.',
}
