import { useMemo } from 'react'
import {
    StyledSection,
    StyledContainer,
    StyledSlider,
    StyledButton,
    StyledTitle,
    StyledCard,
} from '@/components/styles'
import Slider from 'react-slick'
import Link from 'next/link'
import { maxWidth } from '@/assets/styles/mixins.css.js'
import styled from 'styled-components'
import vars from '@/assets/styles/vars'
import { useInfo } from '@/contexts/InfoContext'

export default function () {
    const { massMedia } = useInfo()
    const count = massMedia.length;
    let settings;

    if(count > 4 ) {
        settings = useMemo(
            () => ({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                nextArrow: <StyledSlider.Arrow next media />,
                prevArrow: <StyledSlider.Arrow prev media />,
                variableWidth: false,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: true,
                            arrows: false,
                            dots: true,
                        },
                    },
                ],
            }),
            [],
        )
    } else {
        settings = useMemo(
            () => ({
                settings: "unslick",
                slidesToShow: 4,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            }),
            [],
        )
    }

    return (
        <StyledSection pb="small" pt="small">
            <StyledContainer>
                <StyledSection.Top>
                    <StyledTitle mb="none">СМИ о нас.</StyledTitle>
                    <StyledButton bg="grey">
                        <Link href="/press-center?tab=media">
                            <a />
                        </Link>
                        Перейти в раздел
                    </StyledButton>
                </StyledSection.Top>
                <StyledSlider media>
                    <Slider {...settings}>
                        {massMedia.map((item) => (
                            <StyledCard key={item.id} type="media" center>
                                <CardInner>
                                    <img src={item.preview.x1} alt="" />
                                    <a href={item.link} target="_blank" />
                                </CardInner>
                            </StyledCard>
                        ))}
                    </Slider>
                </StyledSlider>
                <ButtonWrap>
                    <StyledButton bg="grey" width="full">
                        <Link href="/press-center?tab=media">
                            <a />
                        </Link>
                        Перейти в раздел
                    </StyledButton>
                </ButtonWrap>
            </StyledContainer>
        </StyledSection>
    )
}

const ButtonWrap = styled.div`
    display: none;
    ${maxWidth(vars.breakpoints.mMobile)`
        display: block;
    `}
`

const CardInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    ${maxWidth(vars.breakpoints.mMobile)`
        // display: block;
    `}
`
