import { useState } from 'react'
import { StyledTest, StyledTitle, StyledText, StyledButton } from '@/components/styles'
import { Form, Input, PhoneInput, Radio, RangeInput, Modal, SvgIcon, Socials } from '@/components'
import useForm from '@/lib/hooks/form'
import api from '@/lib/api'

const steps = [
    { id: 1, title: 'Выберите вид кредитования', fields: ['type'] },
    { id: 2, title: 'Укажите желаемую сумму и срок кредита.', fields: ['sum', 'term'] },
    { id: 3, title: 'Работаете ли вы сейчас?', fields: ['work'] },
    { id: 4, title: 'Есть ли у вас просроченная задолженность?', fields: ['delay'] },
    { id: 5, title: 'Укажите свой доход в месяц.', fields: ['income'] },
    { id: 6 },
    { id: 7, fields: ['name', 'phone'] },
]

const types = [
    { key: 1, value: 'На личные нужды' },
    { key: 2, value: 'Авто' },
    { key: 3, value: 'Недвижимость' },
    { key: 4, value: 'Рефинансирование кредита' },
    { key: 5, value: 'На развитие бизнеса' },
    { key: 6, value: 'Лизинг' },
]

const works = [
    { key: 1, value: 'Да, более 3-х месяцев' },
    { key: 2, value: 'Да, менее 3-х месяцев' },
    { key: 3, value: 'Нет' },
    { key: 4, value: 'Пенсионер/Студент' },
    { key: 5, value: 'Свой бизнес/Самозанятый' },
    { key: 6, value: 'В декрете' },
]

const delays = [
    { key: 1, value: 'Да, более месяца' },
    { key: 2, value: 'Да, менее месяца' },
    { key: 3, value: 'Нет' },
]

const initialValues = {
    sum: 5000,
    term: 24,
    type: '',
    work: '',
    delay: '',
    income: '',
    chosenType: '',
    name: '',
    phone: '',
}

export default function ({ isOpen, onClose }) {
    const [step, setStep] = useState(1)
    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)

    async function onSubmit() {
        form.submit(async () => {
            const userId = Math.random().toString(36).substring(2)

            await api.test({
                answers: [
                    {
                        question: steps[0].title,
                        answer: types.find((item) => item.key === form.values.type).value,
                    },
                    { question: 'Сумма', answer: form.values.sum },
                    { question: 'Срок', answer: form.values.term },
                    {
                        question: steps[2].title,
                        answer: works.find((item) => item.key === form.values.work).value,
                    },
                    {
                        question: steps[3].title,
                        answer: delays.find((item) => item.key === form.values.delay).value,
                    },
                    { question: 'Доход в месяц', answer: form.values.income },
                ],
                type: form.values.chosenType,
                name: form.values.name,
                phone: form.values.phone,
                user_id: userId,
            })

            const goalParams = { user_id: userId }
            // function goalCallback () {
            // console.log("запрос в Метрику успешно отправлен: USER_ID = " + userId)
            // }
            //
            // ym(34977235, "reachGoal", "user_id", goalParams, goalCallback)
            // ym(34977235, 'reachGoal', 'user_id', goalParams)
            if(typeof ym !== 'undefined') {
                ym(34977235, 'userParams', goalParams);
            }
            _tmr.push({ type: 'reachGoal', id: 3383172, goal: 'GOAL_NAME'});
            form.setValues(initialValues)
            form.setErrors({})
            onClose(false)
            setSuccess(true)
        })
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} padding="none" large>
                <StyledTest.Modal>
                    <StyledTest.Left>
                        <StyledTitle large mb="small" white>
                            Узнайте, какой тип кредита подходит именно Вам
                        </StyledTitle>
                        <StyledText white>
                            5 простых вопросов помогут подобрать самое оптимальное предложение.
                        </StyledText>
                    </StyledTest.Left>
                    <StyledTest.Inner>
                        <Form onSubmit={onSubmit}>
                            {step <= 5 ? (
                                <>
                                    <StyledTest.Progress width={20 * step - 10} />
                                    <StyledTest.Top>
                                        {step > 1 && (
                                            <StyledTest.Back onClick={() => setStep(step - 1)}>
                                                <SvgIcon name="arrow-back" width="24" height="24" />
                                                Назад
                                            </StyledTest.Back>
                                        )}
                                        <StyledTest.Counter grey>
                                            {step} из 5 вопросов
                                        </StyledTest.Counter>
                                    </StyledTest.Top>
                                    <StyledTitle small mb="medium">
                                        {steps.find((item) => item.id === step).title}
                                    </StyledTitle>
                                    <StyledTest.Form>
                                        <StyledTest.FormFields>
                                            {step === 1 && (
                                                <Radio
                                                    type="button"
                                                    options={types}
                                                    {...form.radio('type')}
                                                />
                                            )}
                                            {step === 2 && (
                                                <>
                                                    <RangeInput
                                                        label="Сумма"
                                                        measure="BYN"
                                                        min="100"
                                                        max="200000"
                                                        step="100"
                                                        {...form.input('sum')}
                                                    />
                                                    <RangeInput
                                                        label="Срок"
                                                        measure="мес"
                                                        min="1"
                                                        max="60"
                                                        {...form.input('term')}
                                                    />
                                                </>
                                            )}
                                            {step === 3 && (
                                                <Radio
                                                    type="button"
                                                    options={works}
                                                    {...form.radio('work')}
                                                />
                                            )}
                                            {step === 4 && (
                                                <Radio
                                                    type="button"
                                                    options={delays}
                                                    {...form.radio('delay')}
                                                />
                                            )}
                                            {step === 5 && (
                                                <Input
                                                    label="Доход в месяц"
                                                    type="tel"
                                                    {...form.input('income')}
                                                />
                                            )}
                                        </StyledTest.FormFields>
                                        <StyledTest.Button>
                                            <StyledButton
                                                disabled={steps
                                                    .find((item) => item.id === step)
                                                    ?.fields.find((item) => !form.values[item])}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setStep(step + 1)
                                                }}
                                            >
                                                Продолжить
                                            </StyledButton>
                                        </StyledTest.Button>
                                    </StyledTest.Form>
                                </>
                            ) : step === 6 ? (
                                <>
                                    <StyledTitle small mb="medium">
                                        Вам подходят
                                    </StyledTitle>
                                    <StyledTest.Result>
                                        <StyledTest.Cards>
                                            {form.values.sum <= 1500 && (
                                                <StyledTest.Card>
                                                    <StyledTest.CardLogo>
                                                        <img src="/images/rubli.png" alt="" />
                                                    </StyledTest.CardLogo>
                                                    <StyledTest.CardTitle>
                                                        Лизинг
                                                    </StyledTest.CardTitle>
                                                    <StyledTest.CardText grey>
                                                        Оформите выгодный лизинг у нашего партерна
                                                        РублиТут.
                                                    </StyledTest.CardText>
                                                    <StyledButton
                                                        sizeS
                                                        onClick={() => {
                                                            form.setValues({
                                                                ...form.values,
                                                                chosenType: 'Лизинг',
                                                            })
                                                            setStep(step + 1)
                                                        }}
                                                    >
                                                        Оформить лизинг
                                                    </StyledButton>
                                                </StyledTest.Card>
                                            )}
                                            <StyledTest.Card>
                                                <StyledTest.CardLogo>
                                                    <img src="/images/banks.png" alt="" />
                                                </StyledTest.CardLogo>
                                                <StyledTest.CardTitle>
                                                    Кредит под 9,9%
                                                </StyledTest.CardTitle>
                                                <StyledTest.CardText grey>
                                                    Оформите выгодный кредит под ставку 9,9%
                                                    годовых в одном из банков партнеров.
                                                </StyledTest.CardText>
                                                <StyledButton
                                                    sizeS
                                                    onClick={() => {
                                                        form.setValues({
                                                            ...form.values,
                                                            chosenType: 'Кредит',
                                                        })
                                                        setStep(step + 1)
                                                    }}
                                                >
                                                    Оформить кредит
                                                </StyledButton>
                                            </StyledTest.Card>
                                        </StyledTest.Cards>
                                        <StyledTest.Again onClick={() => setStep(1)}>
                                            <SvgIcon name="again" width="20" height="20" />
                                            Пройти тест заново
                                        </StyledTest.Again>
                                    </StyledTest.Result>
                                </>
                            ) : (
                                step === 7 && (
                                    <>
                                        <StyledTitle small mb="medium">
                                            Оставьте заявку на получение денег
                                        </StyledTitle>
                                        <StyledTest.Form>
                                            <StyledTest.FormFields>
                                                <Input
                                                    label="Имя"
                                                    placeholder="Введите имя"
                                                    {...form.input('name')}
                                                />
                                                <PhoneInput
                                                    label="Телефон"
                                                    placeholder="Введите телефон"
                                                    {...form.input('phone')}
                                                />
                                            </StyledTest.FormFields>
                                            <StyledTest.Button>
                                                <StyledButton
                                                    disabled={
                                                        steps[6].fields.find(
                                                            (item) => !form.values[item],
                                                        ) || form.isLoading
                                                    }
                                                    isLoading={form.isLoading}
                                                    onClick={onSubmit}
                                                >
                                                    Отправить
                                                </StyledButton>
                                            </StyledTest.Button>
                                        </StyledTest.Form>
                                    </>
                                )
                            )}
                        </Form>
                    </StyledTest.Inner>
                </StyledTest.Modal>
            </Modal>
            <Modal
                small
                isOpen={success}
                onClose={setSuccess}
                title="Ваша заявка успешно отправлена"
                text="<p>Ваши данные были успешно отправлены. В скором времени с вами свяжется наш менеджер.</p><p>А пока Вы ожидаете, подпишитесь на <a href='https://t.me/byfin' target='_blank'>телеграм канал</a> с полезным контентом о финансах.<p>"
            >
                <Socials />
            </Modal>
        </>
    )
}
