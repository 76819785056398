import { StyledSection, StyledContainer, StyledTitle, StyledText } from '@/components/styles'

export default function ({ title, text }) {
    return (
        <StyledSection pt="medium" pb="small">
            <StyledContainer>
                <StyledTitle white>{title}</StyledTitle>
                <StyledText white>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </StyledText>
            </StyledContainer>
        </StyledSection>
    )
}
