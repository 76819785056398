import { StyledRadio, StyledInput } from '@/components/styles'

export default function ({ title, options, mb, value, onChange, type = 'list', ...props }) {
    return (
        <StyledRadio mb={mb}>
            {title && <StyledInput.Title>{title}</StyledInput.Title>}
            <StyledRadio.Labels type={type}>
                {options.map((item) => (
                    <StyledRadio.Label
                        checked={value == item.key}
                        key={item.key}
                        type={type}
                        {...props}
                    >
                        <input
                            type="radio"
                            onChange={() => onChange(item.key)}
                            checked={value == item.key}
                        />
                        {item.value}
                    </StyledRadio.Label>
                ))}
            </StyledRadio.Labels>
        </StyledRadio>
    )
}
