import styled, { css } from 'styled-components'
import { maxWidth } from '@/assets/styles/mixins.css'
import vars from '@/assets/styles/vars'

const Contacts = styled.div``

Contacts.Actions = styled.div`
    position: absolute;
    top: ${p => p.isVisible ? "calc(100% + 12px)" : "-40px"};
    left: 0;
    display: none;
    width: 100%;
    justify-content: center;
    opacity: ${p => p.isVisible ? "1" : "0"};
    z-index: 6;
    transition: .3s;
    ${maxWidth(vars.breakpoints.tablet)`
        display: flex;
    `}
`

Contacts.Action = styled.div`
    margin: 0 8px;
    padding: 12px 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(61, 75, 86, 0.12), 0px 4px 32px rgba(61, 75, 86, 0.1);
    border-radius: 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    ${maxWidth(vars.breakpoints.sMobile - 1)`
        margin: 0 4px;
        padding: 10px;
    `}
`

Contacts.City = styled.div`
    border-top: 1px solid ${vars.theme.outlineGrey};
    transition: all .3s;
    ${p => p.isOpen && css`
        border-top-color: transparent;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        margin: 0 -20px;
    `}
`

Contacts.CityHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    background: ${p => p.isOpen ? vars.theme.lightGrey : "#fff"};
    ${p => p.isOpen && css`
        color: ${vars.theme.red};
    `}
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    cursor: pointer;
    transition: .3s;
    &:after {
        content: "";
        flex-shrink: 0;
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: ${p => p.isOpen ? 0 : "5px"};
        margin-left: 10px;
        border-right: 2px solid ${p => p.isOpen ? vars.theme.red : vars.theme.darkGrey};
        border-bottom: 2px solid ${p => p.isOpen ? vars.theme.red : vars.theme.darkGrey};
        transform: ${p => p.isOpen ? "rotate(-135deg)" : "rotate(45deg)"};
        transition: all .3s;
    }
    ${maxWidth(vars.breakpoints.tablet)`
        padding: 16px 24px;
        font-size: 20px;
        line-height: 28px;
    `}
    ${maxWidth(vars.breakpoints.mMobile)`
        padding: 16px 20px;
        font-size: 16px;
        line-height: 24px;
    `}
`

Contacts.CityContent = styled.div`
    overflow: hidden;
    opacity: ${p => p.isOpen ? 1 : 0};
    visibility: ${p => p.isOpen ? "visible" : "hidden"};
    height: ${p => p.isOpen ? "auto" : "0"};
    transition: ${p => p.isOpen ? "opacity 0.25s ease 0.1s, visibility 0s ease 0.1s" : "visibility 0s ease 0.1s"};  
    ${maxWidth(vars.breakpoints.mMobile)`
        padding: 0 20px;
    `} 
`

export default Contacts
