import { useState } from 'react'
import { StyledMobileOffices } from '@/components/styles'
import { SvgIcon, MapModal } from '@/components'
import getWindowWidth from '@/lib/getWindowWidth'
import moment from 'moment'
import 'moment/locale/ru'

moment.locale('ru')

export default function ({ office }) {
    const windowWidth = getWindowWidth()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <StyledMobileOffices.Item onClick={() => windowWidth > 1024 && setIsOpen(true)}>
                <StyledMobileOffices.ItemTop>
                    <StyledMobileOffices.City>{office.city.title}</StyledMobileOffices.City>
                    <StyledMobileOffices.Address>{office.address}</StyledMobileOffices.Address>
                    <StyledMobileOffices.Location
                        onClick={() => windowWidth <= 1024 && setIsOpen(true)}
                    >
                        <SvgIcon name="location" width="16" height="16" />
                    </StyledMobileOffices.Location>
                </StyledMobileOffices.ItemTop>
                <StyledMobileOffices.Date>
                    {moment(office.date).format('DD MMMM YYYY')}
                </StyledMobileOffices.Date>
            </StyledMobileOffices.Item>
            <MapModal office={office} isOpen={isOpen} onClose={setIsOpen} />
        </>
    )
}
