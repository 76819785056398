import { useState, useRef } from 'react'
import { StyledButton, StyledForm } from '@/components/styles'
import { Form, Input, PhoneInput, Modal } from '@/components'
import useForm from '../../lib/hooks/form'
import api from '../../lib/api'

const initialValues = { name: '', phone: '', city: '' }

export default function ({ office, isOpen, onClose }) {
    const nameRef = useRef()

    const form = useForm(initialValues)

    const [success, setSuccess] = useState(false)

    function onSubmit() {
        form.submit(async () => {
            await api.mobileOfficeRequest(form.values)
            form.setValues(initialValues)
            form.setErrors({})
            onClose(false)
            setSuccess(true)
        })
    }

    function onOpen() {
        nameRef.current && nameRef.current.focus()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Оставьте заявку на мобильный офис."
                text="Укажите свои данные и город."
                onAfterOpen={onOpen}
            >
                <Form onSubmit={onSubmit}>
                    <StyledForm.Inner>
                        <Input
                            label="Имя"
                            placeholder="Введите имя"
                            inputRef={nameRef}
                            mb="medium"
                            {...form.input('name')}
                        />
                        <PhoneInput
                            label="Телефон"
                            placeholder="Введите телефон"
                            mb="medium"
                            {...form.input('phone')}
                        />
                    </StyledForm.Inner>
                    <Input label="Город" {...form.input('city')} />
                    <StyledButton width="full" disabled={form.isLoading} isLoading={form.isLoading}>
                        Отправить
                    </StyledButton>
                </Form>
            </Modal>
            <Modal
                small
                isOpen={success}
                onClose={setSuccess}
                title="Ваши данные были успешно отправлены"
                text="В скором времени с вами свяжется наш менеджер."
            />
        </>
    )
}
